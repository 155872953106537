// https://ui.docs.amplify.aws/react-native/connected-components/authenticator/customization

import { Authenticator } from '@aws-amplify/ui-react-native';
import React from 'react';

export function Provider({ children }: any) {
  return <Authenticator.Provider>{children}</Authenticator.Provider>;

  //   const Stack = createStackNavigator<AuthStackParamList>();
  //   return (
  //     <>
  //       <TopBar />
  //       <Stack.Navigator>
  //         <Stack.Screen
  //           name="auth"
  //           component={AuthWrapper}
  //           options={{
  //             gestureEnabled: false,
  //             headerShown: false,
  //           }}
  //         />
  //         <Stack.Screen
  //           name="terms"
  //           component={TermsScreen}
  //           options={{
  //             gestureEnabled: false,
  //             headerShown: false,
  //           }}
  //         />
  //       </Stack.Navigator>
  //     </>
  //   );
  // }
}
