/**
 * Advisor Context
 *
 * The purpose of this Context is to CRUD (create read update delete) a user's Advisor and it's underlying assessments.
 * A Advisor will consist of answers to specific assessments that have, or not have, been started or completed by a user.
 * The Advisor context uses the assessment context to gather the structure of the assessment.
 * This separation is useful so that we can adjust the assessment structure separately from the saved assessments.
 * Although one might say that once a user starts to create an assessment then changing the underlying structure would render the started assessment obsolete, instead we will need to perform comparisons between existing assessments and the original assessment structure.  This approach will (hopefully allow us to create iterations of assessments without effecting existing data)
 */
import { Advisor, CreateAdvisorInput } from '@src/API';
import { createAdvisor, deleteAdvisor, updateAdvisor } from '@src/graphql/mutations';
import { getAdvisor, listAdvisors } from '@src/graphql/queries';
import { LoadingContext } from '@src/providers/Loading';
import { UserContext } from '@src/providers/User';
import Owners from '@src/utils/Owners';
import { generateClient } from 'aws-amplify/api';
import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';

export type AdvisorContextProps = {
  loadAllAdvisors: () => void;
  loadAdvisorById: (id: string) => Advisor | undefined;
  allAdvisors: Advisor[] | undefined;
  makeAdvisor: (s: string) => void;
  saveAdvisor: (p: any) => void;
  deleteAdvisorById: (id: string) => void;
  currentAdvisor: Advisor | undefined;
  setCurrentAdvisor: (Advisor: Advisor | undefined) => void;
};

export const AdvisorContext = React.createContext<AdvisorContextProps>({
  loadAllAdvisors: () => {},
  loadAdvisorById: _ => undefined,
  makeAdvisor: _ => {},
  saveAdvisor: _ => {},
  deleteAdvisorById: _ => {},
  allAdvisors: undefined,
  currentAdvisor: undefined,
  setCurrentAdvisor: _ => {},
});

export const { Consumer } = AdvisorContext;
export default Consumer;

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const { addLoading, removeLoading } = useContext(LoadingContext);
  const { user } = useContext(UserContext);
  const [allAdvisors, setAllAdvisors] = useState<Advisor[] | undefined>(undefined);
  const [vectorStores, setAllVectorStores] = useState<VectorStore[] | undefined>(undefined);

  // we need to figure out how to store the current Advisor in state so that if the user reloads they dont lose their work
  const [currentAdvisor, setCurrentAdvisor] = useState<Advisor | undefined>(undefined);
  const client = generateClient();

  /**
   * get all Advisors
   */
  async function loadAllAdvisors() {
    // using the auth context we will get the user id and retrieve all Advisors from the backend
    // the frontend will filter the Advisors accordingly
    try {
      addLoading('loadAllAdvisors');
      const p = await client.graphql({
        query: listAdvisors,
      });
      setAllAdvisors(p.data.listAdvisors.items);
      removeLoading('loadAllAdvisors');
    } catch (err) {
      console.log('error loading Advisors:', err);
    }
  }

  async function loadAdvisorById(id: string) {
    try {
      addLoading('loadAdvisorById');
      console.log('loadAdvisorById', id);
      const p = await client.graphql({
        query: getAdvisor,
        variables: { id },
      });
      // return the Advisor?  store it in state?
      removeLoading('loadAdvisorById');
      setCurrentAdvisor(p.data.getAdvisor as Advisor);
      return p.data.getAdvisor;
    } catch (err) {
      console.log(`error loading Advisor by id ${id}:`, err);
    }
  }

  async function saveAdvisor(p: any) {
    try {
      // save the current project to the backend
      addLoading('saveAdvisor');
      if (!currentAdvisor?.title) return;
      const { createdAt, updatedAt, owner, __typename, ...rest } = { ...currentAdvisor, ...p };
      const project = { ...rest };
      console.log('saveAdvisor:', project);
      if (project && project.id) {
        setCurrentAdvisor({ ...currentAdvisor, ...p });
        await client.graphql({
          query: updateAdvisor,
          variables: {
            input: project,
          },
        });
      }
      removeLoading('saveAdvisor');
    } catch (err) {
      console.log('error updating advisor:', err);
    }
  }

  async function makeAdvisor(advisorBaseName: string) {
    try {
      // when creating a Advisor, we use the stores assessments data to populate the database
      // each Advisor will have a unique ID
      // the Advisor context will handle the loading of each unique Advisor
      addLoading('makeAdvisor');

      // create a vector store object
      const indexId = 'advisors-01';
      const namespace = `${advisorBaseName.replace(/[^a-zA-Z0-9_-]/g, '-').toLowerCase()}-${Date.now()}`;

      const initialCurrentAdvisor: CreateAdvisorInput = {
        title: advisorBaseName,
        owners: Owners,
        namespace,
        indexId,
      };

      const advisorResult = await client.graphql({
        query: createAdvisor,
        variables: { input: initialCurrentAdvisor },
      });
      console.log('vectorStoreResult.data.createVectorStore.id:', advisorResult.data.createAdvisor);
      removeLoading('makeAdvisor');
      return advisorResult;
    } catch (err) {
      console.log('error creating Advisor:', err);
    }
  }

  function deleteAdvisorById(id: string) {
    const newAllAdvisors = allAdvisors?.filter(p => {
      return p.id !== id;
    });
    // todo could this setState be replaced with a graphql subscription?
    setAllAdvisors(newAllAdvisors);
    // delete the advisor from the database
    client.graphql({
      query: deleteAdvisor,
      variables: { input: { id } },
    });
  }

  const contextValue: AdvisorContextProps = useMemo(
    () => ({
      currentAdvisor,
      allAdvisors,
      vectorStores,
      loadAllAdvisors,
      loadAdvisorById,
      makeAdvisor,
      saveAdvisor,
      deleteAdvisorById,
      setCurrentAdvisor,
    }),
    [currentAdvisor, allAdvisors, loadAllAdvisors, loadAdvisorById, makeAdvisor, saveAdvisor, setCurrentAdvisor],
  );

  return <AdvisorContext.Provider value={contextValue}>{children}</AdvisorContext.Provider>;
}
