import React, { PropsWithChildren, useMemo, useState } from 'react';

export type LoadingState = {
  id: string;
  state: boolean;
};

export type LoadingContextProps = {
  loadingQueue: LoadingState[];
  loading: boolean;
  addLoading: (id: string) => void;
  removeLoading: (id: string) => void;
  checkLoading: (id: string) => LoadingState | undefined;
};

export const LoadingContext = React.createContext<LoadingContextProps>({
  loadingQueue: [],
  loading: false,
  addLoading: _ => {},
  removeLoading: _ => {},
  checkLoading: _ => undefined,
});

export const { Consumer } = LoadingContext;
export default Consumer;

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const [loadingQueue, setLoadingQueue] = useState<LoadingState[]>([]);

  const addLoading = (id: string) => {
    setLoadingQueue(prevQueue => [...prevQueue, { id, state: true }]);
  };

  const removeLoading = (id: string) => {
    setLoadingQueue(prevQueue => prevQueue.filter(item => item.id !== id));
  };

  const checkLoading = (id: string) => {
    return loadingQueue.some(item => item.id === id);
  };

  const loading = loadingQueue.length > 0 && loadingQueue.some(item => item.state);

  const contextValue: LoadingContextProps = useMemo(
    () => ({
      loadingQueue,
      loading,
      addLoading,
      removeLoading,
      checkLoading,
    }),
    [loading],
  );

  return <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>;
}
