import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import React from 'react';
import { View } from 'react-native';
import Button from '../Button';

interface TaskItemProps {
  task: any;
  index: number;
  onPress: () => void;
}

const TaskItem = ({ task, index, onPress }: TaskItemProps) => {
  const { name, summary, difficulty } = task;

  return (
    <View
      style={{
        // borderRadius: 10,
        padding: 15,
        backgroundColor: '#f1f1f1',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            width: '5%',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              backgroundColor: '#fff',
              width: 30,
              height: 30,
              borderRadius: 30,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextBodyMedium style={[Styles.h6]}>{index + 1}</TextBodyMedium>
          </View>
        </View>
        <View style={{ width: '55%', paddingLeft: 10 }}>
          <TextBodyMedium style={[Styles.h6, {}]}>{name}</TextBodyMedium>
          <TextBodyRegular style={[Styles.h7, {}]}>{summary}</TextBodyRegular>
        </View>
        <View
          style={[
            {
              width: '20%',
              alignSelf: 'center',
              paddingLeft: 30,
            },
          ]}
        >
          <View style={{ width: 30, height: 30, backgroundColor: 'orange', borderRadius: 30, paddingLeft: 10 }} />
        </View>
        <TextBodyRegular
          style={[
            Styles.h5,
            {
              width: '20%',
              alignSelf: 'center',
              paddingLeft: 30,
            },
          ]}
        >
          {difficulty}
        </TextBodyRegular>
        <View
          style={{
            paddingLeft: 10,
          }}
        >
          <Button
            onPress={onPress}
            theme="simple"
          >
            See more...
          </Button>
        </View>
      </View>
    </View>
  );
};

export default TaskItem;
