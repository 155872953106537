import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useResponsive from '@src/hooks/useResponsive';
import { UserContext } from '@src/providers/User';
import { getTranslation } from '@src/services/i18n';
import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import Button from '../Button';
import { TextBodyMedium, TextBodyRegular } from '../shared/StyledText';
import Styles from '../shared/Styles';
import { Pressable } from '../shared/Themed';

const SignOutButton = () => {
  const { signOut } = useContext(UserContext);
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const onPress = () => {
    signOut();
    navigation.navigate('loggedOut');
  };

  return (
    <>
      <Button
        theme={'simple'}
        onPress={onPress}
        style={{
          padding: 10,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: 1,
          }}
        >
          <TextBodyRegular style={[Styles.h6, { marginRight: 10, color: '#000' }]}>Logout</TextBodyRegular>
          <Ionicons
            name="log-out"
            size={25}
            color={'#000'}
          />
        </View>
      </Button>
    </>
  );
};

const NavUI = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { user, userAttributes } = useContext(UserContext);
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { isWideScreen } = useResponsive();

  const openMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };
  const gotoBackOffice = () => {
    navigation.navigate('backoffice');
    setMobileNavOpen(!mobileNavOpen);
  };
  const isAdmin = userAttributes?.groups?.includes('admin');
  return (
    <View
      style={{
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <Pressable onPress={openMobileNav}>
        <Ionicons
          name="menu"
          size={30}
          color={'#000'}
        />
      </Pressable>
      {mobileNavOpen && (
        <View
          style={{
            display: 'flex',
            flex: 1,
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: '#000',
            position: 'absolute',
            zIndex: 100,
            right: isWideScreen ? -250 : -20,
            top: isWideScreen ? -175 : -20,
            width: 300,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginLeft: 10,
              marginRight: 20,
              marginTop: 20,
            }}
          >
            <TextBodyMedium
              style={[
                Styles.h6,
                {
                  color: '#999',
                },
              ]}
            >
              {`${user?.signInDetails?.loginId ? user?.signInDetails?.loginId : ''}`}
            </TextBodyMedium>
            <Pressable
              onPress={openMobileNav}
              style={{
                alignSelf: 'flex-end',
              }}
            >
              <Ionicons
                name="close"
                size={30}
                color={'#000'}
              />
            </Pressable>
          </View>
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            {isAdmin && (
              <Pressable
                onPress={gotoBackOffice}
                style={{
                  marginTop: 10,
                  borderBottomWidth: 1,
                  borderColor: '#000',
                  padding: 10,
                }}
              >
                <TextBodyRegular style={[Styles.h6, {}]}>{getTranslation('backOffice.navButton')}</TextBodyRegular>
              </Pressable>
            )}
            <SignOutButton />
          </View>
        </View>
      )}
    </View>
  );
};

export default NavUI;
