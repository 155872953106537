import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Ask } from '@src/API';
import { vectorStore } from '@src/components/ADT/MagicTable';
import Button from '@src/components/Button';
import CountdownTimer from '@src/components/Countdown';
import { DemoContext } from '@src/components/Demos/Context';
import InfoToolTip from '@src/components/InfoToolTip';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { createSubmissionPreScreen } from '@src/graphql/mutations';
import useResponsive from '@src/hooks/useResponsive';
import { AlignmentContext } from '@src/providers/Alignment';
import { LoadingContext } from '@src/providers/Loading';
import { chat } from '@src/utils/Chat';
import { uploadFilesUtil } from '@src/utils/FileUpload';
import { generateClient } from 'aws-amplify/api';
import React, { useContext, useEffect, useRef } from 'react';
import { ActivityIndicator } from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import { SPSPage } from '.';
import DeliverableLLMItem from './DeliverableLLMItem';
import InputEditable from './InputEditable';

const SPSReview = ({ goto }: SPSPage) => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { isWideScreen } = useResponsive();
  const { loading, addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const { alignmentState, setAlignmentState } = useContext(AlignmentContext);
  const { getPineconeIndex } = useContext(DemoContext);
  const askSourceRef = useRef<string>();
  const client = generateClient({
    authMode: 'apiKey',
  });

  const gotoCreate = () => {
    goto('make');
  };

  const gotoGenerated = () => {
    generate();
  };

  const createDatabaseEntry = async (uuid: string, vsData: vectorStore) => {
    const { files, ...rest } = alignmentState; // Destructure to remove 'files'
    const input = {
      ...rest,
      id: uuid,
      indexId: vsData.indexId,
      namespace: vsData.namespace,
    };
    setAlignmentState(input);
    return await client.graphql({
      query: createSubmissionPreScreen,
      variables: {
        input,
      },
      // authMode: isLoggedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
      // export type GraphQLAuthMode = 'apiKey' | 'oidc' | 'userPool' | 'iam' | 'identityPool' | 'lambda' | 'none';
    });
  };

  const generate = async () => {
    addLoading('generate');
    try {
      const uuid = uuidv4();
      // create pinecone index
      const vsData = await getPineconeIndex();
      if (vsData) {
        // create URL
        // create a database entry with a uuid
        const db = await createDatabaseEntry(uuid, vsData);
        goto('make');
        navigation.navigate('wealign', {
          screen: 'submit',
          params: {
            id: uuid,
          },
        });
        // updateItemDb({
        //   id: uuid,
        //   role: alignmentState.role,
        //   deliverables: alignmentState.deliverablesLLM,
        // });
        return db;
      }
    } catch (err) {
      console.log('Error Generate createDatabaseEntry()', err);
    } finally {
      removeLoading('generate');
    }
  };

  const addRequirement = () => {
    // Add a new requirement
    setAlignmentState(prevState => {
      if (prevState && prevState.deliverablesLLM) {
        const newDeliverablesLLM = [...prevState.deliverablesLLM, { title: '' }];
        const newAlignmentState = { ...prevState, deliverablesLLM: newDeliverablesLLM };
        return newAlignmentState;
      } else {
        return prevState;
      }
    });
  };

  const onAlign = async () => {
    try {
      addLoading('loadingTWE');
      // incase there were files to be uploaded
      await extractor();
      // run the ai but not in parralel
      await Promise.all([getTitleWhyEmail(), generateDeliverablesLLM()]);
    } catch (err) {
      console.log('error onAlign', err);
    } finally {
      removeLoading('loadingTWE');
    }
  };

  const extractor = async () => {
    if (alignmentState?.files) {
      const [extraction] = await Promise.all(
        alignmentState.files.map(async fileList => {
          // incase the user uploads multiple files at separate points in time, we need to loop over all uploaded file cohorts
          return await uploadFilesUtil({ files: fileList });
        }),
      );

      // parse out each string of text for analysis without chunking... this could be improved <- we do not want to miss any information by not including all of the text in the initial list creation request
      // we are not storing the text in the vector store (yet) because the user may not create an alignment so it's better to create the vector store when the entire alignment is generated
      let blockText = '';

      extraction.map(extract => {
        extract?.map(child => {
          blockText += child.pageContent; // each extract is an array of words
        });
        // an extraction is group of groups, where the sub group is a list of files, and the parent is a list of sub groups.
      });
      askSourceRef.current = `${blockText} ${alignmentState.deliverables}`;

      setAlignmentState(prevState => {
        const newAlignmentState = {
          ...prevState,
          deliverables: askSourceRef.current,
        };
        return newAlignmentState;
      });
    }
    return Promise.resolve();
  };

  const getRole = async () => {
    const role = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `Create a specialist role to analyse <information>${alignmentState.deliverables || askSourceRef.current}</information>. The role has expertise to understand the context of the <information> and to create an approach to extracting information requests that are needed to be answered. You always address ambiguity and assumptions in the <information> so that each information request is clear. Your response must be a 50-250 word string that explains the role you created to be used in future prompts.`,
    };

    const res = await chat({ 0: { role } });
    setAlignmentState(prevState => {
      const newAlignmentState = {
        ...prevState,
        role: res.role,
      };
      return newAlignmentState;
    });
  };

  const getTitleWhyEmail = async () => {
    const getTitleWhyEmail = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `Evaluating a list of information requests <list>${alignmentState.deliverables || askSourceRef.current}</list>. The tone should be formal, but friendly. Do not use pronouns. Respond with a JSON object format: {{ "titleLLM": "a succinct title. Do not include any brackets [ or ]", "requirementsLLM": "a 50-100 word paragraph overview of the requests. In markdown. Do not include any brackets [ or ]", "emailLLM": "a 200-400 word email that requests information as stated in the overview, with a short bulleted list summarizing the areas of information to be provided. In markdown. Do not include any placeholders and remove any brackets [ or ] if they exist" }} Do not include any brackets [ or ] anywhere in your response.`,
      isJson: true,
    };
    const res = await chat({ 0: { getTitleWhyEmail } });
    setAlignmentState(prevState => {
      const newAlignmentState = {
        ...prevState,
        ...res.getTitleWhyEmail,
      };
      return newAlignmentState;
    });
  };

  const generateDeliverablesLLM = async () => {
    const genInitialAskList = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `You are a subject matter expert that reviews documents to break the document into a series of information asks that would need to be answered to sufficiently answer all the information components within the original document. Based on the following set of information: <information>${alignmentState.deliverables || askSourceRef.current}</information> Return a list of asks that sufficiently capture the pieces of information that would need to be answered to accurately and completely update the document. Return this as a numbered list in the original order.`,
    };
    const methodAskListReview = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `We will review the generated list <list>{genInitialAskList}</list> of asks to ensure we have captured all the items. Think about and create a methodology that you will use to evaluate whether you have created a list that appropriately reflects the information in the original document. Create a grading methodology that ranks from 1-10 with 10 being a perfect adherence to the original document.`,
    };
    const deliverablesLLM = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `Review the generated list <list>{genInitialAskList}</list> against the original set of information: <information>${alignmentState.deliverables || askSourceRef.current}</information> using the methodology <methodology>{methodAskListReview}</methodology> to determine if any asks were missed that should have been included, if they were missed, add them into the list. Your response should only be a rearranged list of asks, not rename any of them, in the order of how they were presented in the <information> following this JSON format {{ output: [{{ title: "the short name of the ask", summaryShort: "a 5-10 word summary of the ask", summaryLong: "a 70 word summary of the ask", keywords: "2 keywords in a comma separated list.", queries: "a list of individual questions that could be asked about this ask. each question is unique and not related to the other questions.  each question is separated by || characters." }}]}}`,
      isJson: true,
    };

    const res = await chat({ 1: { genInitialAskList }, 2: { methodAskListReview }, 3: { deliverablesLLM } });
    if (res) {
      // eventually this should probably be a subscription to the GraphQL
      setAlignmentState(prevState => {
        const newAlignmentState = {
          ...prevState,
          deliverablesLLM: res.deliverablesLLM.output,
        };
        return newAlignmentState;
      });
    } else {
      console.log('no response for generating deliverables llm');
    }
  };

  useEffect(() => {
    if (!alignmentState.deliverablesLLM) {
      onAlign();
    }
  }, []);

  return (
    <>
      <View
        style={{
          marginTop: isWideScreen ? '3%' : '10%',
          marginHorizontal: '3%',
          padding: isWideScreen ? 20 : 10,
        }}
      >
        <TextBodyMedium style={[isWideScreen ? Styles.h1 : Styles.h3, { color: Colors.primary, alignSelf: 'center', marginRight: 15 }]}>Review your requests</TextBodyMedium>
      </View>
      <>
        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            borderRadius: 5,
            marginHorizontal: '3%',
            padding: isWideScreen ? 20 : 10,
          }}
        >
          <View
            style={{
              borderBottomColor: '#eee',
              paddingBottom: 10,
              marginBottom: 20,
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Name</TextBodyMedium>
              <View style={{ flex: 1, width: '50%' }}>
                <InfoToolTip toolTip={`This will be visible at the top of the alignment page as well as in your email subject`} />
              </View>
            </View>
            {alignmentState && alignmentState.titleLLM && (
              <View style={{ position: 'absolute', top: 0, right: 0, flexDirection: 'row', alignItems: 'center' }}>
                <TextBodyMedium style={[Styles.h6, { marginRight: 10, color: 'green' }]}>Ready!</TextBodyMedium>
                <Ionicons
                  size={24}
                  color={'green'}
                  name="checkmark-circle"
                />
              </View>
            )}
          </View>

          {alignmentState && alignmentState.titleLLM ? (
            <View>
              <InputEditable
                property="titleLLM"
                height={50}
              />
            </View>
          ) : (
            <>
              <TextBodyMedium style={[Styles.h5, { marginBottom: 10 }]}>Please wait while we create your title</TextBodyMedium>
              <TextBodyMedium style={[Styles.h7, { marginBottom: 20 }]}>Depending on the size and quantity of files uploaded, this can take anywhere between 5 to 15 seconds.</TextBodyMedium>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <ActivityIndicator
                  size={30}
                  color={Colors.primary}
                  style={{ marginRight: 10 }}
                />
                <CountdownTimer
                  size={14}
                  initialTime={alignmentState.files ? 45 : 15}
                />
              </View>
            </>
          )}
        </View>

        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            borderRadius: 5,
            marginHorizontal: '3%',
            padding: isWideScreen ? 20 : 10,
          }}
        >
          <View
            style={{
              borderBottomColor: '#eee',
              paddingBottom: 10,
              marginBottom: 20,
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Summary</TextBodyMedium>
              <View style={{ flex: 1, width: '50%' }}>
                <InfoToolTip toolTip={`A brief explanation of your request.`} />
              </View>
            </View>
            {alignmentState && alignmentState.requirementsLLM && (
              <View style={{ position: 'absolute', top: 0, right: 0, flexDirection: 'row', alignItems: 'center' }}>
                <TextBodyMedium style={[Styles.h6, { marginRight: 10, color: 'green' }]}>Ready!</TextBodyMedium>
                <Ionicons
                  size={24}
                  color={'green'}
                  name="checkmark-circle"
                />
              </View>
            )}
          </View>
          {alignmentState && alignmentState.requirementsLLM ? (
            <InputEditable
              property="requirementsLLM"
              height={200}
            />
          ) : (
            <>
              <TextBodyMedium style={[Styles.h5, { marginBottom: 10 }]}>Please wait while we explain your purpose</TextBodyMedium>
              <TextBodyMedium style={[Styles.h7, { marginBottom: 20 }]}>{`Depending on the size and quantity of files uploaded, this can take anywhere between ${alignmentState.files ? 30 : 5} to ${alignmentState.files ? 45 : 15} seconds.`}</TextBodyMedium>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <ActivityIndicator
                  size={30}
                  color={Colors.primary}
                  style={{ marginRight: 10 }}
                />
                <CountdownTimer
                  size={14}
                  initialTime={alignmentState.files ? 45 : 15}
                />
              </View>
            </>
          )}
        </View>

        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            borderRadius: 5,
            marginHorizontal: '3%',
            padding: isWideScreen ? 20 : 10,
          }}
        >
          <View
            style={{
              borderBottomColor: '#eee',
              paddingBottom: 10,
              marginBottom: 20,
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Email</TextBodyMedium>
              <View style={{ flex: 1, width: '50%' }}>
                <InfoToolTip toolTip={`Copy this email and drop it into your email client and send it to the responsible parties. This feature will be automated if you added assignees in the previous step.`} />
              </View>
            </View>
            {alignmentState && alignmentState.emailLLM && (
              <View style={{ position: 'absolute', top: 0, right: 0, flexDirection: 'row', alignItems: 'center' }}>
                <TextBodyMedium style={[Styles.h6, { marginRight: 10, color: 'green' }]}>Ready!</TextBodyMedium>
                <Ionicons
                  size={24}
                  color={'green'}
                  name="checkmark-circle"
                />
              </View>
            )}
          </View>
          {alignmentState && alignmentState.emailLLM ? (
            <InputEditable
              property="emailLLM"
              height={500}
            />
          ) : (
            <>
              <TextBodyMedium style={[Styles.h5, { marginBottom: 10 }]}>Please wait while we create your email</TextBodyMedium>
              <TextBodyMedium style={[Styles.h7, { marginBottom: 20 }]}>Depending on the size and quantity of files uploaded, this can take anywhere between 5 to 15 seconds.</TextBodyMedium>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <ActivityIndicator
                  size={30}
                  color={Colors.primary}
                  style={{ marginRight: 10 }}
                />
                <CountdownTimer
                  size={14}
                  initialTime={alignmentState.files ? 45 : 15}
                />
              </View>
            </>
          )}
        </View>

        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            borderRadius: 5,
            marginHorizontal: '3%',
            padding: isWideScreen ? 20 : 10,
          }}
        >
          <View
            style={{
              borderBottomColor: '#eee',
              paddingBottom: 10,
              marginBottom: 20,
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Requests</TextBodyMedium>
              <View style={{ flex: 1, width: '50%' }}>
                <InfoToolTip toolTip={`This is a list of what you are requesting. You can edit, add or remove them.`} />
              </View>
            </View>
            {alignmentState && alignmentState.deliverablesLLM && (
              <View style={{ position: 'absolute', top: 0, right: 0, flexDirection: 'row', alignItems: 'center' }}>
                <TextBodyMedium style={[Styles.h6, { marginRight: 10, color: 'green' }]}>Ready!</TextBodyMedium>
                <Ionicons
                  size={24}
                  color={'green'}
                  name="checkmark-circle"
                />
              </View>
            )}
          </View>
          {alignmentState && alignmentState.deliverablesLLM ? (
            <>
              <View>
                {alignmentState.deliverablesLLM.map((ask: Ask | null, index: number) => {
                  return (
                    <View
                      key={`review-ask-${index}`}
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      <DeliverableLLMItem
                        index={index}
                        task={ask}
                      />
                    </View>
                  );
                })}
                <Button
                  onPress={addRequirement}
                  style={{
                    width: '100%',
                    // backgroundColor: Colors.buttonBackground,
                    backgroundColor: '#fff',
                  }}
                >
                  <TextBodyMedium style={[Styles.h6]}>Add a new requirement</TextBodyMedium>
                </Button>
              </View>
            </>
          ) : (
            <View>
              <TextBodyMedium style={[Styles.h5, { marginBottom: 10 }]}>Please wait while we create your list of requirements.</TextBodyMedium>
              <TextBodyMedium style={[Styles.h7, { marginBottom: 20 }]}>Depending on the size and quantity of files uploaded, this can take anywhere between 30 to 60 seconds.</TextBodyMedium>
              <View
                style={{
                  flexDirection: 'row',
                }}
              >
                <ActivityIndicator
                  size={30}
                  color={Colors.primary}
                  style={{ marginRight: 10 }}
                />
                <CountdownTimer
                  size={14}
                  initialTime={60}
                />
              </View>
            </View>
          )}
        </View>
        {!checkLoading('loadingTWE') && (
          <View
            style={{
              backgroundColor: Colors.light.container,
              marginTop: '3%',
              borderRadius: 5,
              marginHorizontal: '3%',
              padding: isWideScreen ? 20 : 10,
            }}
          >
            <>
              <View
                style={{
                  borderBottomColor: '#eee',
                  paddingBottom: 10,
                  marginBottom: 20,
                  borderBottomWidth: 1,
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Satisfied with the above results?</TextBodyMedium>
                  <View style={{ flex: 1, width: '50%' }}>
                    <InfoToolTip toolTip={`Create your unique URL by clicking the Generate button below. Once generated, you can either send emails to your recipients or we will automatically contact them on your behalf.`} />
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: isWideScreen ? 'row' : 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <Button
                  onPress={gotoCreate}
                  iconBefore="chevron-back"
                  theme="secondary"
                  toolTip={'Go back and edit your alignment'}
                  style={{
                    width: isWideScreen ? '25%' : '100%',
                    alignItems: 'center',
                  }}
                >
                  Restart
                </Button>
                {checkLoading('generate') ? (
                  <View
                    style={{
                      width: isWideScreen ? '30%' : '100%',
                      marginTop: isWideScreen ? 0 : 20,
                      alignSelf: isWideScreen ? 'flex-end' : 'flex-start',
                    }}
                  >
                    <ActivityIndicator
                      size="large"
                      color={Colors.primary}
                    />
                    <TextBodyMedium style={[Styles.h6, { marginTop: 10 }]}>{`Please wait while we generate your unique link.\nYou will automatically redirect when it is ready.`}</TextBodyMedium>
                  </View>
                ) : (
                  <Button
                    onPress={gotoGenerated}
                    theme="primary"
                    iconAfter="chevron-forward"
                    toolTip={'Create a unique shareable link'}
                    style={{
                      width: isWideScreen ? '25%' : '100%',
                      marginTop: isWideScreen ? 0 : 20,
                      alignItems: 'center',
                    }}
                  >
                    Generate!
                  </Button>
                )}
              </View>
            </>
          </View>
        )}
      </>
    </>
  );
};

export default SPSReview;
