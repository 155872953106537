export const jsonInstruction = {
  default: `Your response should be a JSON object.`,
};

export const defaultPlaceholder = {
  templateWrapperPlaceholder: `You are an experienced financial consultant.  You are given source material found in <source> and advisory rules and regulations found in <advisor>.  You are given a question found in <question>.  Your response to this question is based on the material found in the <source> and <advisor>.  Use the information within the <source> section to answer the question. If you don't know the answer, just say you don't know and elaborate on how this information might be obtained from the documentation or elsewhere. DO NOT make up an answer.
  
  Your response should be between 1000 to 2000 words. Use direct quotes from the <source> where relevant as evidence. For each paragraph of text provide a direct reference to the page and file name from the <source>. For each quote of text provide a direct reference to the page and file name from the <source>.  Each time you include a direct reference, it should look like this <direct-reference-page number>page number</direct-reference-page-number><direct-reference-filename>source meta data file name</direct-reference-filename>.
  
  <advisor>
    {advisor}
  </advisor>

  <source>
    {source}
  </source>

  <chat_history>
    {chat_history}
  </chat_history>

  Question: <question>{question}</question>
  `,
};

export const esgWrapper = {
  templateWrapper: `Act that you are an experienced consultant specialized in conducting environmental, social, and governance (ESG) due diligence for investors. Your role is to analyze company documentation and evaluate their ESG practices against international standards and best /practices. The documentation is located in <source>. When assessing documentation, you will: 1. Carefully review the provided materials 2. Compare the company's practices to relevant ESG standards and frameworks 3. Identify strengths, weaknesses, and areas for improvement 4. Highlight any red flags or concerns 5. Provide an objective assessment supported by specific examples from the documentation Your analysis process will involve: 1. Understanding the source and industry of the company 2. Methodically examining each ESG factor (environmental, social, governance) 3. Assessing the company's policies, procedures, and disclosures 4. Evaluating the quality and comprehensiveness of their ESG initiatives 5. Considering both qualitative and quantitative information 6. Forming evidence-based conclusions 7. Provide a risk rating for the issue area using: Very Low, Low, Moderate, High, Very High labels. Please conduct your analysis thoroughly and impartially. All responses should be generally 1000 to 2000 words, depending on the complexity of the <source>. Use direct quotes from the <source> where relevant as evidence. For each paragraph of text provide a direct reference to the page and file name from the <source>. For each quote of text provide a direct reference to the page and file name from the <source>. Each time you include a direct reference, include a reference as a footnote. Source: <source>{reportSource}</source>`,
};

// export const taskListWrapper = {
//   templateWrapper: `Your role is to analyze company documentation from the <source> and create an action plan task list.  An action plan is a list of tasks where each task is something that needs to be completed. Each task is unique and cannot exist twice in your response. Do not miss a single task from the <source>. Review the <source> material at least twice before making your response. Your response will be a list of tasks. It is of the utmost importance that you find all tasks, no tasks should be excluded from you list. Each task is split into four parts. The name, the summary, the email, and the difficulty score; each property is based on the source in <source>. The name of each task is a short sentence describing the task. The summary of the task is how the source documents in <source> relate to the task at this time and a short concise suggestion of how the task could be completed. The email of the task is an email that the user can send to recipients and stakeholders receive information about the task the email is important and must be formal with an opening, explanation of what is needed, and ending. The difficulty score of the task is a score from 0-10 based on how many people may be required to complete a task. If there are no source documents then say a summary could not be generated from the source documents. <source>{source}</source> <question>{question}</question>`,
//   jsonFormat: `Respond with a valid JSON object, only the JSON object should be returned.  The first character in your response should be the start of the JSON object "{{".  The JSON object that you will return must be complete, valid, and without any partial objects. The response JSON must not have any open, unclosed brackets. Your response must contain a list of tasks where each task has four fields: 'name', 'summary' email, 'difficulty'. for example: {{ tasks: [ {{name, summary, email, difficulty}}, {{name, summary, email, difficulty}}, {{name, summary, email, difficulty}} ] }}`,
// };

export const taskListWrapper = {
  templateWrapper: `Your role is to analyze the provided company documentation from <source> and create a comprehensive action plan task list. It is crucial that you identify and include **all** tasks mentioned in the source material, which may be numerous. Each task must be unique and clearly defined. Review the <source> material thoroughly and ensure that no tasks are omitted from your response. Each task should include the following components: 
  - Name: A concise title for the task (string).
  - Summary: A brief explanation of how the source relates to the task and a suggestion for completion (string).
  - Difficulty Score: A score from 0-10 indicating the complexity of the task based on the resources required (number).

  If no source documents are available, indicate that a summary could not be generated. Ensure your response is structured and clear. <source>{taskListSource}</source>`,

  jsonFormat: `Your response must be a complete and valid JSON object. No preamble. Nothing other than the JSON object.  If there is not a valid JSON object then return {{invalid:true}}. Do not include anu "back ticks". Ensure that there are no open or unclosed brackets. Here is an example of a valid JSON response:
  {{ tasks: [ 
    {{ "name": "Task 1", "summary": "Summary of task 1", "difficulty": 5 }}, 
    {{ "name": "Task 2", "summary": "Summary of task 2", "difficulty": 3 }} 
  ] }}.  The entire response cannot be more than 8000 characters, if it is then compress the list to be within 8000 characters.`,
};

export const sourcePlaceholder = {
  placeholder: `Given the following conversation and a follow up question, rephrase the follow up question to be a standalone question.  The document sections you return should not be duplicates of one that has already been given.
  <chat_history>
    {chat_history}
  </chat_history>
  Follow Up Input: {question}
  Standalone question:`,
  refinePlaceholder: 'Given the following source source material and a question, break the source material into blocks of 1000 characters and then score from 0-5 each block of 1000 characters in how it relates to the question.  Do NOT change the source material text under any circumstances.  Any blocks with a score equal to 1 or less should be removed. If there are any similar blocks then only 1 of the similar blocks should remain and the others should be removed, regardless of the score \nSource Material: {source}\nQuestion: {question}',
};

export const advisorPlaceholder = {
  placeholder: `Given the the following question, find regulatory laws or rules that most relates to the subject matter in the question: {question}`,
  refinePlaceholder: 'Given the following advisor source material and a question, select sections from the advisor source material that are relevant to answering the question with the most honest and truthworthy opinion using the advisor gathered from the sources to make the best recommendation.\nAdvisor base source material: {source}\nQuestion: {question}',
};

export const taskListWrapperEmail = {
  templateWrapper: `Your role is a external party requesting an update on the <task>, you are polite, and professional. You are not verbose and use specific language to state what you need. You provide one sentence of justification for why you need the information. You specify when you hope to receive the information. Create an email based on the task that is provided in <task>: <task>{question}</task>`,
};

export const taskListWrapperSummary = {
  templateWrapper: `Create an summary based on the task that is provided in <task> using the context provided in the <source> : <task>{question}</task> <source>{summarySource}</source>`,
};

export const reviseEmailPlaceholder = {
  templateWrapper: `Your role is to revise the email that is provided in <query>: <query>{question}</query>`,
};

export const reviseSummaryPlaceholder = {
  verifySummary: `{question}`,
  templateWrapper: `Extract information from documentation found in <source>{summarySource}</source> based on <query>{question}</query>.`,
};

export const demoAnalysis = {
  templateWrapper: `You are an experienced consultant specialized in due diligence for investors. Your role is to provide financial information related to the question based on the source material. Use the information within the <source> section to answer the question. If you don't know the answer, just say you don't know and elaborate on how this information might be obtained from the documentation or elsewhere. DO NOT make up an answer. All responses should be generally 1000 to 2000 words, depending on the complexity of the <source>. Use direct quotes from the <source> where relevant as evidence. For each paragraph of text provide a direct reference to the page and file name from the <source>. For each quote of text provide a direct reference to the page and file name from the <source>.  Each time you include a direct reference, it should look like this <direct-reference-page number>page number</direct-reference-page-number><direct-reference-filename>reference file name</direct-reference-filename>. If the question is not related to the source or chat history, politely respond that you are tuned to only answer questions that are related to the source. <source>{source}</source> Question: {question}`,
};

export const refinementPlaceholders = {
  taskListRequest: `Make sure that the response in the json format.`,
  emailExplainRequest: 'In 15 words explain the mood that this email is written: {refineEmailRequest}',
  combineRefineRequest: 'Based on the task list "{taskListRequest}" create a new task list that uses the mood from the email "{refineEmailRequest}" to write each of the tasks and if any task is very similar then remove one of them.',
};

export const reportPlaceholders = {
  templateWrapper: 'Based on the <question> analyze the <source>. <source>{source}</source> <question>{question}</question>',
  sourcePlaceholder: '{question}',
};

export const attributeExtractGen = {
  vectorStore: `Your response should be to first analyze the goal of question, and then produce a "single simplified question" that will be used to search through a vector store to retrieve relevant documents related to the question: <question>{question}</question>.  Nothing other than the "single simplified question" should be returned.  No other text should be included.  Do not include any brackets like {{}}`,
  templateWrapper: `Analyze the material in <source> and identify the smallest, specific units of meaning or information that contribute to the report. These units should be non-generic and directly tied to the content and purpose of the report. For each unit of meaning you identify, provide the following: 
  - Section: A concise description of the unit of meaning in a few words (e.g., 'Key Finding', 'Statistical Evidence', 'Conclusion about X'). - Prompt: A corresponding prompt that will guide an LLM to gather this specific unit of meaning from the {{source}} material. <source>{source}</source>`,
  jsonFormat: `Structure the output as JSON with the following format: {{
    "units_of_meaning": [
        {{
            "section": "unit of meaning in a few words",
            "prompt": "create the llm query that will gather the unit of meaning based on the {{source}} material"
        }},
        {{
            "section": "unit of meaning in a few words",
            "prompt": "create the llm query that will gather the unit of meaning based on the {{source}} material"
        }}
    ]
}}`,
};

export const weAlignReview = {
  templateWrapper: 'Take the tasks',
  jsonFormat: `Your response must be a complete and valid JSON object. No preamble. Nothing other than the JSON object.  If there is not a valid JSON object then return {{invalid:true}}. Do not include anu "back ticks". Ensure that there are no open or unclosed brackets. Here is an example of a valid JSON response:
  {{ tasks: [ 
    {{ "name": "Task 1", "summary": "Summary of task 1", "difficulty": 5 }}, 
    {{ "name": "Task 2", "summary": "Summary of task 2", "difficulty": 3 }} 
  ] }}.  The entire response cannot be more than 8000 characters, if it is then compress the list to be within 8000 characters.`,
};
