import { vectorStore } from '@src/components/ADT/MagicTable';
import { convertFileToBase64 } from './ConvertBase64';
import { ingestFile } from './Pinecone';
import { startExtractRequest } from './Textract';
import { convertTextractToLangChain, LangChainDocument } from './TextractProcessor';
import UploadToS3 from './UploadToS3';

type uploadFilesProps = {
  files: FileList;
  vectorStore?: vectorStore;
};

export const uploadFilesUtil = async ({ files, vectorStore }: uploadFilesProps): Promise<(LangChainDocument[] | undefined)[]> => {
  const fileOperations = Array.from(files).map(async (file: File): Promise<LangChainDocument[] | undefined> => {
    try {
      const fileExtension = file.name.split('.').pop();
      const baseFileName = file.name.replace(/\.[^/.]+$/, '').replace(/[^a-zA-Z0-9_-]/g, '-');
      const fileName = `${baseFileName}-${Date.now()}.${fileExtension}`.toLowerCase();
      const uploadComplete = await UploadToS3({ data: file, path: fileName });
      console.log('uploadComplete', uploadComplete);
      const base64Data = await convertFileToBase64(file);
      const imageData = {
        bucketName: 'banabanabucket9571c-dev',
        objectKey: fileName,
        base64Data: base64Data,
      };
      let rawDocs;
      if (fileExtension === 'pdf') {
        const extract = await startExtractRequest({
          imageData,
          extractType: 'text',
        });
        if (extract) {
          rawDocs = convertTextractToLangChain(extract, fileName);
        }
      } else {
        console.log('NOT A PDF');
        throw new Error('NOT A PDF');
        // const llamaExtract = await LlamaParse(file);
        // console.log('llamaExtract', llamaExtract);
      }
      if (vectorStore && rawDocs) {
        // process the textract code to prepare it for ingestion
        // todo bring this into the backend with a node script
        await ingestFile({ rawDocs, indexId: vectorStore.indexId, namespace: vectorStore.namespace });
      }
      if (rawDocs) return rawDocs;
    } catch (error) {
      console.error('Error processing file:', file.name, error);
    }
  });

  return await Promise.all(fileOperations);
};

export const extractFileNames = (completedFiles: Array<any>) => {
  return completedFiles.map(fileArray => fileArray[0]?.path).filter(path => path !== undefined);
};
