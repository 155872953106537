import { Ionicons } from '@expo/vector-icons';
import CountdownTimer from '@src/components/Countdown';
import { DemoContext } from '@src/components/Demos/Context';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import { uploadFilesUtil } from '@src/utils/FileUpload';
import React, { useContext, useRef, useState } from 'react';
import { ActivityIndicator, TouchableOpacity } from 'react-native';
import { vectorStore } from '.';

interface UploadRowProps {
  children: JSX.Element;
  onPress?: (event: FileList | null) => Promise<void>;
}

const UploadRow = ({ children, onPress }: UploadRowProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<FileList | string | undefined>();
  const { checkLoading, addLoading, removeLoading } = useContext(LoadingContext);
  const [uploadComplete, setUploadComplete] = useState(false);

  const handleOnPress = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current?.click();
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        addLoading('uploadFile1');
        setFile(event.target.files);
        if (onPress) await onPress(event.target.files);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        removeLoading('uploadFile1');
      }
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        multiple
      />
      {!checkLoading('uploadFile1') && !uploadComplete ? (
        <TouchableOpacity
          onPress={handleOnPress}
          style={{ alignSelf: 'flex-start', width: '100%' }}
        >
          {children}
        </TouchableOpacity>
      ) : (
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <ActivityIndicator
            size={24}
            color="#000"
          />
          <TextBodyMedium style={[Styles.h5, { marginLeft: 10 }]}>Please wait. Your file is being uploaded and processed.</TextBodyMedium>
          <TextBodyRegular style={[Styles.h7, { marginLeft: 10 }]}>Depending on the number of files you uploaded and their file size, this can take 30 seconds to 2 minutes. Thank you for your patience.</TextBodyRegular>
        </View>
      )}
    </>
  );
};

type RowUploadProps = {
  addRow?: (vs: vectorStore) => void;
};

const RowUpload = ({ addRow }: RowUploadProps) => {
  const { getPineconeIndex } = useContext(DemoContext);
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      addLoading('loadPineconeIndex');
      // create pinecone index for this user.  each user should have only 1 index associated with them, where each index includes namespaces that are associated with projects
      // we need to get the indexId and namespace
      const newPineconeData = await getPineconeIndex();
      if (newPineconeData) {
        const indexId = newPineconeData?.indexId;
        const namespace = newPineconeData?.namespace;
        try {
          const vs = { indexId: 'demo-analysis', namespace } as vectorStore;
          await uploadFilesUtil({ files, vectorStore: vs });
          setTimeout(() => {
            // hack because sometimes the namespace in pinecone is not ready for search
            removeLoading('loadPineconeIndex');
            if (addRow) addRow({ indexId, namespace });
          }, 15000);
        } catch (error) {
          removeLoading('loadPineconeIndex');
          console.error('Error uploading file:', error);
        }
      }
    }
  };

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: 50,
            justifyContent: 'center',
            borderRightWidth: 1,
            borderRightColor: '#eee',
            alignItems: 'center',
          }}
        >
          <Ionicons name="add" />
        </View>
        {!checkLoading('loadPineconeIndex') && (
          <UploadRow onPress={handleFileUpload}>
            <TextBodyRegular
              style={[
                Styles.h6,
                {
                  padding: 20,
                },
              ]}
            >
              Add row
            </TextBodyRegular>
          </UploadRow>
        )}
        {checkLoading('loadPineconeIndex') && (
          <View style={{ flexDirection: 'row', padding: 12, alignItems: 'center' }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 20 }}>
              <ActivityIndicator
                size={16}
                color="black"
              />
              <TextHeader style={[Styles.h6, { marginLeft: 10 }]}>Loading</TextHeader>
            </View>
            <TextBodyRegular style={[Styles.h6, { marginHorizontal: 10 }]}>Please wait. This can take 30 seconds depending on the number of files.</TextBodyRegular>
            <CountdownTimer
              size={14}
              initialTime={30}
            />
          </View>
        )}
      </View>
    </>
  );
};

export default RowUpload;
