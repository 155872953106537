/**
 * Demo Context
 *
 * The purpose of this Context is to control the auto-deletion of demo content when a user leaves the website
 */
import { listNamespacesByIndexId, PINECONE_PLAN, QUOTAS } from '@src/utils/Pinecone';
import React, { PropsWithChildren, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface PineconeData {
  indexId: string;
  namespace: string;
}
export type DemoContextProps = {
  getPineconeIndex: () => Promise<PineconeData | undefined>;
};

export const DemoContext = React.createContext<DemoContextProps>({} as DemoContextProps);

export const { Consumer } = DemoContext;
export default Consumer;

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const currentIndex = useRef(0);
  const indices = ['demo-0', 'demo-1', 'demo-2', 'demo-3', 'demo-4'];

  const getPineconeIndex = async () => {
    const indexId = indices[currentIndex.current];
    let namespaces;
    try {
      namespaces = await listNamespacesByIndexId(indexId);
    } catch (e) {
      console.log('error listNamespacesByIndexId', e);
    }

    if (namespaces) {
      if (Object.keys(namespaces).length <= QUOTAS[PINECONE_PLAN].NamespacesPerServerlessIndex) {
        // create a new namespace
        return { indexId, namespace: uuidv4() };
      } else {
        // use a different index
        if (currentIndex.current < indices.length) {
          currentIndex.current++;
          return getPineconeIndex();
        }
      }
    } else {
      return null;
    }
  };

  // const deleteDemoContent = async () => {
  //   const indexId = indices[currentIndex.current];
  //   await deletePineconeIndex(indexId);
  //   createPineconeIndex(indexId);
  // };

  // useEffect(() => {
  //   return () => {
  //     // this will run when the component is unmounted aka the user leaves the website
  //     deleteDemoContent();
  //   };
  // }, []);

  const contextValue: DemoContextProps = useMemo(() => ({ getPineconeIndex }), [getPineconeIndex]);
  return <DemoContext.Provider value={contextValue}>{children}</DemoContext.Provider>;
}
