export function isCircular(obj: any, path: string = 'root'): boolean {
  const seenObjects = new WeakSet();

  function detect(obj: any, currentPath: string): boolean {
    if (obj && typeof obj === 'object') {
      if (seenObjects.has(obj)) {
        console.error(`Circular reference detected at: ${currentPath}`);
        return true;
      }
      seenObjects.add(obj);
      for (const key of Object.keys(obj)) {
        const newPath = `${currentPath}.${key}`; // Build the path to the current property
        if (detect(obj[key], newPath)) {
          return true; // Return true if a circular reference is found
        }
      }
    }
    return false;
  }

  return detect(obj, path);
}
