import { RootNavigator } from '@src/navigation';
import { ModalContext } from '@src/providers/Modal';
import React, { useContext } from 'react';
import WrapperModal from '../WrapperModal';

const Wrapper = () => {
  const { modalContent } = useContext(ModalContext);

  return (
    <>
      {modalContent && <WrapperModal />}
      <RootNavigator />
    </>
  );
};

export default Wrapper;
