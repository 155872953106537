/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '@src/types/navigation';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/'), 'https://app.baltare.com', 'https://app.baltare.com/', 'http://app.baltare.com', 'https://*.baltare.com'],
  config: {
    screens: {
      demo: {
        screens: {
          demoLanding: 'demo',
          taskList: 'demo/task-list/:indexId/:namespace',
          customTaskList: 'demo/custom-task-list/:indexId/:namespace',
          mining: 'demo/mining',
          'all-data-table': 'demo/all-data-table',
          reports: 'demo/reports/:indexId/:namespace',
          attributeGen: 'demo/attribute-gen',
        },
      },
      wealign: {
        screens: {
          dashboard: 'wealign/backofficeteam',
          create: 'wealign/create',
          review: 'wealign/create/review',
          generated: 'wealign/create/generated',
          submit: 'wealign/s/:id',
          help: 'wealign/help',
        },
      },
      loggedOut: {
        screens: {
          login: 'login',
        },
      },
      loggedIn: {
        screens: {
          investor: {
            screens: {
              dashboard: '',
              project: 'project/:projectId',
              report: 'project/:projectId/report/:categories',
            },
          },
          backoffice: {
            screens: {
              backofficeDashboard: 'backoffice/dashboard',
              advisorDetail: 'backoffice/advisor/:advisorId',
              advisorNew: 'backoffice/advisor/new',
            },
          },
        },
      },
      NotFound: '*',
    },
  },
};

export default linking;
