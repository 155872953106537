import Screen from '@src/components/Screen';
import { TextBodyMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import useResponsive from '@src/hooks/useResponsive';
import React from 'react';
import { Linking } from 'react-native';

const MakeHelp = () => {
  const { isWideScreen } = useResponsive();

  const gotoFeedback = () => {
    Linking.openURL('https://baltare.convas.io/');
  };

  return (
    <Screen>
      <View
        style={{
          paddingHorizontal: isWideScreen ? 100 : 20,
          paddingVertical: isWideScreen ? 100 : 30,
        }}
      >
        <TextBodyMedium style={[Styles.h1, { marginTop: 10 }]}>Welcome to weAlign:</TextBodyMedium>
        <TextBodyMedium style={[Styles.h2, { marginTop: 10 }]}>We help gather information painlessly</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 30 }]}>WeAlign helps you gather and verify information efficiently by automating the tedious parts of collecting documents and following up with people. Think of it as your personal assistant that keeps track of everything you need and gently reminds others to provide it.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h2, { marginTop: 20 }]}>What Makes WeAlign Different?</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Instead of sending multiple emails and manually checking if you've received what you need, WeAlign creates a central hub for your information requests. The system automatically verifies if submitted documents actually answer your questions, saving you from having to check each submission manually. When something's missing, WeAlign immediately lets people know what else they need to provide.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h2, { marginTop: 20 }]}>Getting Started</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Starting your first information request (we call it an "alignment") is simple:</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Step 1: Share Your Needs</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Tell us what information you're looking to gather. Be as specific as possible about what you need - this helps our system accurately verify submissions later. You'll see a summary of your requirements and a preview of how they'll appear to others.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Step 2: Review and Generate</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Take a moment to review:</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- The summary of what you're requesting</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- The list of specific items needed</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- The email that will be sent to participants</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- When everything looks right, click "Generate" to create your unique alignment URL.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Step 3: Share and Track</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Share your unique URL with anyone who needs to provide information. They'll see a clear list of what's needed and can start uploading documents or typing responses right away. Each submission is automatically checked against your requirements and summary information is filled in and you’ll see references to the reference materials and documents.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h3, { marginTop: 30 }]}>How It Works</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>For You (The Requester)</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>When you create an alignment, you access:</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Real-time updates when documents are submitted</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- The ability to communicate with participants through chat</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Options to modify requirements or add new ones</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Automatic notifications when deadlines approach</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- A secure document storage system</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>For Your Contributors</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>People you share the URL with can:</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- See exactly what information is needed</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Upload documents (PDFs, spreadsheets, etc.) or type responses directly</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Get immediate feedback if their submission doesn't meet requirements</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Ask questions through the built-in chat</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Track their progress toward completing all requirements</TextBodyMedium>
        <TextBodyMedium style={[Styles.h3, { marginTop: 30 }]}>Smart Features That Save Time</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Automatic Verification</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>When someone uploads a document or types a response, WeAlign automatically checks if it satisfies your requirements. If something's missing, the system immediately lets them know what else is needed - no manual checking required.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Built-in Communication</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Need to clarify something? Click on any task to start a conversation about it. You can ask questions, provide additional context, or request clarification, all without leaving the platform.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Deadline Management</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Set a deadline, and WeAlign automatically reminds participants as it approaches. No more sending follow-up emails - the system handles it for you.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Security First</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Your information stays private and secure:</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Each alignment has a unique, secure URL</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- Documents are only visible to you and the person who uploaded them</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- All data is encrypted and stored securely</TextBodyMedium>
        <TextBodyMedium style={[Styles.p, { marginLeft: 10, marginTop: 10 }]}>- You control who has access to your alignment</TextBodyMedium>
        <TextBodyMedium style={[Styles.h4, { marginTop: 20 }]}>Getting Help</TextBodyMedium>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            flexShrink: 1,
            marginTop: 10,
          }}
        >
          <TextBodyMedium style={[Styles.p, {}]}>WeAlign is continuously improving based on user feedback. We'd love to hear your thoughts! Share your feedback at</TextBodyMedium>
          <Pressable onPress={gotoFeedback}>
            <TextBodyMedium style={[Styles.p, { marginLeft: 5, textDecorationLine: 'underline' }]}>https://baltare.convas.io/</TextBodyMedium>
          </Pressable>
        </View>
        <TextBodyMedium style={[Styles.p, { marginTop: 10 }]}>Remember, this is just the beginning - we're constantly adding new features to make information gathering even easier. If you need help or have questions, you can always click on any task for assistance or use our chat interface for guidance.</TextBodyMedium>
      </View>
    </Screen>
  );
};

export default MakeHelp;
