/**
 * Assessment Context
 *
 * The purpose of this Context is to make the static assessment data available and retrievable from the backend.
 * There are many assessments that we support, and each one has a unique structure.
 * Each assessment is not representative of a user's assessment.  Instead, that lives within the user's "Project" @see providers/Project
 */
import constructionLifecycle from '@src/assets/json/assessments/constructionLifecycle.json';
import corporateIndividual from '@src/assets/json/assessments/corporateIndividual.json';
import ddSiteAssetCondition from '@src/assets/json/assessments/ddSiteAssetCondition.json';
import environmentalImpact from '@src/assets/json/assessments/environmentalImpact.json';
import financialReview from '@src/assets/json/assessments/financialReview.json';
import investmentReview from '@src/assets/json/assessments/investmentReview.json';
import investorApplication from '@src/assets/json/assessments/investorApplication.json';
import keyRisks from '@src/assets/json/assessments/keyRisks.json';
import legalDocuments from '@src/assets/json/assessments/legalDocuments.json';
import operationalReview from '@src/assets/json/assessments/operationalReview.json';
import outputAssessment from '@src/assets/json/assessments/outputAssessment.json';
import screening from '@src/assets/json/assessments/screening.json';
import socialValueCriteria from '@src/assets/json/assessments/socialValueCriteria.json';
import tarff from '@src/assets/json/assessments/tarff.json';
import taxImpact from '@src/assets/json/assessments/taxImpact.json';
import projectJson from '@src/assets/json/project.json';
import slidesJson from '@src/assets/json/slides.json';
import { TypeGuide } from '@src/components/Assessment/Guide';
import { generateClient } from 'aws-amplify/api';
import React, { PropsWithChildren, useMemo, useState } from 'react';

// Import the JSON file and typecast it to the Slides interface

interface TypeSlides {
  [key: string]: TypeAssessmentSlide;
}
const slides = slidesJson as TypeSlides;

export interface TypeAssessment {
  module: string;
  title: string;
  progress: number;
  slides: Array<string>;
}
export interface TypeAssessmentSlide {
  key: string;
  title: string;
  type: string;
  guide: TypeGuide;
  options?: Array<Record<string, string>>;
  placeholder?: string;
  body?: string;
  documents?: Array<Record<string, string>>;
}

export type AssessmentContextProps = {
  getAssessments: () => void;
  getAssessmentById: (id: string) => TypeAssessment | undefined;
  getSlideById: (id: string) => TypeAssessmentSlide;
};
export const AssessmentContext = React.createContext<AssessmentContextProps>({
  getAssessments: () => {},
  getAssessmentById: _ => undefined,
  getSlideById: _ => undefined,
});

export const { Consumer } = AssessmentContext;
export default Consumer;

export function Provider({
  children,
}: PropsWithChildren<Record<symbol, symbol>>) {
  const client = generateClient();
  const [currentAssessment, setCurrentAssessment] = useState<
    TypeAssessment | undefined
  >();

  async function getAssessments() {
    return projectJson;
  }

  function getAssessmentById(id: string): TypeAssessment | undefined {
    // at some point this will become a graphql function that returns the appropriate assessment information
    switch (id) {
      case 'tarff':
        return tarff;
      case 'screening':
        return screening;
      case 'constructionLifecycle':
        return constructionLifecycle;
      case 'corporateIndividual':
        return corporateIndividual;
      case 'ddSiteAssetCondition':
        return ddSiteAssetCondition;
      case 'environmentalImpact':
        return environmentalImpact;
      case 'financialReview':
        return financialReview;
      case 'investmentReview':
        return investmentReview;
      case 'keyRisks':
        return keyRisks;
      case 'legalDocuments':
        return legalDocuments;
      case 'operationalReview':
        return operationalReview;
      case 'outputAssessment':
        return outputAssessment;
      case 'socialValueCriteria':
        return socialValueCriteria;
      case 'taxImpact':
        return taxImpact;
      case 'investorApplication':
        return investorApplication;
      default:
        return screening;
    }
  }

  function getSlideById(id: string): TypeAssessmentSlide {
    // at some point this will become a graphql function that returns the appropriate assessment information
    return slides[id];
  }

  const contextValue: AssessmentContextProps = useMemo(
    () => ({
      getAssessments,
      getAssessmentById,
      getSlideById,
    }),
    [],
  );

  return (
    <AssessmentContext.Provider value={contextValue}>
      {children}
    </AssessmentContext.Provider>
  );
}
