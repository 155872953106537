import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { JsonMap } from '@segment/analytics-react-native';
import useCustomAnalytics from '@src/services/analytics';
import React, { PropsWithChildren, useRef } from 'react';
import LinkingConfiguration from '../navigation/LinkingConfiguration';

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef();
  const { screen } = useCustomAnalytics();

  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      ref={navigationRef}
      onStateChange={async () => {
        if (navigationRef) {
          const curRoute = navigationRef.getCurrentRoute();
          if (curRoute) {
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef.getCurrentRoute()?.name;

            if (previousRouteName !== currentRouteName) {
              // analytics tracking
              screen(currentRouteName, navigationRef.getCurrentRoute()?.params as JsonMap);

              // Save the current route name for later comparison
              routeNameRef.current = currentRouteName;
            }
          }
        }
      }}
    >
      {children}
    </NavigationContainer>
  );
}
