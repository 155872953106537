import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import Colors from './shared/Colors';
import { TextBodyMedium } from './shared/StyledText';
import Styles from './shared/Styles';

interface InfoToolTipProps {
  onPress?: () => void;
  disabled?: boolean;
  theme?: string;
  style?: any;
  fontStyle?: any;
  children?: JSX.Element | string | undefined;
  icon?: string;
  iconSet?: string;
  active?: boolean;
  toolTip?: String;
  modal?: JSX.Element | undefined;
  onHoverCb?: () => void;
  iconBefore?: boolean;
}
const InfoToolTip = ({ disabled, toolTip }: InfoToolTipProps) => {
  const [onHover, setOnHover] = useState(false);
  const handleHoverIn = () => {
    setOnHover(true);
  };
  const handleHoverOut = () => {
    setOnHover(false);
  };

  return (
    <>
      <Pressable
        disabled={disabled}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        style={{
          flex: 1,
          flexShrink: 1,
          alignSelf: 'flex-start',
        }}
      >
        <MaterialCommunityIcons
          name={'information'}
          size={24}
          color="#999"
          style={{}}
        />
      </Pressable>
      {onHover && toolTip && (
        <View style={{ position: 'absolute', width: '50%', bottom: 25, padding: 10, zIndex: 1000, backgroundColor: '#fff' }}>
          <TextBodyMedium style={[Styles.h7, { color: Colors.darkBlue }]}>{toolTip}</TextBodyMedium>
        </View>
      )}
    </>
  );
};

export default InfoToolTip;
