import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { ModalContext } from '@src/providers/Modal';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Pressable, View } from 'react-native';
import Colors from '../shared/Colors';
import { TextBodyMedium, TextBodyRegular } from '../shared/StyledText';
import Styles from '../shared/Styles';

interface ButtonProps {
  onPress?: () => void;
  disabled?: boolean;
  theme?: string;
  style?: any;
  fontStyle?: any;
  children?: JSX.Element | string | undefined;
  iconAfter?: string;
  iconBefore?: string;
  iconSet?: string;
  active?: boolean;
  toolTip?: String;
  modal?: JSX.Element | undefined;
  onHoverCb?: () => void;
  id?: string;
}
const Button = ({ id, onPress, disabled, style, fontStyle, children, theme, iconAfter, iconSet, active, toolTip, modal, onHoverCb, iconBefore }: ButtonProps) => {
  const { checkButtonModal, openButtonModal, closeButtonModal } = useContext(ModalContext);
  const modalRef = useRef(null); // Create a ref for the modal
  let iconColor = 'white';

  const [onHover, setOnHover] = useState(false);
  const handleHoverIn = () => {
    if (onHoverCb) onHoverCb();
    setOnHover(true);
  };
  const handleHoverOut = () => {
    if (onHoverCb) onHoverCb();
    setOnHover(false);
  };
  let styles = [Styles.button, style];
  let fontStyles = [Styles.h4, fontStyle];

  if (theme?.includes('primary')) {
    if (disabled) {
      styles = [
        ...styles,
        {
          paddingHorizontal: 25,
          paddingVertical: 10,
          borderColor: Colors.buttonBackground,
          borderWidth: 2,
          backgroundColor: '#fff',
          borderRadius: 5,
        },
      ];
      fontStyles = [{ ...Styles.h4, color: '#999' }];
    } else {
      styles.push({
        paddingHorizontal: 25,
        paddingVertical: 10,
        borderRadius: 5,
        borderWidth: 2,
        backgroundColor: onHover ? Colors.buttonBackground : Colors.buttonBackground,
        borderColor: onHover ? Colors.lightGrayPurple : Colors.buttonBackground,
      });
      fontStyles = [{ ...Styles.h4, color: onHover ? '#fff' : '#fff' }];
    }
  }

  if (theme?.includes('secondary')) {
    if (disabled) {
      styles = [
        ...styles,
        {
          paddingHorizontal: 25,
          paddingVertical: 10,
          borderColor: Colors.buttonSecondaryBackground,
          borderWidth: 2,
          backgroundColor: '#fff',
          borderRadius: 5,
        },
      ];
      fontStyles = [{ ...Styles.h4, color: '#999' }];
    } else {
      styles.push({
        paddingHorizontal: 25,
        paddingVertical: 10,
        borderRadius: 5,
        borderWidth: 2,
        backgroundColor: onHover ? Colors.buttonSecondaryBackground : Colors.buttonSecondaryBackground,
        borderColor: onHover ? Colors.lightGrayPurple : Colors.buttonSecondaryBackground,
      });
      fontStyles = [{ ...Styles.h4, color: onHover ? '#fff' : '#fff' }];
    }
  }

  if (theme?.includes('tertiary')) {
    if (disabled) {
      styles = [
        ...styles,
        {
          paddingHorizontal: 25,
          paddingVertical: 10,
          borderColor: 'transparent',
          borderWidth: 2,
          backgroundColor: '#fff',
          borderRadius: 5,
        },
      ];
      fontStyles = [{ ...Styles.h4, color: '#999' }];
    } else {
      styles.push({
        paddingHorizontal: 25,
        paddingVertical: 10,
        borderRadius: 5,
        borderWidth: 2,
        backgroundColor: onHover ? Colors.buttonTertiaryBackground : 'transparent',
        borderColor: onHover ? 'transparent' : Colors.buttonTertiaryBackground,
      });
      fontStyles = [{ ...Styles.h4, color: onHover ? '#000' : '#000' }];
    }
  }

  if (theme?.includes('simple')) {
    styles.push(Styles.buttonSimple);
    fontStyles.push({
      fontSize: 14,
      paddingBottom: 3,
      borderBottomWidth: 1,
    });
  }

  if (theme?.includes('small')) {
    styles.push({
      borderWidth: 1,
      paddingHorizontal: 15,
      paddingVertical: 5,
    });
    fontStyles = [{ ...Styles.h6, color: onHover ? '#fff' : '#fff' }];
    iconColor = onHover ? '#fff' : '#fff';
  }

  if (theme?.includes('active')) {
    styles.push({ backgroundColor: Colors.buttonBackgroundActive, paddingHorizontal: 16, borderWidth: 0, ...Styles.shadow, shadowOpacity: 1, shadowColor: Colors.primary });
    fontStyles = [{ ...Styles.h6, color: onHover ? '#fff' : '#fff', justifyContent: 'center' }];
    iconColor = onHover ? '#fff' : '#fff';
  }

  if (theme?.includes('light') || (!theme && !disabled)) {
    styles.push(Styles.buttonFancy);
  }

  if (theme?.includes('red')) {
    styles.push([{ ...Styles.buttonFancy, backgroundColor: '#FF0000' }]);
  }

  if (active) {
    styles.push({
      backgroundColor: Colors.buttonBackgroundActive,
      cursor: 'default',
      borderWidth: 2,
      borderColor: Colors.buttonBackgroundActive,
    });
  }

  const handleOnPress = () => {
    onPress && onPress();
    if (modal && id) {
      openButtonModal(id);
    }
  };

  // Effect to handle clicks outside the modal
  useEffect(() => {
    if (modal) {
      const handleClickOutside = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && id) {
          closeButtonModal(id); // Close the modal if clicked outside
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [modal]);

  return (
    <>
      <Pressable
        ref={modalRef}
        onPress={handleOnPress}
        disabled={disabled}
        style={[styles.map(style => style), { position: 'relative', userSelect: 'none' }]}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
      >
        {onHover && toolTip && (
          <View style={{ alignSelf: 'center', alignItems: 'center', position: 'absolute', maxWidth: '120%', bottom: '120%', padding: 10, zIndex: 1000, backgroundColor: Colors.toolTip }}>
            <TextBodyMedium style={[Styles.h7, { color: Colors.darkBlue }]}>{toolTip}</TextBodyMedium>
            <View
              style={{
                position: 'absolute',
                bottom: -10,
                width: 0,
                height: 0,
                borderLeftWidth: 10,
                borderRightWidth: 10,
                borderTopWidth: 10,
                borderTopColor: Colors.toolTip,
                borderLeftColor: 'transparent',
                borderRightColor: 'transparent',
                borderBottomColor: 'transparent',
              }}
            ></View>
          </View>
        )}
        {id && checkButtonModal(id) && modal && <View style={{ alignItems: 'center', position: 'absolute', bottom: '120%', padding: 10, zIndex: 10, backgroundColor: Colors.toolTip }}>{modal}</View>}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {iconBefore && iconSet === 'MaterialCommunityIcons' ? (
            <MaterialCommunityIcons
              name={iconBefore}
              size={24}
              color="black"
            />
          ) : (
            <Ionicons
              name={iconBefore}
              size={24}
              style={{}}
              color={iconColor}
            />
          )}
          {typeof children === 'string' ? (
            <TextBodyRegular
              style={[
                fontStyles,
                {
                  justifyContent: 'center',
                  marginHorizontal: 10,
                  alignSelf: 'center',
                },
              ]}
            >
              {children}
            </TextBodyRegular>
          ) : (
            children
          )}
          {iconAfter && iconSet === 'MaterialCommunityIcons' ? (
            <MaterialCommunityIcons
              name={iconAfter}
              size={24}
              color="black"
            />
          ) : (
            <Ionicons
              name={iconAfter}
              size={24}
              style={{}}
              color={iconColor}
            />
          )}
        </View>
      </Pressable>
    </>
  );
};

export default Button;
