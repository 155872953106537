// https://us-east-1.console.aws.amazon.com/cognito/v2/idp/user-pools/us-east-1_Sp4VgjOVX/app-integration/clients/3en1k17it0sp0ihcv7j4vr0dg?region=us-east-1

import { useAuthenticator } from '@aws-amplify/ui-react-native';
import bugsnagNotify from '@src/utils/BugsnagNotify';
import { AuthUser, FetchUserAttributesOutput, fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import React, { PropsWithChildren, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { LoadingContext } from './Loading';

export type UserContextProps = {
  user: AuthUser | undefined;
  userAttributes: IUserAttributes | undefined;
  signOut: (data?: any) => void; // this is not quite right but for some reason the type "AuthEventData" does not exist as an export on the amplify auth npm
};
export const UserContext = React.createContext<UserContextProps>({
  user: undefined,
  userAttributes: undefined,
  signOut: () => {},
});

export const { Consumer } = UserContext;
export default Consumer;

interface IUserAttributes extends FetchUserAttributesOutput {
  groups: string | undefined;
  indexId?: string;
  inviteCode?: string;
  email?: string | undefined;
  email_verified?: string | undefined;
  name?: string | undefined;
  sub?: string | undefined;
}

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const { addLoading, removeLoading } = useContext(LoadingContext);
  const { user, signOut } = useAuthenticator();
  const [userAttributes, setUserAttributes] = useState<IUserAttributes | undefined>();

  const handleListeners = async () => {
    try {
      addLoading('user');
      Hub.listen('auth', ({ payload }) => {
        switch (payload.event) {
          case 'signedIn':
            // todo this is not pretty but for some reason authenticator will not update the user state on sign in
            forceUpdate();
            window.location.reload();
            console.log('user have been signedIn successfully.');
            break;
          case 'signedOut':
            console.log('user have been signedOut successfully.');
            break;
          case 'tokenRefresh':
            console.log('auth tokens have been refreshed.');
            break;
          case 'tokenRefresh_failure':
            console.log('failure while refreshing auth tokens.');
            break;
          case 'signInWithRedirect':
            console.log('signInWithRedirect API has successfully been resolved.');
            break;
          case 'signInWithRedirect_failure':
            console.log('failure while trying to resolve signInWithRedirect API.');
            break;
          case 'customOAuthState':
            console.info('custom state returned from CognitoHosted UI');
            break;
        }
      });
    } catch (err) {
      bugsnagNotify(err as string);
    } finally {
      removeLoading('user');
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      try {
        if (user) {
          const [attributes, session] = await Promise.all([await fetchUserAttributes(), await fetchAuthSession()]);
          console.log('attributes', attributes);
          const groups = (session.tokens?.idToken?.payload['cognito:groups'] as string) || undefined;
          setUserAttributes({ groups, indexId: attributes['custom:indexId'], inviteCode: attributes['custom:inviteCode'], ...attributes });
        } else {
          const session = await fetchAuthSession();
          console.log('session', session);
        }
      } catch (error) {
        console.error('Error fetching session:', error);
      }
    };

    // fetchSession();
  }, [user]);

  // TODO we need to get the unique initiator propreitary assessment object, which will need to be obfuscated and decoded by the frontend?

  const contextValue: UserContextProps = useMemo(
    () => ({
      user,
      userAttributes,
      signOut,
    }),
    [user, userAttributes],
  );

  useEffect(() => {
    handleListeners();
  }, []);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
}
