import { StyleSheet } from 'react-native';
import Colors from './Colors';

const h1 = {
  color: Colors.darkBlue,
  fontSize: 42,
};
const h2 = {
  color: Colors.darkBlue,
  fontSize: 28,
};
const h3 = {
  color: Colors.darkBlue,
  fontSize: 25,
};
const h4 = {
  color: Colors.darkBlue,
  fontSize: 20,
};
const h5 = {
  color: Colors.darkBlue,
  fontSize: 18,
  letterSpacing: 0.5,
  lineHeight: 25,
};
const h6 = {
  color: Colors.darkBlue,
  fontSize: 16,
};
const h7 = {
  color: Colors.darkBlue,
  fontSize: 14,
};
const h8 = {
  color: Colors.darkBlue,
  fontSize: 12,
};
const h9 = {
  fontSize: 10,
};

export default StyleSheet.create({
  container: {},
  input: {
    resize: 'vertical',
    minHeight: 40,
    fontSize: 18,
    borderColor: '#ddd',
    borderRadius: 5,
    paddingVertical: 15,
    paddingHorizontal: 15,
    color: '#000',
    backgroundColor: '#fff',
    fontFamily: 'inter-regular',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 15,
  },
  inputDisabled: {
    backgroundColor: '#ddd',
  },
  inputLight: {
    borderWidth: 2,
    borderColor: '#fff',
    backgroundColor: 'transparent',
  },
  picker: {
    padding: 8,
    marginTop: 10,
    marginBottom: 10,
    fontSize: 15,
  },
  xl: {
    fontSize: 60,
  },
  heading1: h1,
  h1,
  heading2: h2,
  h2,
  heading3: { ...h3, marginBottom: 7 },
  h3,
  heading4: { ...h4, marginVertical: 7 },
  h4,
  heading5: h5,
  h5,
  heading6: h6,
  h6,
  heading7: h7,
  h7,
  heading8: h8,
  h8,
  heading9: h9,
  list_item: {
    // markdown specific name
    marginVertical: 2,
    fontSize: 16,
    fontFamily: 'poppins-regular',
  },
  paragraph: {
    marginVertical: 2,
    fontSize: 16,
    fontFamily: 'poppins-regular',
  },
  span: {
    marginVertical: 2,
    fontSize: 16,
    fontFamily: 'poppins-regular',
  },
  p: {
    fontSize: 15,
    lineHeight: 17,
  },
  button: {
    // cursor: 'pointer',
    color: '#fff',
  },
  buttonFancy: {
    // backgroundColor: Colors.orange,
    borderWidth: 2,
    borderColor: '#000',
    paddingVertical: 16,
    paddingHorizontal: 25,
    // marginHorizontal: 10,
  },
  buttonSimple: {
    display: 'flex',
  },
  buttonFancyColor: {
    backgroundColor: Colors.orange,
  },
  buttonDisabled: {
    borderWidth: 2,
    borderColor: 'transparent',
    paddingVertical: 5,
    paddingHorizontal: 20,
    // marginHorizontal: 10,
    backgroundColor: '#ddd',
  },
  buttonLight: {
    borderWidth: 2,
    borderColor: '#fff',
    backgroundColor: 'transparent',
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 15,
  },
});
