import { Ionicons } from '@expo/vector-icons';
import React from 'react';
import { Pressable, View } from './shared/Themed';

interface CollapsibleProps {
  button: JSX.Element;
  children: JSX.Element;
  isOpen?: boolean;
}

const Collapsible = ({ button, children, isOpen = false }: CollapsibleProps) => {
  const [isVisible, setIsVisible] = React.useState(isOpen);

  const toggleVisibility = () => {
    setIsVisible(prev => !prev);
  };

  return (
    <>
      <Pressable onPress={toggleVisibility}>
        <View style={{ paddingRight: 25, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottomColor: '#eee', borderBottomWidth: isVisible ? 1 : 0 }}>
          <View style={{}}>{button}</View>
          <Ionicons
            name={isVisible ? 'chevron-up' : 'chevron-down'}
            size={24}
            color="black"
          />
        </View>
      </Pressable>
      {isVisible && (
        <View
          style={{
            padding: 5,
          }}
        >
          {children}
        </View>
      )}
    </>
  );
};

export default Collapsible;
