import { SubmissionPreScreen, UpdateSubmissionPreScreenInput } from '@src/API';
import { updateSubmissionPreScreen } from '@src/graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import React, { Dispatch, PropsWithChildren, SetStateAction, useMemo, useRef, useState } from 'react';

export type AlignmentContextProps = {
  alignmentState: AlignmentState;
  setAlignmentState: Dispatch<SetStateAction<AlignmentState>>;
  shouldUpdate: false;
  setShouldUpdate: Dispatch<SetStateAction<boolean>>;
  updateAlignmentDeliverablesLLM: (id: string, input: UpdateSubmissionPreScreenInput, index: number) => Promise<void>;
};

export interface AlignmentState extends Omit<SubmissionPreScreen, '__typename' | 'id' | 'createdAt' | 'updatedAt'> {
  files?: [FileList];
}

export const AlignmentContext = React.createContext<AlignmentContextProps>({
  alignmentState: {},
  setAlignmentState: _ => {},
  shouldUpdate: false,
  setShouldUpdate: _ => {},
  updateAlignmentDeliverablesLLM: async (_, i) => {},
});

export const { Consumer } = AlignmentContext;
export default Consumer;

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const client = generateClient({
    authMode: 'apiKey',
  });
  const [alignmentState, setAlignmentState] = useState<AlignmentState>({});
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const firstTime = useRef(true);
  const tempDeliverablesLLM = useRef([]);

  const updateAlignmentDeliverablesLLM = async (id: string, input: UpdateSubmissionPreScreenInput, index: number) => {
    // compare alignmentState.deliverablesLLM length to the size
    if (alignmentState.deliverablesLLM) {
      // Update the specific index in the temporary array
      tempDeliverablesLLM.current[index] = { ...alignmentState.deliverablesLLM[index], ...input };

      // Retain previous information while updating the state
      setAlignmentState(prevState => ({
        ...prevState,
        deliverablesLLM: alignmentState.deliverablesLLM.map((item, idx) => (tempDeliverablesLLM.current[idx] ? tempDeliverablesLLM.current[idx] : item)),
      }));

      const len = tempDeliverablesLLM.current.filter(val => !!val).length;
      if (len === alignmentState.deliverablesLLM.length && firstTime.current) {
        firstTime.current = false;
        const response = await client.graphql({
          query: updateSubmissionPreScreen,
          variables: {
            input: {
              id,
              deliverablesLLM: tempDeliverablesLLM.current.map(({ __typename, ...item }) => item), // omit __typename (this needs to happen in several places, im not sure why this needs to happen for only the input)
            },
          },
        });
      }
    }
  };

  const contextValue: AlignmentContextProps = useMemo(
    () => ({
      alignmentState,
      setAlignmentState,
      shouldUpdate,
      setShouldUpdate,
      updateAlignmentDeliverablesLLM,
    }),
    [alignmentState, setAlignmentState, shouldUpdate, setShouldUpdate, updateAlignmentDeliverablesLLM],
  );

  return <AlignmentContext.Provider value={contextValue}>{children}</AlignmentContext.Provider>;
}
