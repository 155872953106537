export const PAGE_VIEW = 'PAGE_VIEW';
import { JsonMap, useAnalytics } from '@segment/analytics-react-native';

const useCustomAnalytics = () => {
  const { track, screen } = useAnalytics();

  const tracker = (eventName: string, params?: JsonMap) => {
    if (process.env.NODE_ENV !== 'development') {
      track(eventName, params);
    }
  };

  const screener = (eventname: string, params?: JsonMap) => {
    if (process.env.NODE_ENV !== 'development') {
      screen(eventname, params);
    }
  };

  return {
    track: tracker,
    screen: screener,
  };
};

export default useCustomAnalytics;
