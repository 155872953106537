import { Ionicons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import { ChangeLogInputInput, SubmissionPreScreen } from '@src/API';
import Button from '@src/components/Button';
import Collapsible from '@src/components/Collapsible';
import CountdownTimer from '@src/components/Countdown';
import Footer from '@src/components/Footer';
import InfoToolTip from '@src/components/InfoToolTip';
import Input from '@src/components/Input';
import Screen from '@src/components/Screen';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular, TextInterRegular, TextInterSemiBold } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import UploadDocumentButton from '@src/components/UploadDocumentButton';
import { updateSubmissionPreScreen } from '@src/graphql/mutations';
import { getSubmissionPreScreen } from '@src/graphql/queries';
import useResponsive from '@src/hooks/useResponsive';
import { AlignmentContext } from '@src/providers/Alignment';
import { LoadingContext } from '@src/providers/Loading';
import { ModalContext } from '@src/providers/Modal';
import { getTranslation } from '@src/services/i18n';
import { uploadFilesUtil } from '@src/utils/FileUpload';
import FormatDate from '@src/utils/FormatDate';
import { ingestText } from '@src/utils/Pinecone';
import { generateClient } from 'aws-amplify/api';
import { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import AlignmentItem from './AlignmentItem';
import EmailCapture from './EmailCapture';

const SPSSubmit = () => {
  const route = useRoute();
  const { isWideScreen } = useResponsive();
  const client = generateClient({
    authMode: 'apiKey',
  });
  const { addLoading, removeLoading, loading, checkLoading } = useContext(LoadingContext);
  const { alignmentState, setAlignmentState, setShouldUpdate } = useContext(AlignmentContext);
  const { setModalContent } = useContext(ModalContext);
  const [data, setData] = useState<SubmissionPreScreen | null>();
  const [updateKey, setUpdateKey] = useState(0);
  const [conversation, setConversation] = useState<string>('');
  const id = route?.params?.id;
  const tasks = alignmentState?.deliverablesLLM;
  const reporters = alignmentState?.changeLog ? Array.from(new Set(alignmentState.changeLog.map(changeLog => changeLog?.reporter))).filter(Boolean) : [];
  const fileNames = alignmentState?.changeLog ? Array.from(new Set(alignmentState.changeLog.map(changeLog => changeLog?.files))).filter(Boolean) : [];

  const load = async () => {
    try {
      const { data } = await client.graphql({
        query: getSubmissionPreScreen,
        variables: {
          id,
        },
      });
      if (data.getSubmissionPreScreen) {
        setAlignmentState(data.getSubmissionPreScreen);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleFileUpload = async (emailAddress: string, files?: FileList | null) => {
    console.log('handleFileUpload', files, emailAddress, alignmentState, alignmentState && alignmentState.indexId && alignmentState.namespace);
    if (files && alignmentState && alignmentState.indexId && alignmentState.namespace) {
      try {
        addLoading('updateData');
        // toggle if a file has been uploaded
        setShouldUpdate(true);

        const indexId = alignmentState.indexId;
        const namespace = alignmentState.namespace;
        let rawDocs = await uploadFilesUtil({ files, vectorStore: { indexId, namespace } });

        let timeoutDuration = 10000;
        timeoutDuration += 3000 * rawDocs.length;

        console.log('handleFileUpload rawDocs', rawDocs);

        setTimeout(async () => {
          // hack because sometimes the namespace in pinecone is not ready for search
          // generate the list of requirements
          let filesAdded = '';
          if (files) {
            Array.from(files).forEach(file => {
              filesAdded += `${file.name.replace(' ', '-')} `;
            });
          }

          await saveSubmission(filesAdded, emailAddress);
        }, timeoutDuration);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
      }
    }
  };

  const updateChangeLog = async (fileNames: string, emailAddress: string) => {
    try {
      if (alignmentState) {
        // save the results
        const newChangeLog = { files: fileNames, reporter: emailAddress, createDate: `${Date.now()}`, messages: conversation } as ChangeLogInputInput;
        let changeLog;
        if (alignmentState.changeLog) {
          changeLog = [newChangeLog, ...alignmentState.changeLog];
        } else {
          changeLog = [newChangeLog];
        }
        changeLog = changeLog.map(({ __typename, ...item }) => item);
        setAlignmentState({
          ...alignmentState,
          changeLog,
        });
        const response = await client.graphql({
          query: updateSubmissionPreScreen,
          variables: {
            input: { id, changeLog },
          },
        });
        setAlignmentState(response.data.updateSubmissionPreScreen);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      removeLoading('updateData');
    }
  };

  const saveSubmission = async (fileNames: string, emailAddress: string) => {
    // this forces the alignment items to reload
    setUpdateKey(prev => {
      return prev + 1;
    });
    updateChangeLog(fileNames, emailAddress);
  };

  const update = async (emailAddress: string) => {
    // send the conversation to the vector store
    if (alignmentState && alignmentState.indexId && alignmentState.namespace) {
      addLoading('updateData');
      setConversation('');
      // send the conversation to the vector store
      await ingestText(`${conversation} added by ${emailAddress}`, alignmentState.indexId, alignmentState.namespace);
      // wait 5 seconds for the vector store to catch up... this is arbitrary and needs to change
      setTimeout(() => saveSubmission(conversation, emailAddress), 10000);
    }
  };

  const onAddInput = () => {
    setModalContent(<EmailCapture onComplete={update} />);
  };

  const onAddUpload = (files?: FileList | null) => {
    setModalContent(
      <EmailCapture
        onComplete={(emailAddress: string) => {
          handleFileUpload(emailAddress, files);
        }}
      />,
    );
  };

  useEffect(() => {
    load();
    return () => {
      setAlignmentState({});
    };
  }, []);

  const printPanel = () => {
    return (
      <View
        style={{
          width: isWideScreen ? '30%' : '100%',
          height: isWideScreen ? '100%' : 'auto',
          position: isWideScreen ? 'absolute' : 'relative',
          right: isWideScreen ? 30 : 0,
          // top: isWideScreen ? 25 : 0,
          zIndex: 1,
        }}
      >
        <ScrollView>
          <View
            style={{
              marginBottom: 100,
            }}
          >
            <View
              style={{
                backgroundColor: Colors.light.container,
                borderRadius: 5,
                marginTop: 45,
                marginHorizontal: '3%',
              }}
            >
              <View
                style={{
                  paddingVertical: 20,
                  paddingHorizontal: 25,
                }}
              >
                <TextBodyMedium style={[Styles.h2, { marginBottom: 10, color: Colors.primary }]}>Upload documents or input text</TextBodyMedium>
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <TextBodyRegular style={[Styles.h5, { marginRight: 10 }]}>Do you have the answers?</TextBodyRegular>
                  <InfoToolTip toolTip={`Requests will automatically resolve if the input satisfies them.`} />
                </View>
              </View>
              <View
                style={{
                  borderTopColor: '#fff',
                  borderTopWidth: 1,
                  padding: 30,
                  paddingTop: 15,
                }}
              >
                {loading ? (
                  <View style={{ marginVertical: 30 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                      <ActivityIndicator
                        size={20}
                        color={Colors.darkBlue}
                      />
                      <TextBodyMedium style={[Styles.h4, { marginLeft: 10, color: Colors.primary }]}>Loading</TextBodyMedium>
                    </View>
                    <TextBodyRegular style={[Styles.h7, { marginTop: 20, width: '100%' }]}>Please wait ~60 seconds depending on the number of files uploaded.</TextBodyRegular>
                    <CountdownTimer
                      size={14}
                      initialTime={60}
                    />
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <View
                      style={{
                        // width: isWideScreen ? '45%' : '100%',
                        borderBottomWidth: 1,
                        paddingBottom: 30,
                        borderBottomColor: '#eee',
                        marginBottom: 40,
                      }}
                    >
                      <UploadDocumentButton onPress={onAddUpload} />
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: 20,
                        }}
                      >
                        <Ionicons
                          name="lock-closed"
                          size={18}
                        />
                        <TextBodyRegular style={[Styles.h7, { color: Colors.darkBlue, marginLeft: 10 }]}>{`The information you upload is stored securely.\nTo learn more, please see our Privacy Policy.`}</TextBodyRegular>
                      </View>
                    </View>
                    <View style={{}}>
                      <Input
                        height={100}
                        style={[Styles.input, {}]}
                        placeholder="Enter information for any request here"
                        placeholderTextColor="#aaa"
                        onChangeText={text => {
                          setConversation(text);
                        }}
                        value={conversation}
                        multiline={true}
                        numberOfLines={5}
                      />
                      <View
                        style={{
                          marginTop: 20,
                          alignSelf: 'center',
                        }}
                      >
                        <Button
                          theme="primary"
                          iconAfter="add"
                          onPress={onAddInput}
                        >
                          {getTranslation('buttons.submit')}
                        </Button>
                      </View>
                    </View>
                  </View>
                )}
              </View>
            </View>
            {alignmentState?.changeLog && (
              <View
                style={{
                  backgroundColor: Colors.light.container,
                  borderRadius: 5,
                  marginTop: 20,
                  marginHorizontal: '3%',
                }}
              >
                <Collapsible
                  button={
                    <View style={{ paddingVertical: 15, paddingHorizontal: 25, flexDirection: 'row', alignItems: 'center' }}>
                      <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Change log</TextBodyMedium>
                      <InfoToolTip toolTip={`When and who submitted what information.`} />
                    </View>
                  }
                >
                  <>
                    {alignmentState &&
                      alignmentState.changeLog &&
                      alignmentState.changeLog.map((changeLog, index) => {
                        return (
                          <View
                            key={`changelog-${index}`}
                            style={{
                              marginHorizontal: 30,
                              marginVertical: 15,
                              paddingBottom: 15,
                              borderBottomColor: '#eee',
                              borderBottomWidth: index < alignmentState.changeLog.length - 1 ? 1 : 0,
                            }}
                          >
                            {changeLog && (
                              <>
                                {changeLog.messages && changeLog.messages.length > 0 && <TextInterRegular style={[Styles.h5, { marginBottom: 5, color: Colors.darkBlue }]}>{changeLog?.messages}</TextInterRegular>}
                                {changeLog.files && changeLog.files.length > 0 && (
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      marginBottom: 5,
                                    }}
                                  >
                                    <Ionicons
                                      name="document"
                                      size={24}
                                    />
                                    <TextInterRegular style={[Styles.h5, { marginLeft: 5, color: Colors.darkBlue }]}>{changeLog?.files}</TextInterRegular>
                                  </View>
                                )}
                                {changeLog.reporter && changeLog.reporter.length > 0 && <TextInterRegular style={[Styles.h6, { marginBottom: 5, color: Colors.darkBlue }]}>{changeLog?.reporter}</TextInterRegular>}
                                {changeLog.createDate && changeLog.createDate.length > 0 && <TextInterRegular style={[Styles.h6, { marginBottom: 5, color: Colors.darkBlue }]}>{FormatDate(Number(changeLog?.createDate))}</TextInterRegular>}
                              </>
                            )}
                          </View>
                        );
                      })}
                  </>
                </Collapsible>
              </View>
            )}
            <View
              style={{
                backgroundColor: Colors.light.container,
                borderRadius: 5,
                marginTop: '3%',
                marginHorizontal: '3%',
              }}
            >
              <Collapsible
                button={
                  <View style={{ paddingVertical: 15, paddingHorizontal: 25, flexDirection: 'row', alignItems: 'center' }}>
                    <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Documents uploaded</TextBodyMedium>
                  </View>
                }
              >
                <>
                  {fileNames && alignmentState && (
                    <View
                      style={{
                        padding: 20,
                      }}
                    >
                      {fileNames.length === 0 && <TextBodyRegular style={[Styles.h6, { color: Colors.darkBlue }]}>There have not been any files uploaded.</TextBodyRegular>}
                      {fileNames.length > 0 &&
                        fileNames.map((fileName, index) => (
                          <View
                            key={`fileName-${alignmentState.indexId}-${alignmentState.namespace}-${index}`}
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Ionicons
                              name="document"
                              size={24}
                            />
                            <TextBodyRegular style={[Styles.h8, { marginLeft: 10, color: Colors.darkBlue }]}>{fileName?.replace(',', '')}</TextBodyRegular>
                          </View>
                        ))}
                    </View>
                  )}
                </>
              </Collapsible>
            </View>
            <View
              style={{
                backgroundColor: Colors.light.container,
                borderRadius: 5,
                marginTop: '3%',
                marginHorizontal: '3%',
              }}
            >
              <Collapsible
                button={
                  <View style={{ paddingVertical: 15, paddingHorizontal: 25, flexDirection: 'row', alignItems: 'center' }}>
                    <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Contributors</TextBodyMedium>

                    <InfoToolTip toolTip={`Below is a list of individuals' emails who submitted information.`} />
                  </View>
                }
              >
                <>
                  {reporters && alignmentState && (
                    <View
                      style={{
                        padding: 20,
                      }}
                    >
                      {reporters.length === 0 && <TextBodyRegular style={[Styles.h6, { color: Colors.darkBlue }]}>There are no contributors. Share this link to receive information.</TextBodyRegular>}
                      {reporters.length > 0 &&
                        reporters.map((assignee, index) => (
                          <View
                            key={`assignee-${alignmentState.indexId}-${alignmentState.namespace}-${index}`}
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <Ionicons
                              name="person"
                              size={24}
                            />
                            <TextBodyRegular style={[Styles.h6, { marginLeft: 10, color: Colors.darkBlue }]}>{assignee.replace(',', '')}</TextBodyRegular>
                          </View>
                        ))}
                    </View>
                  )}
                </>
              </Collapsible>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  };

  const printTasks = () => {
    return (
      <View
        key={updateKey}
        style={{
          backgroundColor: Colors.light.container,
          borderRadius: 5,
          marginTop: '3%',
          marginHorizontal: '3%',
          paddingVertical: 22,
          paddingHorizontal: 30,
        }}
      >
        <View
          style={{
            borderBottomColor: '#eee',
            borderBottomWidth: 1,
            paddingBottom: 10,
            marginBottom: 30,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Requests</TextBodyMedium>
            <View style={{ flex: 1, width: '50%' }}>
              <InfoToolTip toolTip={`Each item in the list below automatically updates as new information is added to the alignment. Click any to learn more about their status.`} />
            </View>
          </View>
        </View>
        {tasks &&
          tasks.map((request, index) => {
            return (
              <View
                key={`review-request-${index}`}
                style={{
                  marginBottom: 20,
                }}
              >
                <AlignmentItem
                  index={index}
                  task={request}
                  vectorStore={{ indexId: alignmentState.indexId, namespace: alignmentState.namespace }}
                  role={alignmentState.role}
                  tasks={tasks}
                />
              </View>
            );
          })}
      </View>
    );
  };

  return (
    <>
      {isWideScreen && !checkLoading('loadData') && alignmentState && printPanel()}
      <Screen>
        <View style={{}}>
          {!checkLoading('loadData') && alignmentState ? (
            <View style={{ position: 'relative' }}>
              <View
                style={{
                  width: isWideScreen ? '70%' : '100%',
                }}
              >
                <View
                  style={{
                    backgroundColor: Colors.light.container,
                    borderRadius: 5,
                    marginTop: 45,
                    marginHorizontal: '3%',

                    paddingVertical: 22,
                    paddingHorizontal: 30,
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: '#eee',
                      paddingBottom: 10,
                      marginBottom: 10,
                      borderBottomWidth: 1,
                    }}
                  >
                    <TextBodyMedium style={[Styles.h6, { color: Colors.primary }]}>Subject</TextBodyMedium>
                  </View>
                  <TextInterSemiBold style={[Styles.h3, { marginTop: 5, color: Colors.darkBlue }]}>{alignmentState.titleLLM}</TextInterSemiBold>
                </View>

                <View
                  style={{
                    backgroundColor: Colors.light.container,
                    borderRadius: 5,
                    marginTop: '3%',
                    marginHorizontal: '3%',

                    paddingVertical: 22,
                    paddingHorizontal: 30,
                  }}
                >
                  <View
                    style={{
                      borderBottomColor: '#eee',
                      paddingBottom: 10,
                      marginBottom: 10,
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <TextBodyMedium style={[Styles.h6, { color: Colors.primary, marginRight: 10 }]}>Overview</TextBodyMedium>
                      <View style={{ flex: 1, width: '50%' }}>
                        <InfoToolTip toolTip={`A quick overview of the current status of the alignment, including outstanding issues and recently incorporated details.`} />
                      </View>
                    </View>
                  </View>
                  <TextInterRegular style={[Styles.h5, { marginBottom: 5, color: Colors.darkBlue }]}>{alignmentState.requirementsLLM}</TextInterRegular>
                </View>

                {tasks && printTasks()}
                {!isWideScreen && printPanel()}
              </View>
            </View>
          ) : (
            <View
              style={{
                backgroundColor: Colors.light.container,
                borderRadius: 5,
                marginTop: '3%',
                marginHorizontal: '3%',

                paddingVertical: 22,
                paddingHorizontal: 30,
                width: isWideScreen ? '60%' : '100%',
              }}
            >
              <TextBodyRegular style={[Styles.h5, { marginBottom: 0 }]}>Please wait. We are loading the requirements for your submission.</TextBodyRegular>
              <ActivityIndicator
                size={36}
                color={Colors.darkBlue}
              />
            </View>
          )}
          <Footer />
        </View>
      </Screen>
    </>
  );
};

export default SPSSubmit;
