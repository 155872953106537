import { ModalContext } from '@src/providers/Modal';
import React, { useContext } from 'react';
import { View } from 'react-native';
import { Pressable } from '../shared/Themed';

const WrapperModal = () => {
  const { setModalContent } = useContext(ModalContext);
  const { modalContent, dimensions } = useContext(ModalContext);
  function goBack() {
    setModalContent(undefined);
  }
  return (
    <View
      style={{
        backgroundColor: 'rgba(0,0,0,.2)',
        width: '100%',
        height: '100%',
        top: 0,
        position: 'absolute',
        zIndex: 100,
      }}
    >
      <Pressable
        style={{
          width: '100%',
          height: '100%',
        }}
        onPress={goBack}
      ></Pressable>
      {modalContent}
    </View>
  );
};

export default WrapperModal;
