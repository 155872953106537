import Responsive from '@src/utils/Responsive';
import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

const useResponsive = () => {
  const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);
  const isWideScreen = screenWidth > Responsive.small;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', handleResize);

    return () => {
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  return { screenWidth, isWideScreen };
};

export default useResponsive;
