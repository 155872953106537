import Button from '@src/components/Button';
import Input from '@src/components/Input';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { ModalContext } from '@src/providers/Modal';
import { getTranslation } from '@src/services/i18n';
import React, { useContext, useState } from 'react';
import { NativeSyntheticEvent, ScrollView, TextInputKeyPressEventData } from 'react-native';

interface EmailCaptureProps {
  onComplete: (emailAddress: string) => void;
}
const EmailCapture = ({ onComplete }: EmailCaptureProps) => {
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [error, setError] = useState<string>();
  const { setModalContent } = useContext(ModalContext);

  const onKeyPressEmailAddress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // Handle the key press event here
    if (event.nativeEvent.key === 'Enter') {
      onEmailSaved();
    }
  };

  const onEmailSaved = () => {
    console.log('onEmailSaved', isValidEmail);
    if (!isValidEmail) {
      setError('Please enter a valid email address');
    } else {
      onComplete(emailAddress);
      closeModal();
    }
  };

  const closeModal = () => {
    setModalContent(undefined);
  };

  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);

  return (
    <ScrollView
      style={{
        position: 'absolute',
        backgroundColor: '#fff',
        padding: 20,
        // paddingBottom: 50,
        marginTop: 100,
        alignSelf: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: Colors.light.container,
          padding: 30,
        }}
      >
        <TextBodyMedium style={[Styles.h3, { color: Colors.darkBlue, marginBottom: 10 }]}>Enter your email address</TextBodyMedium>
        <TextBodyRegular style={[Styles.h6, { color: Colors.darkBlue, marginBottom: 30 }]}>This is required.</TextBodyRegular>
        <Input
          style={Styles.input}
          placeholder=""
          placeholderTextColor={'#999'}
          onChangeText={text => {
            setEmailAddress(text);
          }}
          autoFocus={true}
          value={emailAddress}
          onKeyPress={onKeyPressEmailAddress}
        />
        {error && <TextBodyRegular style={[Styles.h6, { color: 'red' }]}>{error}</TextBodyRegular>}
        <Button
          theme="primary"
          onPress={onEmailSaved}
          style={{ marginTop: 20 }}
          disabled={!isValidEmail}
        >
          {getTranslation('buttons.submit')}
        </Button>
      </View>
    </ScrollView>
  );
};

export default EmailCapture;
