import { Authenticator } from '@aws-amplify/ui-react-native';
import Create from '@src/components/Auth/Create';
import Login from '@src/components/Auth/Login';
import React from 'react';
import TopBarWeAlign from '../SPS/TopBarWeAlign';

const AuthLogin = () => {
  return (
    <>
      <TopBarWeAlign />
      <Authenticator
        components={{
          SignIn: props => {
            return <Login {...props} />;
          },
          SignUp: props => {
            return <Create {...props} />;
          },
        }}
      ></Authenticator>
    </>
  );
};

export default AuthLogin;
