// https://us-east-1.console.aws.amazon.com/iam/home?region=us-east-1#/roles/details/baltare-textract-role-49ujzxrl

import Constants from 'expo-constants';
import { ExtractionBlock } from './TextractProcessor';

interface IStartExtractRequest {
  imageData: any;
  extractType: string;
}

interface IGetExtractRequest {
  jobId: string;
  nextToken: string;
}

const startTextractUrl = 'https://nllhdijafnsx6hh53foizbcmgq0lnpmj.lambda-url.us-east-1.on.aws';
const getTextractUrl = 'https://djai324luhtowbfsol6ppw3loq0jazld.lambda-url.us-east-1.on.aws/';

/**
 * Extract text from a doument file
 */
export async function startExtractRequest(obj: IStartExtractRequest): Promise<ExtractionBlock[] | undefined> {
  const url = startTextractUrl; //process.env.NODE_ENV !== 'development' ? startTextractUrl : `http://localhost:3000/startExtract`;

  const triggerResponse = await triggerRequest(url, obj);
  if (triggerResponse?.jobId) {
    return await pollTextractJob(triggerResponse.jobId);
  }
}

/**
 * Polls the GET lambda function to get the results of a Textract job
 */
async function pollTextractJob(jobId: string) {
  let jobStatus = 'IN_PROGRESS';
  let result = [];
  let nextToken;

  // Polling loop to check job status and fetch results
  while (jobStatus === 'IN_PROGRESS' || nextToken) {
    try {
      const params = {
        JobId: jobId,
        NextToken: nextToken,
      };
      const url = getTextractUrl; //process.env.NODE_ENV !== 'development' ? getTextractUrl : `http://localhost:3000/getExtract`;
      const response = await triggerRequest(url, params);
      if (response) {
        jobStatus = response.JobStatus;
        if (jobStatus === 'SUCCEEDED') {
          // Extract and accumulate text from blocks
          // const blocks = response.Blocks?.filter(b => b.BlockType === 'WORD');
          if (response.Blocks) result = result.concat(response.Blocks);
          // Check if there is more data to fetch
          nextToken = response.NextToken;
          console.log(`Job successful. nextToken: ${nextToken}`);
        } else if (jobStatus === 'FAILED') {
          throw new Error(`Textract job failed: ${response.StatusMessage}`);
        } else if (jobStatus === 'IN_PROGRESS') {
          console.log(`Job status ${jobStatus}. Waiting to retry...`);
          await new Promise(resolve => setTimeout(resolve, 3000)); // Wait before retrying
        }
      } else {
        console.log('ERROR, no response');
        break;
      }
    } catch (error) {
      console.error('Error polling Textract job:', error);
      throw error;
    }
  }
  console.log('result', result);
  return result; // Return accumulated result after the job is done
}

/**
 * API fetch helper
 */
async function triggerRequest(url: string, obj: IStartExtractRequest | IGetExtractRequest): Promise<any> {
  const apiKey = Constants?.expoConfig?.extra?.aws.API_KEY;

  if (!apiKey) {
    console.error('API key is not configured.');
    return;
  }

  try {
    // const controller = new AbortController();
    // const timeoutId = setTimeout(() => controller.abort(), 1050000); // 1050 seconds timeout
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': authHeader, // <-- TODO we need this for AWS Cognito
        // 'x-api-key': apiKey,
        // Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token
      },
      body: JSON.stringify(obj),
      // signal: controller.signal,
      credentials: 'same-origin', // or 'same-origin' if your request is to the same domain
    });

    if (!res.ok) {
      console.error(`Failed to fetch: ${res.status} ${res.statusText}`);
      const errorText = await res.text(); // Get the response text for more details
      console.error('Response body:', errorText); // Log the response body
      return;
    }
    const data = await res.json();
    return data;
  } catch (error) {
    if (error.name === 'AbortError') {
      console.error('Request timed out');
    } else {
      console.error('Failed to trigger fetch:', error);
    }
  }
}
