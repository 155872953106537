import MagicTableItemWrapper, { ColumnCriteriaPrompt } from '@src/components/ADT/MagicTable';
import { createMagicTableCellRequest } from '@src/components/ADT/MagicTable/MagicTableCell';
import Screen from '@src/components/Screen';
import Colors from '@src/components/shared/Colors';
import { TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import useResponsive from '@src/hooks/useResponsive';
import React from 'react';

const defaultCriteriaColumns = [
  { type: 'task', label: 'File name', query: 'METADATA.fileName', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Date added', query: 'METADATA.createdAt', prompt: createMagicTableCellRequest },
  { type: 'task', label: 'Organization', query: 'What is the name of the company or organization that this document is about?', prompt: createMagicTableCellRequest },
  // { type: 'task-list', label: 'Total tasks', prompt: createTaskListCountRequest },
  // { type: 'task', label: 'Reference document(s)', query: 'How many ?', prompt: createMagicTableCellRequest },
  // { type: 'task', label: 'Date', query: 'What are some associated dates?', prompt: createMagicTableCellRequest },
  { type: 'task', label: '# Clients served', query: `How many clients or corporations were involved? Return the number only.`, prompt: createMagicTableCellRequest },
  // { type: 'task', label: 'Office location', query: 'Office location', prompt: createMagicTableCellRequest },
  // { type: 'task', label: '% Volunteers increase', query: 'How did the number of volunteers increase, either by percentage or the total number of volunteers?', prompt: createMagicTableCellRequest },
  // { type: 'task', label: '% Growth from prior year', query: 'What was the percentage grown? Only return the percentage number.', prompt: createMagicTableCellRequest },
  // { type: 'task', label: '# of travel emissions reduced in tCO2e', query: '# of travel emissions reduced in tCO2e', prompt: createMagicTableCellRequest },
] as ColumnCriteriaPrompt[];

const vectorStores = [
  {
    indexId: 'demo-analysis',
    namespace: '907626e9-e422-4bfc-b377-17562289f724',
  },
];

const AllDataTableDemo = () => {
  const { isWideScreen } = useResponsive();

  return (
    <Screen>
      <View
        style={{
          margin: 20,
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            padding: 20,
            width: '100%',
            backgroundColor: '#fff',
            justifyContent: 'center',
          }}
        >
          <TextBodyRegular style={[isWideScreen ? Styles.h2 : Styles.h3, { marginBottom: 20, color: Colors.buttonBackground }]}>All Data Table</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10 }]}>{`How it works`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, marginLeft: isWideScreen ? 20 : 5 }]}>{`• Each column header is a question of each row's corresponding document(s).`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, marginLeft: isWideScreen ? 20 : 5 }]}>{`• Add document: Click ‘Add row + ‘ and upload a document or documents you want analyzed.`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, marginLeft: isWideScreen ? 20 : 5 }]}>{`• Add Column: Click ‘Add column +’ and input your question.`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, marginLeft: isWideScreen ? 20 : 5 }]}>{`• Insight: Click on a cell in the table to get more detail on that column's question.`}</TextBodyRegular>
          <TextBodyRegular style={[Styles.h5, { marginBottom: 10, marginLeft: isWideScreen ? 20 : 5 }]}>{`• Summary: Click ‘Go to summary‘ to see an overview of the submitted documents.`}</TextBodyRegular>
        </View>
        <MagicTableItemWrapper
          defaultCriteriaColumn={defaultCriteriaColumns}
          defaultVectorStores={vectorStores}
        />
      </View>
    </Screen>
  );
};

export default AllDataTableDemo;
