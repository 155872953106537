import Colors from '@src/components/shared/Colors';
import { TextBodyMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import { ModalContext } from '@src/providers/Modal';
import { chat } from '@src/utils/Chat';
import FormatDate from '@src/utils/FormatDate';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { ColumnCriteriaPrompt } from '.';
import ADTSummaryModal from '../demo/ADTSummaryModal';

interface MagicTableItemProps {
  criteria: ColumnCriteriaPrompt;
  namespace: string;
  indexId: string;
  columnCount: number;
}

const MagicTableItem = ({ criteria, namespace, indexId, columnCount }: MagicTableItemProps) => {
  const { setModalContent } = useContext(ModalContext);
  const [cellContent, setCellContent] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [source, setSource] = useState<any[]>([]);

  const onHandlePress = () => {
    setModalContent(
      <ADTSummaryModal
        query={`${criteria.label}: ${cellContent}`}
        indexId={indexId}
        namespace={namespace}
      />,
    );
  };

  const triggerRequest = async () => {
    setLoading(true);

    try {
      if (criteria.prompt) {
        const req = criteria.prompt({ indexId, namespace, criteria }); // Pass indexId and namespace here

        const { cellContent, sourceMaterials } = await chat(req);
        if (cellContent) {
          setCellContent(cellContent);
        }
        if (sourceMaterials && criteria.query.includes('METADATA')) {
          if (criteria.query.includes('createdAt')) {
            setCellContent(FormatDate(sourceMaterials[0].metadata[criteria.query.split('.')[1]]));
          } else if (criteria.query.includes('fileName')) {
            const fileNameSplit = sourceMaterials[0].metadata[criteria.query.split('.')[1]].split('-');
            setCellContent(`${fileNameSplit.slice(0, -1).join('-')}`);
          }
        }
        if (sourceMaterials) {
          setSource(sourceMaterials);
        }
      } else {
        throw Error('No criteria prompt()');
      }
    } catch (err) {
      console.log('magic table item llm error', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    triggerRequest();
  }, []);

  return (
    <View
      style={{
        width: `${100 / columnCount}%`,
        borderRightWidth: 1,
        borderRightColor: '#fff',
        borderBottomWidth: 1,
        borderBottomColor: '#fff',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      <Pressable
        onPress={onHandlePress}
        style={{ flex: 1, borderTopWidth: 0, borderTopColor: '#fff', borderRightWidth: 0, borderRightColor: '#fff', backgroundColor: cellContent ? (cellContent.toLowerCase() === 'not available' ? '#FF6961' : '#C1E1C1') : '#fff', padding: 15 }}
      >
        {!loading && <TextBodyMedium style={[Styles.h6, {}]}>{cellContent}</TextBodyMedium>}
        {loading && (
          <ActivityIndicator
            style={{
              alignItems: 'center ',
              justifyContent: 'center',
            }}
            size="small"
            color={Colors.buttonBackground}
          />
        )}
      </Pressable>
    </View>
  );
};

export default MagicTableItem;
