export default {
  terms: {
    thanks: 'Merci',
    thankYou: 'Merci beaucoup',
  },
  buttons: {
    next: 'Next',
    back: 'Back',
    done: 'Done',
    submit: 'Submit',
    reload: 'Reload',
  },
  errorBoundary: {
    title: 'FRFR You encountered a bug',
    body: 'FRFR Please contact customer support to let them know what you did before this error appeared.',
    automaticReload: 'FRFR The app will automatically reload in',
    button: 'FRFR Reload app',
  },
};
