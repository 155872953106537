const pink = '#F598FF';
const primary = '#1565C0'; //'#728ff2';
const darkBlue = '#171D3A';
const lightGrayPurple = '#E5E9FD';
const lighterGrayPurple = '#F0F3FF'; // Slightly lighter than lightGrayPurple
const orange = '#ff6600';
const aiBlue = '#0000ff';
const activeButton = '#0000ff';
// 244270 f7a453 ffb339
// const buttonBackground = '#244270';
const bluelighter = '#7d7fbf';
const buttonBackground = primary; // '#966fd6'; //'#373997';
const buttonBackgroundActive = '#0986eb';
const buttonSecondaryBackground = '#42A5F5'; // '#966fd6'; //'#373997';
const buttonTertiaryBackground = '#42A5F5';
const green = '#90EE90';
const red = '#FF6961';
const gray = '#f1f1f1';
const orangePastel = '#FAC898';
const lightNeonGreen = '#39ff14';

export default {
  orange,
  lightNeonGreen,
  darkBlue,
  aiBlue,
  activeButton,
  primary,
  pink,
  toolTip: '#fff',
  // 244270 f7a453 ffb339
  // buttonBackground,
  bluelighter,
  buttonBackground,
  buttonBackgroundActive,
  buttonSecondaryBackground,
  buttonTertiaryBackground,
  lightGrayPurple,
  lighterGrayPurple,
  green,
  red,
  gray,
  orangePastel,
  light: {
    background: '#f0f0f0',
    container: '#fff',
    secondary: primary,
    text: darkBlue,
    buttonBg: '#000',
    buttonBorder: '#000',
    buttonBgInverted: '#FFF',
    buttonColor: '#fff',
    buttonInvertedColor: '#000',
    shadow: '#fff',
  },
  dark: {
    background: '#fff',
    container: lightGrayPurple,
    secondary: primary,
    text: darkBlue,
    buttonBg: '#fff',
    buttonBorder: '#fff',
    buttonBgInverted: '#000',
    buttonColor: '#000',
    buttonInvertedColor: '#fff',
    shadow: '#000',
  },
};
