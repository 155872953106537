import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator } from 'react-native';
import { AdvisorContext } from './Provider';

const AdvisorList = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const { loadAllAdvisors, allAdvisors } = useContext(AdvisorContext);
  const { loading } = useContext(LoadingContext);

  useEffect(() => {
    loadAllAdvisors();
  }, []);

  return (
    <>
      {!loading ? (
        allAdvisors && allAdvisors.length > 0 ? (
          <View
            style={{
              flexDirection: 'column',
            }}
          >
            <TextHeader style={[Styles.h4]}>{`Total: ${allAdvisors.length}`}</TextHeader>
            {allAdvisors.map((project, index) => (
              <Pressable
                key={project.id}
                onPress={() => {
                  navigation.navigate('advisorDetail', { advisorId: project.id });
                }}
                style={{
                  marginTop: 10,
                  paddingVertical: 10,
                  width: '100%',
                  borderColor: '#000',
                  borderBottomWidth: 1,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <TextBodyRegular style={[Styles.h4]}>{`${index + 1}. ${project.title}`}</TextBodyRegular>
                <TextBodyRegular style={[Styles.h6]}>{new Date(project.createdAt).toLocaleDateString()}</TextBodyRegular>
              </Pressable>
            ))}
          </View>
        ) : (
          <TextHeader style={[Styles.h4]}>{`Total: 0.  Nothing has been created.  Please click above.`}</TextHeader>
        )
      ) : (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator
            size="large"
            color="#000"
          />
          <TextBodyRegular style={[Styles.h6, { marginLeft: 10 }]}>Loading, please wait.</TextBodyRegular>
        </View>
      )}
    </>
  );
};

export default AdvisorList;
