import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import SvgLogo from '@src/components/Logo/SvgLogo';
import SvgLogoIcon from '@src/components/Logo/SvgLogoIcon';
import { TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Button from '../Button';
import Colors from '../shared/Colors';

const TopBarDemo = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const gotoLogin = () => {
    navigation.navigate('loggedIn');
  };
  return (
    <View
      style={{
        zIndex: 1,
        justifyContent: 'center',
        height: 150,
        backgroundColor: Colors.appBackground,
        padding: '3%',
        alignItems: 'center',
        borderBottomWidth: 4,
        borderBottomColor: Colors.buttonBackground,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '95%',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity
            onPress={() => navigation.navigate('sustainability')}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: 15,
            }}
          >
            <SvgLogoIcon
              width={70}
              height={70}
              color={Colors.buttonBackground}
            />
            <View style={{ marginLeft: 30 }}>
              <SvgLogo
                width={120}
                height={45}
                color={Colors.buttonBackground}
              />
            </View>
          </TouchableOpacity>
          <TextHeader style={[Styles.h8, { marginRight: 30, color: Colors.buttonBackground }]}>Demo</TextHeader>
        </View>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            onPress={gotoLogin}
            theme="primary"
          >
            Sign up
          </Button>
        </View>
      </View>
    </View>
  );
};

export default TopBarDemo;
