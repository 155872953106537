import { Fontisto, Ionicons } from '@expo/vector-icons';
import Button from '@src/components/Button';
import CountdownTimer from '@src/components/Countdown';
import InfoToolTip from '@src/components/InfoToolTip';
import Input from '@src/components/Input';
import Colors from '@src/components/shared/Colors';
import { TextBodyExtraBold, TextBodyMedium, TextBodyRegular, TextInterRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import UploadDocument from '@src/components/UploadDocument';
import useResponsive from '@src/hooks/useResponsive';
import WeAlignPrompts from '@src/prompts/WeAlignPrompts';
import { AlignmentContext } from '@src/providers/Alignment';
import { LoadingContext } from '@src/providers/Loading';
import React, { useContext, useState } from 'react';
import { ActivityIndicator, ScrollView } from 'react-native';
import DateTimePicker from 'react-native-ui-datepicker';
import { SPSPage } from '.';

type PersonaButtonProps = {
  title: string;
  onPress?: () => void;
  icon?: string;
};

const PersonaButton = ({ title, onPress, icon }: PersonaButtonProps) => {
  const [onHover, setOnHover] = useState(false);
  const handleHoverIn = () => {
    setOnHover(true);
  };
  const handleHoverOut = () => {
    setOnHover(false);
  };
  return (
    <Pressable
      onPress={onPress}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        backgroundColor: onHover ? Colors.bluelighter : Colors.light.secondary,
        marginRight: 10,
      }}
    >
      {icon && (
        <Ionicons
          name={icon}
          size={24}
          color={Colors.light.text}
          style={{ marginRight: 10 }}
        />
      )}
      <TextBodyRegular style={[Styles.h7, { color: '#fff' }]}>{title}</TextBodyRegular>
    </Pressable>
  );
};

const SPSMake = ({ goto }: SPSPage) => {
  const { isWideScreen } = useResponsive();
  const { addLoading, removeLoading, checkLoading } = useContext(LoadingContext);
  const { alignmentState, setAlignmentState } = useContext(AlignmentContext);
  const [showDateTimePicker, setShowDateTimePicker] = useState<boolean>(false);
  const [showAssignee, setShowAssignee] = useState<boolean>(false);
  const [showOwnerEmail, setShowOwnerEmail] = useState<boolean>(false);
  const [chatGenerateList, setChatGenerateList] = useState<string>('');
  const [preFilledList, setPreFilledList] = useState<string | undefined>();
  const [selection, setSelection] = useState<string | undefined>();
  const [isCollaborative, setIsCollaborative] = useState<boolean>(true);

  const selectCustomPrompt = (name: string) => {
    if (selection === name) {
      setPreFilledList(undefined);
      setSelection(undefined);
    } else {
      setPreFilledList(WeAlignPrompts[name].deliverables);
      setSelection(name);
    }
  };

  const onSubmit = async () => {
    // set the content of the input field to the alignmentState.deliverables
    if (chatGenerateList) {
      setAlignmentState({
        ...alignmentState,
        deliverables: chatGenerateList,
      });
    } else if (preFilledList) {
      setAlignmentState({
        ...alignmentState,
        deliverables: preFilledList,
      });
    }
    goto('review');
  };

  const uploadDocumentForRequirements = async (files: FileList | null) => {
    if (files) {
      addLoading('uploadDocument');
      // create pinecone index for this user.  each user should have only 1 index associated with them, where each index includes namespaces that are associated with projects
      // we need to get the indexId and namespace
      try {
        // save files to temp state
        // show list of files that were attached!
        if (alignmentState && alignmentState.files) {
          setAlignmentState({ ...alignmentState, files: [...alignmentState.files, files] });
        } else {
          setAlignmentState({ files: [files] });
        }
      } catch (error) {
        removeLoading('uploadDocument');
        console.error('Error uploading file:', error);
      }
      removeLoading('uploadDocument');
    }
  };

  const toggleCollab = () => {
    setIsCollaborative(prevState => !prevState);
  };

  return (
    <>
      <View
        style={{
          // backgroundColor: Colors.light.container,
          marginTop: isWideScreen ? '3%' : '3%',
          marginHorizontal: '3%',
          padding: isWideScreen ? 30 : 20,
        }}
      >
        <View>
          <View
            style={{
              flexDirection: 'row',
              alignSelf: 'center',
              alignItems: 'center',
              marginBottom: '6%',
            }}
          >
            <TextBodyExtraBold style={[isWideScreen ? Styles.h1 : Styles.h2, { color: Colors.primary, alignSelf: 'center', marginRight: 15 }]}>Let's gather what you need</TextBodyExtraBold>
          </View>
          {!checkLoading('uploadDocument') ? (
            <View>
              <View style={{ flexDirection: isWideScreen ? 'row' : 'column', justifyContent: 'center' }}>
                <View
                  style={{
                    width: isWideScreen ? (alignmentState.files ? '35%' : '25%') : '100%',
                    ...(alignmentState.files && alignmentState.files.length > 0 ? { ...Styles.shadow, shadowOpacity: 1, shadowColor: Colors.primary } : {}),
                  }}
                >
                  <UploadDocument onPress={uploadDocumentForRequirements}>
                    <View
                      style={[
                        Styles.shadow,
                        {
                          alignItems: 'center',
                          padding: 30,
                          borderRadius: 5,
                          backgroundColor: Colors.light.container,
                          height: 200,
                          justifyContent: 'center',
                          overflow: 'hidden',
                        },
                      ]}
                    >
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          paddingHorizontal: 25,
                          paddingVertical: 10,
                          backgroundColor: Colors.buttonBackground,
                          justifyContent: 'center',
                          borderRadius: 5,
                        }}
                      >
                        <TextBodyMedium style={[Styles.h5, { color: '#fff' }]}>Upload a file</TextBodyMedium>
                        <Ionicons
                          name="cloud-upload"
                          size={24}
                          color="#fff"
                          style={{ marginLeft: 10 }}
                        />
                      </View>
                      {!alignmentState.files && (
                        <View
                          style={{
                            position: 'absolute',
                            width: '100%',
                            top: 0,
                            padding: 15,
                            zIndex: -1,
                          }}
                        >
                          <TextBodyMedium style={[Styles.h7, { color: '#999' }]}>A document containing a list of requests, like a questionaire.</TextBodyMedium>
                        </View>
                      )}
                      {alignmentState.files && (
                        <View
                          style={{
                            position: 'absolute',
                            width: '100%',
                            bottom: 0,
                            padding: 15,
                            zIndex: -1,
                          }}
                        >
                          {alignmentState.files.length > 3 ? (
                            <ScrollView>
                              <TextBodyMedium style={[isWideScreen ? Styles.h6 : Styles.h5, { color: '#999', marginTop: 10, marginBottom: 5 }]}>Files uploaded:</TextBodyMedium>
                              <TextBodyMedium style={[Styles.h7, { color: '#999' }]}>
                                {alignmentState.files
                                  .map(file =>
                                    Array.from(file).map(f => {
                                      return `${f.name}||`;
                                    }),
                                  )
                                  .join('')
                                  .split('||')
                                  .map((name, i) => `${i + 1}: ${name}\n`.replace(',', ''))
                                  .slice(0, -1)}
                              </TextBodyMedium>
                            </ScrollView>
                          ) : (
                            <View>
                              <TextBodyMedium style={[isWideScreen ? Styles.h6 : Styles.h5, { color: '#999', marginTop: 10, marginBottom: 5 }]}>Files uploaded:</TextBodyMedium>
                              <TextBodyMedium style={[Styles.h7, { color: '#999' }]}>
                                {alignmentState.files
                                  .map(file =>
                                    Array.from(file).map(f => {
                                      return `${f.name}||`;
                                    }),
                                  )
                                  .join('')
                                  .split('||')
                                  .map((name, i) => `${i + 1}: ${name}\n`.replace(',', ''))
                                  .slice(0, -1)}
                              </TextBodyMedium>
                            </View>
                          )}
                        </View>
                      )}
                    </View>
                  </UploadDocument>
                </View>
                <TextBodyMedium style={[Styles.h3, { paddingHorizontal: isWideScreen ? 100 : 0, marginVertical: isWideScreen ? 0 : 20, alignSelf: 'center' }]}>or</TextBodyMedium>
                <View
                  style={{
                    width: isWideScreen ? (chatGenerateList ? '40%' : '25%') : '100%',
                    justifyContent: 'center',
                    ...(chatGenerateList ? { ...Styles.shadow, shadowOpacity: 1, shadowColor: Colors.primary } : {}),
                  }}
                >
                  <Input
                    height={200}
                    style={[Styles.input]}
                    placeholder={`Your list of requests\n- Request 1\n- Request 2\n- Request 3\n- etc`}
                    placeholderTextColor="#aaa"
                    onChangeText={setChatGenerateList}
                    value={chatGenerateList}
                    multiline={true}
                    numberOfLines={5}
                    autoFocus={false}
                  />
                </View>
              </View>
              <TextBodyMedium style={[isWideScreen ? Styles.h5 : Styles.h5, { marginTop: '6%', marginBottom: 20, textAlign: 'center' }]}>Or you can select from an option below</TextBodyMedium>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', width: '60%', alignSelf: 'center', alignItems: 'center' }}>
                <Button
                  style={{ marginRight: 10, marginBottom: 10 }}
                  fontStyle={[Styles.h6, { color: '#fff' }]}
                  theme={`secondary small ${selection === 'compliance' && 'active'}`}
                  onPress={() => selectCustomPrompt('compliance')}
                >
                  Compliance
                </Button>
                <Button
                  style={{ marginRight: 10, marginBottom: 10 }}
                  fontStyle={[Styles.h6, { color: '#fff' }]}
                  theme={`secondary small ${selection === 'dealScreen' && 'active'}`}
                  onPress={() => selectCustomPrompt('dealScreen')}
                >
                  Deal Screen
                </Button>
                <Button
                  style={{ marginRight: 10, marginBottom: 10 }}
                  fontStyle={[Styles.h6, { color: '#fff' }]}
                  theme={`secondary small ${selection === 'diligenceQuestionnaire' && 'active'}`}
                  onPress={() => selectCustomPrompt('diligenceQuestionnaire')}
                >
                  Diligence Questionnaire
                </Button>
                <Button
                  style={{ marginRight: 10, marginBottom: 10 }}
                  fontStyle={[Styles.h6, { color: '#fff' }]}
                  theme={`secondary small ${selection === 'proposalReview' && 'active'}`}
                  onPress={() => selectCustomPrompt('proposalReview')}
                >
                  Proposal Review
                </Button>
                <Button
                  style={{ marginRight: 10, marginBottom: 10 }}
                  fontStyle={[Styles.h6, { color: '#fff' }]}
                  theme={`secondary small ${selection === 'portfolioReporting' && 'active'}`}
                  onPress={() => selectCustomPrompt('portfolioReporting')}
                >
                  Portfolio Reporting
                </Button>
                <Button
                  style={{ marginRight: 10, marginBottom: 10 }}
                  fontStyle={[Styles.h6, { color: '#fff' }]}
                  theme={`secondary small ${selection === 'marketAssessment' && 'active'}`}
                  onPress={() => selectCustomPrompt('marketAssessment')}
                >
                  Market Assessment
                </Button>
                <Button
                  style={{ marginRight: 10, marginBottom: 10 }}
                  fontStyle={[Styles.h6, { color: '#fff' }]}
                  theme={`secondary small ${selection === 'programMonitoring' && 'active'}`}
                  onPress={() => selectCustomPrompt('programMonitoring')}
                >
                  Program Monitoring
                </Button>
              </View>
            </View>
          ) : (
            <View style={{ alignItems: 'flex-start' }}>
              <TextBodyMedium style={[isWideScreen ? Styles.h5 : Styles.h6, { marginTop: 30, marginBottom: 10 }]}>Please wait while we process your document</TextBodyMedium>
              <TextBodyMedium style={[Styles.h7, { marginBottom: 20 }]}>Depending on the size and quantity of files uploaded, this can take anywhere between 10 to 60 seconds.</TextBodyMedium>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                }}
              >
                <ActivityIndicator
                  size={30}
                  color={Colors.primary}
                  style={{ marginRight: 10 }}
                />
                <CountdownTimer
                  size={14}
                  initialTime={60}
                />
              </View>
            </View>
          )}
        </View>
      </View>
      <View
        style={{
          flexDirection: isWideScreen ? 'row' : 'column',
          justifyContent: 'space-between',
          width: isWideScreen ? '90%' : '90%',
          alignSelf: 'center',
          alignItems: 'flex-start',
        }}
      >
        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            width: isWideScreen ? '33%' : '100%',
            minHeight: 142,
            borderRadius: 5,
          }}
        >
          <Pressable
            style={{ padding: isWideScreen ? 30 : 20, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}
            onPress={() => setShowAssignee(prevState => !prevState)}
          >
            <View style={{ width: '75%' }}>
              <TextBodyMedium style={[Styles.h4, { color: Colors.primary, flexShrink: 1, marginBottom: 10 }]}>{`Who will you be requesting from (optional)`}</TextBodyMedium>
              <InfoToolTip toolTip={`We will automate the reminders for you.`} />
            </View>
            <Ionicons
              name={showAssignee ? 'chevron-up' : 'chevron-down'}
              size={30}
            />
          </Pressable>
          {showAssignee && (
            <View
              style={{
                paddingHorizontal: 30,
                paddingBottom: 30,
              }}
            >
              <View
                style={{
                  marginBottom: 20,
                  borderTopWidth: 1,
                  borderTopColor: '#fff',
                }}
              >
                <View
                  style={{
                    alignSelf: 'flex-start',
                  }}
                >
                  <Button
                    theme="simple"
                    onPress={toggleCollab}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom: 10,
                        width: '100%',
                      }}
                    >
                      <Fontisto
                        name={isCollaborative ? 'checkbox-passive' : 'checkbox-active'}
                        size={20}
                        style={{ marginRight: isCollaborative ? 10 : 8 }}
                        color="black"
                      />
                      <View style={{ marginLeft: 10, flexShrink: 1 }}>
                        <TextBodyMedium style={[isWideScreen ? Styles.h5 : Styles.h6]}>{`Collaborative?`}</TextBodyMedium>
                        <TextInterRegular style={[Styles.h7, { flexShrink: 1 }]}>{`Will multiple people work together or will each of them receive their own?`}</TextInterRegular>
                      </View>
                    </View>
                  </Button>
                </View>
                <TextInterRegular style={[Styles.h7]}>{`Note: This is not currently operational but it provides an idea of what we could do.  List the emails of who should respond to your requests. Add multiple recipients comma separated.`}</TextInterRegular>
              </View>
              <Input
                height={100}
                style={[Styles.input, {}]}
                placeholder="Email addresses of assignees"
                placeholderTextColor="#aaa"
                onChangeText={text => setAlignmentState({ ...alignmentState, assignees: text })}
                value={alignmentState.assignees}
                multiline={true}
                numberOfLines={5}
              />
            </View>
          )}
        </View>
        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            width: isWideScreen ? '33%' : '100%',
            minHeight: 142,
            borderRadius: 5,
          }}
        >
          <Pressable
            style={{ padding: isWideScreen ? 30 : 20, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}
            onPress={() => setShowDateTimePicker(!showDateTimePicker)}
          >
            <View style={{ width: '75%', flexShrink: 1 }}>
              <TextBodyMedium style={[Styles.h4, { color: Colors.primary, marginBottom: 10 }]}>{`When do you want it by (optional)`}</TextBodyMedium>
              <InfoToolTip toolTip={`Schedule a date and we'll remind them.`} />
            </View>
            <Ionicons
              name={showDateTimePicker ? 'chevron-up' : 'chevron-down'}
              size={30}
            />
          </Pressable>
          {showDateTimePicker && (
            <View
              style={{
                width: '100%',
                paddingHorizontal: 20,
                // marginLeft: isWideScreen ? 50 : 0,
              }}
            >
              <DateTimePicker
                mode="single"
                onChange={params => params.date && setAlignmentState({ ...alignmentState, deadline: params.date.toString() })}
                date={alignmentState.deadline}
              />
            </View>
          )}
        </View>
        <View
          style={{
            backgroundColor: Colors.light.container,
            marginTop: '3%',
            width: isWideScreen ? '33%' : '100%',
            minHeight: 142,
            borderRadius: 5,
          }}
        >
          <Pressable
            style={{ padding: isWideScreen ? 30 : 20, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}
            onPress={() => setShowOwnerEmail(!showOwnerEmail)}
          >
            <View style={{ width: '75%', flexShrink: 1 }}>
              <TextBodyMedium style={[Styles.h4, { color: Colors.primary, marginBottom: 10 }]}>{`Would you like to stay informed? (optional)`}</TextBodyMedium>
              <InfoToolTip toolTip={`We will send you notifcations of alignment status changes.`} />
            </View>
            <Ionicons
              name={showOwnerEmail ? 'chevron-up' : 'chevron-down'}
              size={30}
            />
          </Pressable>
          {showOwnerEmail && (
            <View
              style={{
                paddingHorizontal: 30,
                paddingBottom: 30,
              }}
            >
              <Input
                style={[Styles.input, { borderWidth: 1 }]}
                placeholder="Your email"
                placeholderTextColor="#aaa"
                onChangeText={text => setAlignmentState({ ...alignmentState, ownerEmail: text })}
                value={alignmentState.ownerEmail}
                multiline={false}
                numberOfLines={1}
              />
            </View>
          )}
        </View>
      </View>
      <View
        style={{
          marginTop: '3%',
          marginHorizontal: '3%',
          padding: isWideScreen ? 30 : 20,
        }}
      >
        {checkLoading('uploadDocument') ? (
          <View style={{ alignItems: 'flex-start', width: '70%' }}>
            <TextBodyMedium style={[isWideScreen ? Styles.h5 : Styles.h6, { marginBottom: 10 }]}>Please wait while we process your information</TextBodyMedium>
            <TextBodyMedium style={[Styles.h7, { marginBottom: 20 }]}>Depending on the size and quantity of information being processed, this can take anywhere between 10 to 60 seconds.</TextBodyMedium>
            <View
              style={{
                flexDirection: 'row',
              }}
            >
              <ActivityIndicator
                size={30}
                color={Colors.primary}
                style={{ marginRight: 10 }}
              />
              <CountdownTimer
                size={14}
                initialTime={60}
              />
            </View>
          </View>
        ) : (
          <View
            style={{
              alignSelf: 'center',
              // flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Button
              onPress={onSubmit}
              theme="primary"
              toolTip={`Before publishing, you'll have a chance to review.`}
              disabled={!(preFilledList || chatGenerateList || alignmentState.files)}
              iconAfter="chevron-forward"
              iconBefore="chevron-forward"
            >
              Next
            </Button>
            {!(preFilledList || chatGenerateList || alignmentState.files) && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 30,
                }}
              >
                <Ionicons
                  name="alert-circle"
                  size={20}
                />
                <TextBodyRegular style={[Styles.h8, { marginLeft: 5 }]}>{`You must first either input something above or upload a reference document.`}</TextBodyRegular>
              </View>
            )}
          </View>
        )}
      </View>
    </>
  );
};

export default SPSMake;
