/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getSubmissionPreScreen = /* GraphQL */ `query GetSubmissionPreScreen($id: ID!) {
  getSubmissionPreScreen(id: $id) {
    id
    deliverables
    requirements
    deadline
    emailLLM
    titleLLM
    grade
    deliverablesLLM {
      title
      summaryShort
      summaryLong
      keywords
      methodology
      issues
      grade
      status
      approaches
      sourceMaterials {
        pageContent
        metadata {
          fileName
          linesFrom
          linesTo
          pageNumber
          createdAt
          __typename
        }
        __typename
      }
      queries
      __typename
    }
    requirementsLLM
    changeLog {
      reporter
      createDate
      messages
      files
      __typename
    }
    assignees
    ownerEmail
    indexId
    namespace
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubmissionPreScreenQueryVariables,
  APITypes.GetSubmissionPreScreenQuery
>;
export const listSubmissionPreScreens = /* GraphQL */ `query ListSubmissionPreScreens(
  $filter: ModelSubmissionPreScreenFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubmissionPreScreens(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      deliverables
      requirements
      deadline
      emailLLM
      titleLLM
      grade
      deliverablesLLM {
        title
        summaryShort
        summaryLong
        keywords
        methodology
        issues
        grade
        status
        approaches
        sourceMaterials {
          pageContent
          __typename
        }
        queries
        __typename
      }
      requirementsLLM
      changeLog {
        reporter
        createDate
        messages
        files
        __typename
      }
      assignees
      ownerEmail
      indexId
      namespace
      role
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubmissionPreScreensQueryVariables,
  APITypes.ListSubmissionPreScreensQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    title
    history
    indexId
    namespace
    documents
    reports {
      versions {
        title
        text
        date
        sourceMaterial {
          pageContent
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      history
      indexId
      namespace
      documents
      reports {
        versions {
          title
          text
          date
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
