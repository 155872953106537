import { TextBodyMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { ModalContext } from '@src/providers/Modal';
import React, { useContext } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import TaskItem from './TaskItem';
import TaskModal from './TaskModal';

interface TaskListComponentProps {
  actionPlan: Array<any>;
  indexId?: string;
  namespace?: string;
}

const TaskListComponent = ({ actionPlan, indexId, namespace }: TaskListComponentProps) => {
  const { setModalContent } = useContext(ModalContext);

  const openTask = (task: any) => {
    // todo we need to run some queries while this modal is open.
    setModalContent(
      <TaskModal
        task={task}
        indexId={indexId}
        namespace={namespace}
      />,
    );
  };

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          // justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            width: '60%',
            justifyContent: 'center',
            paddingLeft: 30,
            borderRightWidth: 1,
            borderRightColor: '#eee',
          }}
        >
          <TouchableOpacity>
            <TextBodyMedium style={[Styles.h6]}>Name</TextBodyMedium>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: '20%',
            justifyContent: 'center',
            paddingLeft: 30,
            borderRightWidth: 1,
            borderRightColor: '#eee',
          }}
        >
          <TouchableOpacity>
            <TextBodyMedium style={[Styles.h6]}>Status</TextBodyMedium>
          </TouchableOpacity>
        </View>
        <View
          style={{
            width: '20%',
            justifyContent: 'center',
            paddingLeft: 20,
            paddingRight: 30,
          }}
        >
          <TextBodyMedium style={[Styles.h6]}>Difficulty</TextBodyMedium>
          <TextBodyMedium style={[Styles.h9]}>(out of 10)</TextBodyMedium>
        </View>
        <View
          style={{
            width: '10%',
          }}
        />
      </View>
      <View
        style={{
          flexDirection: 'column',
        }}
      >
        {actionPlan &&
          actionPlan.map((task, index) => {
            return (
              <View
                key={`action-item-${index}`}
                style={{
                  marginVertical: 10,
                }}
              >
                <TaskItem
                  task={task}
                  onPress={() => openTask(task)}
                  index={index}
                />
              </View>
            );
          })}
      </View>
    </View>
  );
};

export default TaskListComponent;
