import { Ionicons } from '@expo/vector-icons';
import ADTSummaryModal from '@src/components/ADT/demo/ADTSummaryModal';
import Colors from '@src/components/shared/Colors';
import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import { ModalContext } from '@src/providers/Modal';
import React, { useContext, useReducer, useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import AddColumnModal from './AddColumnModal';
import MagicTableItem from './MagicTableItem';
import RowUpload from './UploadRow';
const extraColumns = 2;

interface ColumnHeaderProps {
  label: string;
}

const ColumnHeader = ({ label }: ColumnHeaderProps) => {
  return (
    <TouchableOpacity
      onPress={() => {}}
      style={{
        justifyContent: 'center',
        flex: 1,
        padding: 15,
      }}
    >
      <TextBodyMedium style={[Styles.h6, { color: '#fff' }]}>{label}</TextBodyMedium>
    </TouchableOpacity>
  );
};

export type ColumnCriteriaPrompt = ColumnCriteria & {
  prompt?: ({ indexId, namespace, criteria }: createMagicTableCellRequestProps) => {};
};

export type createMagicTableCellRequestProps = {
  indexId: string;
  namespace: string;
  criteria: ColumnCriteria;
};

export type vectorStore = {
  namespace: string;
  indexId: string;
};

export type ColumnCriteria = {
  type: string;
  label: string;
  query: string;
};

interface MagicTableProps {
  defaultCriteriaColumn: ColumnCriteriaPrompt[];
  defaultVectorStores: vectorStore[];
}

const MagicTable = ({ defaultCriteriaColumn, defaultVectorStores }: MagicTableProps) => {
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const { setModalContent } = useContext(ModalContext);
  const [criteriaColumns, setCriteriaColumns] = useState<ColumnCriteriaPrompt[]>(defaultCriteriaColumn);
  const [vectorStores, setVectorStores] = useState<vectorStore[]>(defaultVectorStores);

  const w = 100 / (criteriaColumns.length + extraColumns);
  const cellWidth = 150 > w ? 150 : `${w}%`;

  const onAddColumn = () => {
    // todo we need to run some queries while this modal is open.
    setModalContent(
      <AddColumnModal
        setCriteriaColumns={setCriteriaColumns}
        criteriaColumns={criteriaColumns}
      />,
    );
  };

  const addRow = (vs: vectorStore) => {
    let newVectorStores = [...vectorStores, vs];
    setVectorStores(newVectorStores);
  };

  const gotoReport = (vsAttributes: vectorStore) => {
    setModalContent(
      <ADTSummaryModal
        query="Summary"
        indexId={vsAttributes.indexId}
        namespace={vsAttributes.namespace}
      />,
    );
  };

  const onReload = () => {
    setVectorStores([...vectorStores]);
    forceUpdate();
  };

  return (
    <ScrollView
      style={{
        marginTop: 30,
        backgroundColor: '#fff',
      }}
      contentContainerStyle={
        {
          // alignItems: 'center',
          // width: '100%',
          // width: Dimensions.get('window').width - 50,
          // width: 'calc(100% - 50px)',
          // marginRight: 50,
        }
      }
      horizontal
    >
      <View
        style={{
          // marginRight: 50, // This effectively removes 50px from the total width
          width: '100%',
        }}
      >
        {/* column headers */}
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: Colors.bluelighter,
            // justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <View
            style={{
              width: 50,
              justifyContent: 'center',
              borderRightWidth: 1,
              borderRightColor: '#eee',
              borderBottomWidth: 1,
              borderBottomColor: '#eee',
              alignItems: 'center',
            }}
          >
            <Pressable onPress={onReload}>
              <Ionicons
                name="reload-sharp"
                size={20}
                color={'#fff'}
              />
            </Pressable>
          </View>
          {criteriaColumns.map((task, index) => {
            return (
              <View
                key={`column-header-${index}`}
                style={{
                  width: cellWidth,
                  borderRightWidth: 1,
                  borderRightColor: '#eee',
                  borderBottomWidth: 1,
                  borderBottomColor: '#eee',
                  flex: 1,
                  justifyContent: 'center',
                }}
              >
                <ColumnHeader label={task.label} />
              </View>
            );
          })}
          <View
            style={{
              width: cellWidth,
              borderRightWidth: 1,
              borderRightColor: '#eee',
              borderBottomWidth: 1,
              borderBottomColor: '#eee',
              justifyContent: 'space-between',
              padding: 15,
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Ionicons
              name="document-text-sharp"
              color="#fff"
              size={20}
            />
            <TextBodyRegular style={[Styles.h6, { color: '#fff' }]}>Summary</TextBodyRegular>
          </View>
          <View
            style={{
              width: cellWidth,
              justifyContent: 'center',
              borderRightWidth: 1,
              borderRightColor: '#eee',
              borderBottomWidth: 1,
              borderBottomColor: '#eee',
              padding: 15,
              flex: 1,
            }}
          >
            <TouchableOpacity
              style={{ flex: 1, justifyContent: 'center' }}
              onPress={onAddColumn}
            >
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextBodyRegular style={[Styles.h6, { marginRight: 10, color: '#fff' }]}>Add column</TextBodyRegular>
                <Ionicons
                  name="add"
                  size={20}
                  color="#fff"
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>

        {/* rows */}

        {vectorStores &&
          vectorStores.map((vsAttributes, rowIndex: number) => {
            return (
              <View
                key={`row-${rowIndex}`}
                style={{
                  flexDirection: 'row',
                }}
              >
                <View
                  style={{
                    width: 50,
                    borderRightWidth: 1,
                    borderRightColor: '#eee',
                    borderBottomWidth: 1,
                    borderBottomColor: '#eee',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <TextBodyRegular style={Styles.h6}>{rowIndex + 1}</TextBodyRegular>
                </View>
                {criteriaColumns &&
                  criteriaColumns.map((columnCriteria, columnIndex) => {
                    return (
                      <MagicTableItem
                        key={`row-${columnIndex}`}
                        columnCount={criteriaColumns.length + extraColumns}
                        criteria={columnCriteria}
                        namespace={vsAttributes.namespace}
                        indexId={vsAttributes.indexId}
                      />
                    );
                  })}
                <Pressable
                  style={{
                    width: cellWidth,
                    justifyContent: 'center',
                    borderRightWidth: 1,
                    borderRightColor: '#eee',
                    borderBottomWidth: 1,
                    borderBottomColor: '#eee',
                    padding: 15,
                    flex: 1,
                  }}
                  onPress={() => gotoReport(vsAttributes)}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Ionicons
                      name="document-text-sharp"
                      color="#000"
                      size={20}
                    />
                    <TextBodyRegular style={Styles.h6}>Go to summary</TextBodyRegular>
                  </View>
                </Pressable>
                <View
                  style={{
                    width: cellWidth,
                    justifyContent: 'center',
                    borderRightWidth: 1,
                    borderRightColor: '#eee',
                    borderBottomWidth: 1,
                    borderBottomColor: '#eee',
                    padding: 15,
                    flex: 1,
                  }}
                >
                  <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={onAddColumn}
                  >
                    <Ionicons
                      name="add"
                      size={30}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            );
          })}
        <RowUpload addRow={addRow} />
      </View>
    </ScrollView>
  );
};

export default MagicTable;
