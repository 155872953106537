import React from 'react';
import { ScrollView } from 'react-native';
import Colors from '../shared/Colors';
import { View } from '../shared/Themed';

interface ScreenProps {
  children: JSX.Element;
}
const Screen = ({ children }: ScreenProps) => {
  // const scrollViewRef = useRef(null);
  // if (scrollViewRef.current) {
  //   scrollViewRef.current.scrollTo({
  //     y: 0, // Scroll to the top
  //     animated: false,
  //   });
  // }

  return (
    <ScrollView
      // ref={scrollViewRef}
      contentContainerStyle={{
        width: '100%',
        minHeight: '100%',
        backgroundColor: Colors.light.background,
      }}
    >
      <View
        style={{
          flex: 1,
          flexGrow: 1,
          width: '95%',
          margin: 'auto',
        }}
      >
        {children}
      </View>
    </ScrollView>
  );
};

export default Screen;
