import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';

interface CountdownTimerProps {
  initialTime: number; // Time in seconds
  size: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ initialTime, size = 24 }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(prevTime => {
        // if (prevTime <= 1) {
        //   clearInterval(intervalId);
        //   return 0;
        // }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <View
      style={{
        marginVertical: 10,
      }}
    >
      <Text
        style={{
          fontSize: size,
          fontWeight: 'bold',
        }}
      >
        ~{timeLeft} seconds remaining
      </Text>
    </View>
  );
};

export default CountdownTimer;
