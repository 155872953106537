import Button from '@src/components/Button';
import CountdownTimer from '@src/components/Countdown';
import Input from '@src/components/Input';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import useResponsive from '@src/hooks/useResponsive';
import { LoadingContext } from '@src/providers/Loading';
import { ModalContext } from '@src/providers/Modal';
import { chat } from '@src/utils/Chat';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';

interface ADTSummaryModalProps {
  query?: string;
  indexId: string;
  namespace: string;
}

const ADTSummaryModal = ({ query, indexId, namespace }: ADTSummaryModalProps) => {
  const { isWideScreen } = useResponsive();
  const { setModalContent } = useContext(ModalContext);
  const { checkLoading, addLoading, removeLoading } = useContext(LoadingContext);
  const [summaryChat, setSummaryChat] = useState<any[]>();
  const [reviseSummaryQuery, setReviseSummaryQuery] = useState<string>(query);
  const scrollViewRef = useRef(null);

  const scrollToTop = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        y: 0, // Scroll to the top
        animated: false,
      });
    }
  };

  const onSubmitEditSummary = async () => {
    addLoading('onSubmitEditSummary');
    setReviseSummaryQuery('');
    const { taskDetailsLLM } = await askSummary(reviseSummaryQuery);
    if (taskDetailsLLM && summaryChat) {
      const newSummaryChat = [...summaryChat, taskDetailsLLM];
      setSummaryChat(newSummaryChat);
    }
    removeLoading('onSubmitEditSummary');
  };

  const closeModal = () => {
    setModalContent(undefined);
  };

  // create an email based on the task and assignee
  const askSummary = async (query: string) => {
    const contextSource = {
      requestName: 'question',
      modelName: 'gpt-4o-mini',
      indexId,
      namespace,
      question: query,
    };

    const taskDetailsLLM = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `Review this context <context>{contextSource}</context>. Your response must be formated in json {{ summary: "a markdown in depth summary of how the <context> relates to the "${query}" with specific details and facts in an outline format with headings, subheadings, and lists as needed.  Include any values or amounts and show any algorithms used."}}. `,
      isJson: true,
    };

    return await chat({ 0: { contextSource }, 1: { taskDetailsLLM } });
  };
  // create an email based on the task and assignee
  const loadSummary = async () => {
    const contextSource = {
      requestName: 'question',
      modelName: 'gpt-4o-mini',
      indexId,
      namespace,
      question: 'Information',
    };

    const taskDetailsLLM = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `Review this context <context>{contextSource}</context>. Your response must be formated in json {{ summary: "a markdown in depth summary of how the <context> with specific details and facts in an outline format with headings, subheadings, and lists as needed.  Include any values or amounts and show any algorithms used."}}. `,
      isJson: true,
    };

    return await chat({ 0: { contextSource }, 1: { taskDetailsLLM } });
  };

  const load = async () => {
    // if ( noDocuments ) {
    //   // tell the submitted what they need to submit, show the best way we think one could answer the ask with only information about the ask itself
    // } else if ( documentsExist && !context ) {
    //   // tell the submitted what they need to submit, show the best way we think one could answer the ask with only information about the ask itself
    // } else if ( documentsExist && context ) {

    // }
    addLoading('loadSummary');
    const { taskDetailsLLM } = await loadSummary();
    if (taskDetailsLLM) {
      // if (taskDetails.emailChainRequest) setEmailChat(taskDetails.emailChainRequest);
      if (taskDetailsLLM) setSummaryChat([taskDetailsLLM]);
    }
    scrollToTop();
    removeLoading('loadSummary');
  };

  useEffect(() => {
    load();
    scrollToTop();
  }, []);

  const emailSummaryElement = () => {
    return (
      <>
        {summaryChat && (
          <View
            style={{
              width: '100%',
            }}
          >
            {summaryChat.length &&
              summaryChat.map((summary, index) => {
                return (
                  <View
                    key={`summarychat-${index}`}
                    style={{
                      marginVertical: 20,
                      width: '100%',
                      borderTopWidth: 1,
                      borderTopColor: '#000',
                      paddingTop: 20,
                    }}
                  >
                    {summary.summary && (
                      <>
                        <TextBodyRegular style={[Styles.h6, { marginBottom: 15 }]}>{summary.summary}</TextBodyRegular>
                      </>
                    )}
                    {checkLoading('onSubmitEditSummary') && (
                      <View style={{ marginBottom: 30 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                          <TextBodyRegular style={[Styles.h4, { marginRight: 20 }]}>Loading</TextBodyRegular>
                          <ActivityIndicator
                            size={20}
                            color="black"
                          />
                        </View>
                        <TextBodyRegular style={[Styles.h7, { marginTop: 20, width: '50%' }]}>Please wait. This can take 15-60 seconds depending on the complexity of the question.</TextBodyRegular>
                        <CountdownTimer
                          size={14}
                          initialTime={30}
                        />
                      </View>
                    )}
                  </View>
                );
              })}
            <>
              <Input
                style={Styles.input}
                placeholder={'What can I help you with?'}
                placeholderTextColor={'#999'}
                height={50}
                onChangeText={setReviseSummaryQuery}
                value={reviseSummaryQuery}
                onSubmitEditing={onSubmitEditSummary}
              />
              <Button
                style={{ alignSelf: 'flex-end', marginTop: 20 }}
                onPress={onSubmitEditSummary}
                theme="primary"
              >
                <View style={{ flexDirection: 'row' }}>
                  <TextBodyRegular style={[Styles.h6, { color: '#fff' }]}>Submit</TextBodyRegular>
                </View>
              </Button>
            </>
          </View>
        )}
      </>
    );
  };
  return (
    <View
      style={{
        position: 'absolute',
        left: isWideScreen ? '10%' : 0,
        top: isWideScreen ? '10%' : 0,
        bottom: isWideScreen ? '10%' : 0,
        width: isWideScreen ? '80%' : '100%',
      }}
    >
      <ScrollView
        ref={scrollViewRef}
        style={{
          backgroundColor: '#fff',
          padding: 20,
        }}
      >
        <View
          style={{
            padding: 20,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                width: '85%',
                marginTop: 20,
              }}
            >
              {query && <TextBodyMedium style={[Styles.h3, { marginBottom: 10 }]}>{query}</TextBodyMedium>}
            </View>
            <View
              style={{
                alignSelf: 'flex-start',
              }}
            >
              <Button
                theme="primary"
                onPress={closeModal}
                iconAfter="close"
              ></Button>
            </View>
          </View>
          {checkLoading('loadSummary') && (
            <>
              <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                <ActivityIndicator
                  size={20}
                  color="black"
                />
                <TextHeader style={[Styles.h4, { marginLeft: 10 }]}>Loading</TextHeader>
              </View>
              <TextBodyRegular style={[Styles.h7, { marginTop: 10, width: '45%' }]}>Please wait. This can take 10-30 seconds depending on the number of files.</TextBodyRegular>
              <CountdownTimer
                size={14}
                initialTime={30}
              />
            </>
          )}
          {!checkLoading('loadSummary') && (
            <>
              {isWideScreen ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  {emailSummaryElement()}
                </View>
              ) : (
                <>{emailSummaryElement()}</>
              )}
            </>
          )}
        </View>
      </ScrollView>
    </View>
  );
};

export default ADTSummaryModal;
