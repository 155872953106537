import { Ionicons } from '@expo/vector-icons';
import { TextBodyMedium, TextBodyRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import useResponsive from '@src/hooks/useResponsive';
import React, { Dispatch, SetStateAction } from 'react';
import { ScrollView, View } from 'react-native';
import Button from '../Button';
import Collapsible from '../Collapsible';

interface SourceMaterialsProps {
  sourceMaterials: any;
  setViewerFile: Dispatch<SetStateAction<string | undefined>>;
}

const SourceMaterials = ({ sourceMaterials, setViewerFile }: SourceMaterialsProps) => {
  const { isWideScreen } = useResponsive();
  return (
    <ScrollView
      style={{
        maxHeight: isWideScreen ? 500 : 300,
        borderWidth: 1,
        borderColor: '#000',
        borderRadius: 5,
        backgroundColor: '#f9f9f9',
      }}
    >
      <View style={{ padding: 20 }}>
        <TextBodyMedium style={[Styles.h4, { marginBottom: 15 }]}>Source Materials</TextBodyMedium>
        {sourceMaterials.map((material: any, index: number) => {
          return (
            <View key={`material-${index}`}>
              <Collapsible
                // isOpen={index === 0}
                button={<TextBodyMedium style={[Styles.h6, { paddingVertical: 10 }]}>{`Footnote ${index + 1}`}</TextBodyMedium>}
              >
                <>
                  <TextBodyRegular style={[Styles.h8, { fontStyle: 'italic', marginBottom: 15 }]}>{`"${material.pageContent}"`}</TextBodyRegular>
                  {material.metadata && material.metadata.fileName && (
                    <View
                      style={{
                        alignSelf: 'flex-start',
                      }}
                    >
                      <TextBodyMedium style={[Styles.h6, { marginBottom: 15, color: '#000' }]}>{`Page: ${material.metadata.pageNumber}`}</TextBodyMedium>
                      <Button
                        onPress={() => setViewerFile(material.metadata.fileName)}
                        theme="secondary small"
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Ionicons
                            name="document"
                            size={24}
                            color="#fff"
                          />
                          <TextBodyRegular style={[Styles.h8, { marginLeft: 5, color: '#fff' }]}>{material.metadata.fileName.length > 40 ? `${material.metadata.fileName.substring(0, 40)}...` : material.metadata.fileName}</TextBodyRegular>
                        </View>
                      </Button>
                    </View>
                  )}
                </>
              </Collapsible>
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

export default SourceMaterials;
