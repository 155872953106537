/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import { Ionicons } from '@expo/vector-icons';
import { Picker as DefaultPicker } from '@react-native-picker/picker';
import Colors from '@src/components/shared/Colors';
import useColorScheme from '@src/hooks/useColorScheme';
import * as React from 'react';
import { Animated as DefaultAnimated, FlatList as DefaultFlatList, Image as DefaultImage, Pressable as DefaultPressable, Switch as DefaultSwitch, Text as DefaultText, TextInput as DefaultTextInput, View as DefaultView } from 'react-native';

export function useThemeColor(props: { light?: string; dark?: string }, colorName: keyof typeof Colors.light & keyof typeof Colors.dark) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  }
  return Colors[theme][colorName];
}

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

type TypePicker = {
  selectedValue?: string;
  options: Array<unknown>;
  setResult: (result: string) => void;
  style?: unknown;
};

type TypeSwitch = {
  isEnabled?: boolean;
  toggleSwitch: (result: boolean) => void;
  label: string;
};

export type AnimatedProps = ThemeProps & DefaultAnimated['props'];
export type TextProps = ThemeProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];
export type FlatListProps = ThemeProps & DefaultFlatList['props'];
export type TextInputProps = ThemeProps & DefaultTextInput['props'];
export type SwitchProps = ThemeProps & DefaultSwitch['props'] & TypeSwitch;
export type PickerProps = ThemeProps & DefaultPicker<string>['props'] & TypePicker;
export type PressableProps = ThemeProps & DefaultPressable['props'];
export type ImageProps = ThemeProps & DefaultImage['props'];

export function Text(props: TextProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');
  const shadowColor = useThemeColor({ light: lightColor, dark: darkColor }, 'shadow');

  return (
    <DefaultText
      style={[{ color, shadowColor }, style]}
      {...otherProps}
    />
  );
}

export function ViewScreen(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'appBackground');

  return (
    <DefaultView
      style={[style]}
      {...otherProps}
    />
  );
}

export function View(props: ViewProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

  return (
    <DefaultView
      style={[style]}
      {...otherProps}
    />
  );
}

export function FlatList(props: FlatListProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

  return (
    <DefaultFlatList
      style={[{ backgroundColor }, style]}
      {...otherProps}
    />
  );
}

export function TextInput(props: TextInputProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return (
    <DefaultTextInput
      style={[{ color }, style]}
      {...otherProps}
    />
  );
}

export function Pressable(props: PressableProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  // const backgroundColor = useThemeColor(
  //   { light: lightColor, dark: darkColor },
  //   'background',
  // );

  return (
    <DefaultPressable
      style={[
        {
          userSelect: 'none',
        },
        style,
      ]}
      {...otherProps}
    />
  );
}

export function Image(props: ImageProps) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'background');

  return (
    <DefaultImage
      style={[{ backgroundColor }, style]}
      {...otherProps}
    />
  );
}

export function Switch(props: SwitchProps) {
  const { style, lightColor, darkColor, isEnabled, toggleSwitch, label, children, ...otherProps } = props;
  // const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return (
    <View
      style={[
        props.style,
        {
          flexDirection: 'row',
          alignItems: 'center',
        },
      ]}
    >
      <DefaultSwitch
        trackColor={{ false: '#767577', true: '#81b0ff' }}
        thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
        ios_backgroundColor="#3e3e3e"
        onValueChange={toggleSwitch}
        value={isEnabled}
        checked={isEnabled}
        style={{
          marginRight: 10,
        }}
        {...otherProps}
      />
      {children}
    </View>
  );
}

export function Picker(props: PickerProps) {
  const { selectedValue, setResult, options, style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, 'text');

  return (
    <DefaultPicker
      style={[{ color }, style]}
      {...otherProps}
      selectedValue={selectedValue}
      onValueChange={itemValue => {
        console.log('itemValue', itemValue);
        setResult(itemValue);
      }}
    >
      {options.map((option, index) => (
        <DefaultPicker.Item
          key={`picker-${index}`}
          label={option}
          value={option}
        />
      ))}
    </DefaultPicker>
  );
}

export function AnimatedView(props: AnimatedProps) {
  const { style, transform, lightColor, darkColor, children, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'layer');

  return (
    <DefaultAnimated.View
      style={[{ backgroundColor }, style, transform]}
      {...otherProps}
    >
      {children}
    </DefaultAnimated.View>
  );
}

export function Tooltip(props: AnimatedProps) {
  const { style, transform, lightColor, darkColor, children, ...otherProps } = props;
  const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, 'layer');

  const [onHover, setOnHover] = React.useState(false);
  function handleHoverIn() {
    setOnHover(true);
  }
  function handleHoverOut() {
    setOnHover(false);
  }
  return (
    <View
      style={{
        position: 'relative',
      }}
    >
      <DefaultPressable
        style={[
          {
            backgroundColor,
            // width: 30,
            // height: 30,
            position: 'relative',
            zIndex: 100,
          },
          style,
          transform,
        ]}
        {...otherProps}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
      >
        <Ionicons
          name="information-circle"
          size={20}
        />
      </DefaultPressable>
      <DefaultAnimated.View
        style={{
          position: 'absolute',
          zIndex: 10,
          top: -60,
          left: 30,
          width: 350,
          opacity: onHover ? 1 : 0,
          backgroundColor: '#ddd',
          padding: 20,
          borderRadius: 20,
        }}
      >
        {children}
      </DefaultAnimated.View>
    </View>
  );
}
