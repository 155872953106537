export default {
  title: 'Baltare',
  generics: {
    date: 'Date',
    time: 'Time',
    optional: 'Optional',
    or: 'or',
    ok: 'Ok',
    of: 'of',
    copied: 'Copied',
    close: 'Close',
    perMonth: '/ month',
    heart: '❤️',
    minutes: 'minutes',
    help: 'Need help?',
    clickHere: 'Click here',
    progress: 'Progress',
    documentUpload: 'Document upload',
    readMore: 'Read more',
    learnMore: 'Learn more',
    getStarted: 'Get started',
  },
  buttons: {
    yes: 'Yes',
    no: 'No',
    next: 'Next',
    back: 'Back',
    done: 'Done',
    save: 'Save',
    send: 'Send',
    start: 'Start',
    submit: 'Submit',
    reload: 'Reload',
    login: 'Login',
    logout: 'Logout',
    ask: 'Ask',
    cancel: 'Cancel',
    remove: 'Remove',
    delete: 'Delete',
    confirm: 'Confirm',
    continue: 'Continue',
    update: 'Update',
    select: 'Select',
    skip: 'Skip',
    edit: 'Edit',
    share: 'Share',
    reprompt: 'Reprompt',
    favorite: 'Favorite',
    unfavorite: 'Remove favorite',
    expand: 'Expand',
    more: 'More...',
    hide: 'Hide...',
    apply: 'Apply',
  },
  errorBoundary: {
    title: 'You encountered a bug',
    body: 'Please contact customer support to let them know what you did before this error appeared.',
    automaticReload: 'The app will automatically reload in',
    button: 'Reload app',
  },
  ErrorScreen: {
    title: 'There was an error',
    subtitle: 'Please try again.',
    errorMessage: '"{{errorMessage}}"',
  },
  backOffice: {
    navButton: 'Back Office',
    title: 'Back Office',
    body: 'Welcome to the Back Office.  Here you can test different prompts and see how the app responds.',
  },
  nav: {
    initiator: 'Go to the project initiator portal',
    investor: 'Go to the investor portal',
  },
  investor: {
    dashboard: {
      hero: {
        title: {
          complete: 'Congratulations!',
          started: 'Welcome back!',
          unstarted: 'Welcome!',
        },
        body: {
          complete: 'Your application is complete.  Please give us between 10 to 15 business days for us to review your details.  Click below to learn more!',
          started: 'Please click the button below to continue completing your application from where you left off.',
          unstarted: 'We ask that you complete a compliance application and onboarding questionarie so we can verify your identity and properly serve your investment criteria.',
        },
      },
      applyHere: 'Apply here',
      applicationCriteria: {
        title: 'Application criteria',
        button: 'Change your investment criteria',
      },
      details: {
        title: 'Investor',
        steps: {
          title: 'Pathway to investing in the future',
          0: 'Sign up in Baltare Platform',
          1: 'Get the SDG Commitment Certificate',
          2: 'See what projects meet your criteria',
          3: 'Select a project',
          4: "Let's invest!",
        },
        sections: {
          0: {
            body: 'Institutional-grade project due diligence for our customers to act upon.',
          },
          1: {
            title: 'FREE DEAL FLOW',
            body: 'A continuous-cycle of project deal flow that is within your parameters.',
          },
          2: {
            title: 'CONSISTENT DUE DILIGENCE',
            body: 'Confidently make challenging investments decisions.',
          },
          3: {
            title: 'TRANSPARENT FINANCIAL RISK MODELING',
            body: 'Clear and concise algorithmic explanations.',
          },
          4: {
            title: 'CERTIFICATE OF SDG COMMITMENT',
            body: 'Financial services companies can meet their regulatory compliance commitments.',
          },
        },
      },
    },
  },
  connectionScreen: {
    title: 'Connection issues',
    subtitle: 'You are not connected to the internet.  Recipes can only be found when you are online.',
  },
  auth: {
    title: 'Welcome! Enter your login credentials below.',
    body: 'Transform humanity by helping to enable clean energy production.',
    comingSoon: {
      title: "We're under development!",
      body: "We're working hard to bring you the best experience possible.  Please check back soon.",
      button: 'Schedule a meeting to learn more',
    },
    login: {
      title: 'Sign in',
    },
    create: {
      title: 'Create a new account',
      sectionOne: {
        title: 'Company details:',
        questionOne: {
          label: 'Name',
          placeholder: 'Company name',
        },
        questionTwo: {
          label: 'Website:',
          placeholder: 'Company Website',
        },
        questionThree: {
          label: 'Position:',
          placeholder: 'Company Position',
        },
      },
      verify: {
        title: "Congratulations!  You're almost there.",
        body: "We've sent a verification link to your email.  Please click the link to verify your account.",
      },
    },
  },
  project: {
    dashboard: {
      title: 'Your projects',
      tooltip: 'Below is a list of the projects you have created.  Some may be in progress, others under review and hopefully many completed.  Click on any of the projects below to continue where you left off or check the status of a project.',
      yourProjectsAmount: '{{amount}} projects',
      noProjects: 'You have not yet created any projects. Click the button above to get started.',
      loadingProjects: 'Loading your projects',
      hero: {
        tourBtn: 'Review guide?',
        createBtn: 'Start a new project',
        title: 'Due diligence',
        body: 'Sign in or create an account and upload documents to ask questions of our AI to deduce quantitative and qualitative insights of those documents.',
      },
      uploadSlideButton: {
        title: 'Document upload',
        body: 'Optional document upload to quickly see what data you have and help you find what is missing.',
        button: '',
      },
    },
    newProject: {
      back: 'Your projects',
      acceptTerms: 'By checking this box you agree to our terms & conditions.',
      title: 'Welcome to creating a project on the Baltare platform!',
      body: 'Please upload any related documents and our algorithms will begin analyzing your project immediately, auto-populating your profile with necessary facts that facilitate the due diligence process.\n\nAs you build your application through our guided journey you will be able to attract institutional investment from our global network.',
      placeholder: 'What documents do you already have?',
      projectnamePlaceholder: 'Unnamed new project',
      addProjectNameBelow: 'First choose a name for your project,\nthen click next to get started.',
    },
    manualProject: {
      title: 'Questionnaires',
      body: 'Below are questionnaires that you must complete for us to properly verify and facilitate your project with financing.',
      completeButton: 'Submit your project!',
      deleteButton: 'Delete your project',
      back: 'Your projects',
      inComplete: 'Your project is not yet complete.',
      yourDocuments: 'Your documents',
      askAi: 'Ask us',
      placeholder: 'What documents do you already have?',
    },
  },
  assessment: {
    back: 'Go back',
  },
  support: {
    title: 'Support',
    body: 'We are here to help!  Feel free to contact us by email or phone.',
    terms: 'Terms & Conditions',
    email: 'info@Baltare.com',
    chatbot: 'Chat with our bot',
  },
  footer: {
    terms: 'Terms & Conditions',
    privacy: 'Privacy Policy',
  },
  terms: {
    title: 'Terms & Conditions',
    body: 'Lorem ipsum dolar amit set large amount of copy here with many bullet points and various headings.  This will need to be customized for our needs and will need more work.',
  },
};
