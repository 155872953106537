import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import { NativeSyntheticEvent, TextInput, TextInputKeyPressEventData, TextInputSubmitEditingEventData } from 'react-native';
import Styles from '../shared/Styles';
import { Pressable, View } from '../shared/Themed';
type AutoCompleteType = 'off' | 'username' | 'password' | 'email' | 'name' | 'tel' | 'street-address' | 'postal-code' | 'cc-number' | 'cc-csc' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year';

interface InputProps {
  onChangeText: (text: string) => void;
  value: string;
  placeholder?: string;
  secureTextEntry?: boolean;
  disabled?: boolean;
  height?: number;
  theme?: string;
  style?: any;
  autoComplete?: AutoCompleteType;
  numberOfLines?: number;
  multiline?: boolean;
  placeholderTextColor?: string;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  onSubmitEditing?: (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void;
  onBlur?: (e: NativeSyntheticEvent<any>) => void;
  autoFocus?: boolean;
}
const Input = ({ onSubmitEditing, onChangeText, autoFocus, value, placeholder, height = 35, placeholderTextColor, secureTextEntry = false, disabled, style, theme, onKeyPress, autoComplete, multiline, numberOfLines, onBlur }: InputProps) => {
  const [isHidden, setIsHidden] = useState(true);
  const styles = [Styles.input, style];
  const fontStyles = [Styles.h5, { color: '#fff' }];
  const textareaRef = React.useRef<TextInput>(null);
  const [inputHeight, setInputHeight] = useState(height); // Step 1: State to hold the TextInput height
  if (disabled) {
    styles.push(Styles.inputDisabled);
  }
  if (theme == 'light') {
    styles.push(Styles.inputLight);
    styles.push({
      borderWidth: 1,
      borderColor: '#000',
    });
    fontStyles.push({
      color: '#000',
    });
  }
  const onToggleHidden = () => {
    setIsHidden(!isHidden);
  };

  // Step 2: Function to update the height based on content size
  const onContentSizeChange = e => {
    const newInputHeight = e.nativeEvent.contentSize.height;
    // if (newInputHeight !== inputHeight) {
    //   setInputHeight(newInputHeight);
    // }
  };

  useEffect(() => {
    if (autoFocus) {
      textareaRef.current?.focus();
    }
  }, []);

  return (
    <View
      style={{
        position: 'relative',
        justifyContent: 'center',
      }}
    >
      <TextInput
        onBlur={onBlur}
        onContentSizeChange={onContentSizeChange}
        style={[Styles.input, { height: Math.max(height, inputHeight) }]}
        ref={textareaRef}
        placeholder={placeholder}
        secureTextEntry={secureTextEntry && isHidden}
        onChangeText={onChangeText}
        value={value}
        autoComplete={autoComplete}
        onKeyPress={onKeyPress}
        multiline={multiline}
        numberOfLines={numberOfLines}
        placeholderTextColor={placeholderTextColor}
        onSubmitEditing={onSubmitEditing}
      />
      {secureTextEntry && (
        <Pressable
          onPress={onToggleHidden}
          disabled={disabled}
          style={{
            position: 'absolute',
            right: 20,
          }}
        >
          <Ionicons
            name={isHidden ? 'eye' : 'eye-off'}
            size={24}
            color="black"
          />
        </Pressable>
      )}
    </View>
  );
};

export default Input;
