import React from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';

type PdfViewerProps = {
  fileName: string;
};

const PdfViewer: React.FC<PdfViewerProps> = ({ fileName }) => {
  const s3Url = `https://banabanabucket9571c-dev.s3.us-east-1.amazonaws.com/${fileName}`;

  if (Platform.OS === 'web') {
    return (
      <View style={styles.container}>
        <iframe
          // src={s3Url}
          style={styles.iframe}
          src={`${s3Url}#toolbar=0&navpanes=0&scrollbar=0`}
          title="PDF Viewer"
        />
      </View>
    );
  }

  // Placeholder for other platforms (Android, iOS)
  return (
    <View style={styles.fallback}>
      <Text>PDF Viewer is not supported on this platform.</Text>
    </View>
  );
};

export default PdfViewer;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  fallback: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f8f8f8',
  },
});
