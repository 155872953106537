import { Amplify } from 'aws-amplify';
import { uploadData } from 'aws-amplify/storage';

export default async function UploadToS3({ path, data, options }: { path: string; data: any; options?: any }) {
  try {
    let contentType;
    if (path.includes('.pdf')) {
      contentType = 'application/pdf';
    }
    console.log('\n\nAmplify.getConfig', Amplify.getConfig());
    const result = await uploadData({
      path,
      data,
      options: {
        ...options,
        contentType,
        onProgress: ({ transferredBytes, totalBytes }) => {
          if (totalBytes) {
            console.log(`Upload progress ${Math.round(transferredBytes / totalBytes) * 100} %`);
          }
        },
      },
    }).result;
    console.log('Upload Success: ', result);
    return result;
  } catch (error) {
    console.log('Upload Error : ', path, error);
  }
}
