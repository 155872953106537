import { Ionicons, Octicons } from '@expo/vector-icons';
import CountdownTimer from '@src/components/Countdown';
import { TextBodyExtraBold, TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import useResponsive from '@src/hooks/useResponsive';
import { LoadingContext } from '@src/providers/Loading';
import { ModalContext } from '@src/providers/Modal';
import { chat } from '@src/utils/Chat';
import FormatDate from '@src/utils/FormatDate';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ActivityIndicator, ScrollView, View } from 'react-native';
import Markdown from 'react-native-markdown-display';
import Button from '../Button';
import Input from '../Input';
import PdfViewer from '../PDFViewer';
import Colors from '../shared/Colors';
import { Pressable } from '../shared/Themed';
import SourceMaterials from './SourceMaterials';

interface TaskModalProps {
  task: any;
  indexId?: string;
  namespace?: string;
  data?: any;
  role?: string | null | undefined;
}

const TaskModal = ({ task, indexId, namespace, data, role }: TaskModalProps) => {
  const { isWideScreen } = useResponsive();
  const { setModalContent } = useContext(ModalContext);
  const { checkLoading, addLoading, removeLoading } = useContext(LoadingContext);
  const [taskChat, setTaskChat] = useState<any[]>();
  const [reviseSummaryQuery, setReviseSummaryQuery] = useState<string>('');
  const [pdfFile, setPdfFile] = useState<string>();
  const scrollViewRef = useRef(null);

  const scrollToTop = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        y: 0, // Scroll to the top
        animated: false,
      });
    }
  };

  const onSubmitEditSummary = async () => {
    addLoading('onSubmitEditSummary');
    try {
      const { chatQueryAI, sourceMaterials } = await chatQuery();

      let newtaskChat: any[] = [];
      if (chatQueryAI && taskChat && sourceMaterials) {
        newtaskChat = [...taskChat, { question: reviseSummaryQuery, createdAt: new Date().toISOString(), messages: chatQueryAI, sourceMaterials }];
      } else if (chatQueryAI && sourceMaterials) {
        newtaskChat = [{ question: reviseSummaryQuery, createdAt: new Date().toISOString(), messages: chatQueryAI, sourceMaterials }];
      } else if (chatQueryAI) {
        newtaskChat = [{ question: reviseSummaryQuery, createdAt: new Date().toISOString(), messages: chatQueryAI }];
      }
      setTaskChat(newtaskChat);
    } catch (error) {
      console.log('onSubmitEditSummary error', error);
    } finally {
      removeLoading('onSubmitEditSummary');
    }
  };

  const closeModal = () => {
    setModalContent(undefined);
  };

  const chatQuery = async () => {
    const contextSource = {
      requestName: 'question',
      modelName: 'gpt-4o-mini',
      indexId,
      namespace,
      question: `${task.keywords}, ${reviseSummaryQuery}`,
    };

    const chatQueryAI = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `Review this context <context>{contextSource}</context>. Based on this query "${reviseSummaryQuery}" find an answer using step by step "chain of thought" and respond in json in an array where each index is a steps in chain of thought: {{ explain: "explanation and answer of the step" }}`,
      isJson: true,
    };

    return await chat({ 0: { contextSource }, 1: { chatQueryAI } });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const getColor = () => {
    if (Number(data.grade) >= 3) {
      return Colors.green;
    } else if (Number(data.grade) >= 1) {
      return Colors.orangePastel;
    } else {
      return Colors.red;
    }
  };

  const closePdf = () => {
    setPdfFile(undefined);
  };

  return (
    <View
      style={{
        position: 'absolute',
        left: isWideScreen ? '5%' : 0,
        top: isWideScreen ? '5%' : 0,
        bottom: isWideScreen ? '5%' : 0,
        width: isWideScreen ? '90%' : '100%',
        backgroundColor: pdfFile ? '#f9f9f9' : '#fff',
        borderRadius: 5,
      }}
    >
      {pdfFile && (
        <>
          <Pressable
            onPress={closePdf}
            style={{
              position: 'absolute',
              left: '-5%',
              top: '-5%',
              width: '110%',
              height: '110%',
              backgroundColor: '#000',
              opacity: 0.8,
              zIndex: 999,
            }}
          ></Pressable>
          <View
            style={{
              position: 'absolute',
              left: isWideScreen ? '10%' : 0,
              top: isWideScreen ? '10%' : 0,
              bottom: isWideScreen ? '10%' : 0,
              width: isWideScreen ? '80%' : '100%',
              backgroundColor: '#fff',
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#000',
              padding: 5,
              zIndex: 1000,
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 15,
              }}
            >
              <TextBodyMedium style={[Styles.h5, { padding: 15 }]}>{pdfFile}</TextBodyMedium>
              <Button
                onPress={closePdf}
                theme="primary small"
              >
                <Ionicons
                  name="close"
                  size={22}
                  color={'#fff'}
                />
              </Button>
            </View>
            <PdfViewer fileName={pdfFile} />
          </View>
        </>
      )}
      <View
        style={{
          backgroundColor: pdfFile ? '#f9f9f9' : '#fff',
          borderRadius: 5,
          height: '100%',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 30,
            paddingTop: 30,
            paddingBottom: 20,
          }}
        >
          <View
            style={{
              width: '65%',
            }}
          >
            <TextBodyMedium style={[Styles.h1, { marginBottom: 10 }]}>{task.title.charAt(0).toUpperCase() + task.title.slice(1)}</TextBodyMedium>
            {task.status && <TextBodyRegular style={[Styles.h6, {}]}>{`${task.status}`}</TextBodyRegular>}
          </View>
          <View
            style={{
              alignSelf: 'flex-start',
            }}
          >
            <Button
              theme="secondary small"
              onPress={closeModal}
              iconAfter="close"
            ></Button>
          </View>
        </View>
        {task.status ? (
          <View
            style={{
              borderBottomColor: !data ? 'transparent' : getColor(),
              borderBottomWidth: 5,
              borderTopColor: !data ? 'transparent' : getColor(),
              borderTopWidth: 5,
              backgroundColor: pdfFile ? '#f9f9f9' : '#fff',
              paddingHorizontal: 30,
              paddingVertical: 15,
            }}
          >
            <TextBodyRegular style={[Styles.h5, { marginBottom: 0, flexShrink: 1 }]}>{task.status}</TextBodyRegular>
          </View>
        ) : (
          <View
            style={{
              backgroundColor: !data ? 'transparent' : getColor(),
              width: 75,
              height: 75,
              borderRadius: 75,
              marginLeft: 30,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        )}
        <ScrollView
          ref={scrollViewRef}
          style={{
            backgroundColor: pdfFile ? '#f9f9f9' : '#fff',
          }}
        >
          <View
            style={{
              padding: 30,
            }}
          >
            <View
              style={
                {
                  // paddingTop: 20,
                }
              }
            >
              {checkLoading('chatQuery') && (
                <>
                  <View style={{ flexDirection: 'row', alignItems: 'center', margin: 20 }}>
                    <ActivityIndicator
                      size={20}
                      color="black"
                    />
                    <TextHeader style={[Styles.h4, { marginLeft: 10 }]}>Loading</TextHeader>
                  </View>
                  <TextBodyRegular style={[Styles.h7, { marginTop: 10, width: '45%' }]}>Please wait. This can take 10-30 seconds depending on the number of files.</TextBodyRegular>
                  <CountdownTimer
                    size={14}
                    initialTime={30}
                  />
                </>
              )}
              {!checkLoading('chatQuery') && (
                <>
                  {task && (
                    <View
                      style={{
                        width: '100%',
                      }}
                    >
                      <View
                        style={{
                          marginBottom: 20,
                          width: '100%',
                          paddingBottom: 15,
                          borderBottomWidth: 1,
                          borderBottomColor: '#000',
                        }}
                      >
                        <View
                          style={{
                            flexDirection: isWideScreen ? 'row' : 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            marginBottom: 10,
                          }}
                        >
                          <View
                            style={{
                              width: isWideScreen ? '70%' : '100%',
                              // paddingVertical: 10,
                              paddingRight: 30,
                              borderRightWidth: 0,
                              borderRightColor: '#eee',
                            }}
                          >
                            {task.summaryLong && (
                              <View
                                style={{
                                  paddingBottom: 15,
                                  marginBottom: 25,
                                  borderBottomWidth: !!task.grade && task.grade < 5 && task.approaches ? 1 : 0,
                                  borderBottomColor: '#eee',
                                }}
                              >
                                <Markdown style={Styles}>{task.summaryLong}</Markdown>
                              </View>
                            )}
                            {!!task.grade && task.grade < 5 && task.approaches && (
                              <View
                                style={{
                                  paddingBottom: 15,
                                  marginBottom: 15,
                                }}
                              >
                                <TextBodyMedium style={[Styles.h2, { marginBottom: 15 }]}>Approaches</TextBodyMedium>
                                <TextBodyRegular style={[Styles.h6, { marginBottom: 15 }]}>{task.approaches}</TextBodyRegular>
                              </View>
                            )}
                          </View>
                          <View
                            style={{
                              width: isWideScreen ? '30%' : '100%',
                              padding: 10,
                            }}
                          >
                            {!!task.grade && task.grade > 0 && task.sourceMaterials && (
                              <SourceMaterials
                                sourceMaterials={task.sourceMaterials}
                                setViewerFile={setPdfFile}
                              />
                            )}
                          </View>
                        </View>
                      </View>
                    </View>
                  )}

                  <View
                    style={{
                      marginTop: 15,
                      width: '70%',
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <View
                        style={{
                          // width: '80%',
                          flexGrow: 1,
                        }}
                      >
                        <Input
                          style={[Styles.input]}
                          placeholder={'What can we help you learn?'}
                          placeholderTextColor={'#999'}
                          height={50}
                          onChangeText={setReviseSummaryQuery}
                          value={reviseSummaryQuery}
                          onSubmitEditing={onSubmitEditSummary}
                        />
                      </View>
                      <Button
                        style={{}}
                        onPress={onSubmitEditSummary}
                        theme="primary"
                      >
                        <View style={{ flexDirection: 'row' }}>
                          <TextBodyRegular style={[Styles.h6, { color: '#fff' }]}>Submit</TextBodyRegular>
                        </View>
                      </Button>
                    </View>

                    {task && task.grade > 0 && task.sourceMaterials && task.queries && (
                      <View
                        style={{
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          marginTop: 20,
                        }}
                      >
                        {task.queries.split('||').map((query, index) => {
                          const newQuery = query.trim();
                          return (
                            <Button
                              key={`query-${index}`}
                              theme="secondary small"
                              style={{
                                marginBottom: 10,
                                marginRight: 10,
                              }}
                              onPress={() => {
                                setReviseSummaryQuery(newQuery);
                              }}
                            >
                              <TextBodyRegular style={[Styles.h7, { color: '#fff' }]}>{newQuery}</TextBodyRegular>
                            </Button>
                          );
                        })}
                      </View>
                    )}
                  </View>
                  <TextBodyRegular style={[Styles.h8, { marginLeft: 5, marginBottom: 15 }]}>If you close this modal, your chat will disappear.</TextBodyRegular>

                  {checkLoading('onSubmitEditSummary') && (
                    <View style={{ paddingHorizontal: 5, marginBottom: 10, padding: 10, borderBottomWidth: taskChat?.length ? 1 : 0, borderBottomColor: '#000' }}>
                      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 0 }}>
                        <TextBodyRegular style={[Styles.h4, { marginRight: 20 }]}>Loading</TextBodyRegular>
                        <ActivityIndicator
                          size={20}
                          color="black"
                        />
                      </View>
                      <TextBodyRegular style={[Styles.h7, { marginTop: 10, width: '50%' }]}>Please wait. This can take 10 seconds depending on the complexity of the question.</TextBodyRegular>
                      <CountdownTimer
                        size={14}
                        initialTime={10}
                      />
                    </View>
                  )}
                  {taskChat && (
                    <>
                      {taskChat &&
                        taskChat.reverse().map((taskMsg, i) => {
                          return (
                            <View
                              key={`taskMsg-message-${i}`}
                              style={{
                                borderBottomWidth: 1,
                                borderBottomColor: '#000',
                                paddingBottom: 20,
                                marginBottom: 20,
                              }}
                            >
                              <View style={{ flexDirection: 'row', marginTop: 10, marginBottom: 5 }}>
                                <TextBodyMedium style={[Styles.h4, {}]}>{`You asked:`}</TextBodyMedium>
                                <TextBodyExtraBold style={[Styles.h4, { marginLeft: 5, fontStyle: 'italic' }]}>{`"${taskMsg.question}"`}</TextBodyExtraBold>
                              </View>
                              <TextBodyMedium style={[Styles.h7, { marginBottom: 20 }]}>{`${FormatDate(taskMsg.createdAt)}`}</TextBodyMedium>
                              <View
                                style={{
                                  flexDirection: 'row',
                                }}
                              >
                                <View style={{ width: isWideScreen ? '70%' : '100%', paddingRight: isWideScreen ? 20 : 0 }}>
                                  {taskMsg &&
                                    taskMsg.messages &&
                                    taskMsg.messages.map((msg: any, j: number) => {
                                      return (
                                        <View
                                          key={`task-msg-${j}`}
                                          style={{ flexDirection: 'row', alignItems: 'flex-start', marginBottom: 10 }}
                                        >
                                          <Octicons
                                            name="dot-fill"
                                            size={16}
                                            color="black"
                                          />
                                          <TextBodyMedium style={[Styles.h5, { marginLeft: 10 }]}>{msg.explain}</TextBodyMedium>
                                        </View>
                                      );
                                    })}
                                </View>
                                <View style={{ width: isWideScreen ? '30%' : '100%' }}>
                                  {taskMsg.sourceMaterials && (
                                    <SourceMaterials
                                      sourceMaterials={Object.values(taskMsg.sourceMaterials).map(item => item)}
                                      setViewerFile={setPdfFile}
                                    />
                                  )}
                                </View>
                              </View>
                            </View>
                          );
                        })}
                    </>
                  )}
                </>
              )}
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default TaskModal;
