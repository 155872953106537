export default async function (fileData: string): Promise<string> {
  // Convert the extracted string data to Base64 using FileReader
  return await new Promise<string>((resolve, reject) => {
    const blob = new Blob([fileData], { type: 'image/jpeg' }); // Adjust MIME type as needed
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error('Failed to convert to Base64'));
    reader.readAsDataURL(blob);
  });
}

// convert any file to base64
export const convertFileToBase64 = async (file: File): Promise<string> => {
  // alternative:
  // const imageData = await s3StreamImage('banabanabucket9571c-dev', fileName);
  // if (!imageData) return;

  const base64File: string = await new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = () => {
        reject(new Error('Failed to convert file to base64'));
      };
      reader.readAsDataURL(file);
    } catch (err) {
      console.log('convertFileToBase64 error', err);
    }
  });
  return base64File;
  // return new Uint8Array(await (await fetch(base64File)).arrayBuffer());
};
