export default (timestamp: string | Number) => {
  let date;
  if (typeof timestamp === 'string') {
    date = new Date(timestamp);
  } else {
    date = new Date(Number(timestamp));
  }

  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')} on ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};
