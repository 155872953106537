import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type SvgLogo = {
  width: number;
  height: number;
  style?: any;
  color?: string;
};
export default function SvgLogoIcon({ width, height, style, color }: SvgLogo) {
  return (
    <Svg
      width={width}
      height={height}
      style={style}
      fill={color}
      viewBox="0 0 1226.08 865.47"
    >
      <Path d="M1053.12,606.57c-44.81,0-89.67-1.37-134.4,.52-25.99,1.1-36.73-5.77-41.08-33.66-30.84-197.97-245.54-294.02-412.87-184.93-64.97,42.36-103.75,103.78-114.7,180.75-3.61,25.42-13.42,33.1-38.84,32.56-91.72-1.92-183.5-2.7-275.22-1.26-30.99,.49-37.45-10.6-35.77-39.17C16.15,290.38,237.04,49.3,508.68,7.86c296.87-45.29,573.4,108.16,676.88,380.85,21.9,57.72,35.44,118.53,40.44,183.53,1,26-7.36,36.21-35,35-45.79-2.01-92-.36-137.88-.36v-.31Z" />
      <Path d="M828.18,613.85c39.44,.29,43.53,6.25,35.92,46.12-19.72,103.39-100.26,182.12-206.22,201.58-99.25,18.22-206.15-28.12-257.49-116.84-18.2-31.45-27.21-68.63-37.67-104-6.17-20.89,4.2-30.11,27.49-29.7,73.51,1.29,147.05,.48,220.58,.48" />
    </Svg>
  );
}
