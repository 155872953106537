import Button from '@src/components/Button';
import Input from '@src/components/Input';
import Colors from '@src/components/shared/Colors';
import { TextInterRegular, TextInterSemiBold } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import useResponsive from '@src/hooks/useResponsive';
import { AlignmentContext } from '@src/providers/Alignment';
import React, { useContext, useState } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import SaveEditableInputModal from './SaveEditableInputModal';

interface DeliverableLLMItemProps {
  task: any;
  index: number;
}

const DeliverableLLMItem = ({ task, index }: DeliverableLLMItemProps) => {
  const { isWideScreen } = useResponsive();
  const { alignmentState, setAlignmentState } = useContext(AlignmentContext);
  const { title } = task;
  const value = title;
  const [inputValue, setInputValue] = useState<string>(value);
  const [state, setState] = useState<string | undefined>(task?.title === '' ? 'edit' : undefined);

  const onKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.shiftKey && e.key === 'Enter') {
      // Create a new line
    } else if (e.key === 'Enter') {
      // Trigger the same function as onSave button
      onSave();
    }
  };

  const updateTask = (obj: any) => {
    if (alignmentState && alignmentState.deliverablesLLM) {
      setAlignmentState(prevState => {
        if (prevState && prevState.deliverablesLLM) {
          const newDeliverablesLLM = prevState.deliverablesLLM;
          newDeliverablesLLM[index] = { ...newDeliverablesLLM[index], ...obj };
          const newAlignmentState = { ...prevState, deliverablesLLM: newDeliverablesLLM };
          return newAlignmentState;
        } else {
          return prevState;
        }
      });
    }
  };

  const onSave = () => {
    if (inputValue !== '') {
      updateTask({ title: inputValue });
      setState(undefined);
    }
  };

  const onCancel = () => {
    updateTask({ title: value });
    setInputValue(value);
    setState(undefined);
  };

  const onDelete = () => {
    if (alignmentState && alignmentState.deliverablesLLM) {
      setAlignmentState(prevState => {
        if (prevState && prevState.deliverablesLLM) {
          const newDeliverablesLLM = prevState.deliverablesLLM.filter((_, i) => i !== index);
          const newAlignmentState = { ...prevState, deliverablesLLM: newDeliverablesLLM };
          return newAlignmentState;
        } else {
          return prevState;
        }
      });
    }
    // setInputValue('');
    setState(undefined);
  };

  return (
    <View
      style={{
        backgroundColor: Colors.buttonBackground,
        flexDirection: isWideScreen ? 'row' : 'column',
        alignItems: isWideScreen ? 'center' : 'flex-start',
        justifyContent: 'space-between',
        borderRadius: 5,
        padding: 15,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          width: '75%',
        }}
      >
        <View
          style={{
            backgroundColor: '#fff',
            width: 30,
            height: 30,
            marginRight: 15,
            borderRadius: 30,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextInterRegular style={[Styles.h7]}>{index + 1}</TextInterRegular>
        </View>
        <View style={{ flexDirection: 'column', width: '100%' }}>
          {state === 'edit' ? (
            <Input
              // style={[Styles.h6]}
              onChangeText={setInputValue}
              autoFocus={true}
              value={inputValue}
              // numberOfLines={1}
              multiline={true}
              onKeyPress={onKeyPress}
              height={isWideScreen ? 50 : 50}
            />
          ) : (
            <TextInterSemiBold style={[Styles.h4, { color: '#fff' }]}>{value}</TextInterSemiBold>
          )}
        </View>
      </View>
      {state === 'edit' ? (
        <View
          style={{
            justifyContent: 'center',
            alignSelf: 'flex-end',
            marginTop: isWideScreen ? 0 : 15,
            flexDirection: isWideScreen ? 'column' : 'row',
          }}
        >
          {task.title !== '' && (
            <Button
              theme="primary small"
              onPress={onCancel}
            >
              Cancel
            </Button>
          )}
          <Button
            theme="primary small"
            id={'update-other-areas'}
            onPress={onSave}
          >
            Save
          </Button>
          <Button
            theme="primary small"
            id={'Are-you-sure'}
            modal={
              <SaveEditableInputModal
                id={'Are-you-sure'}
                label="Are you sure?"
                onYes={onDelete}
                onNo={() => setState(undefined)}
              />
            }
          >
            Delete
          </Button>
        </View>
      ) : (
        <View
          style={{
            alignSelf: 'flex-end',
          }}
        >
          <Button
            theme="primary"
            onPress={() => {
              setState('edit');
            }}
          >
            Edit
          </Button>
        </View>
      )}
    </View>
  );
};

export default DeliverableLLMItem;
