import { getFocusedRouteNameFromRoute, useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useResponsive from '@src/hooks/useResponsive';
import { AlignmentContext } from '@src/providers/Alignment';
import React, { useContext } from 'react';
import { Linking, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Button from '../Button';
import SvgLogo from '../Logo/SvgLogo';
import Colors from '../shared/Colors';

const TopBarWeAlign = () => {
  const { isWideScreen } = useResponsive();
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { setAlignmentState } = useContext(AlignmentContext);
  const route = useRoute();
  const currentRouteName = getFocusedRouteNameFromRoute(route) || 'Home'; // Default to 'Home' if no route found

  const gotoCreate = () => {
    setAlignmentState({});
    navigation.navigate('wealign', { screen: 'create' });
  };
  const gotoWebsite = () => {
    Linking.openURL('https://www.wealign.ai');
  };
  const gotoHelp = () => {
    navigation.navigate('wealign', { screen: 'help' });
  };

  return (
    <View
      style={{
        zIndex: 1,
        justifyContent: 'center',
        height: isWideScreen ? 90 : 60,
        backgroundColor: '#fff',
        padding: '3%',
        alignItems: 'center',
        borderBottomWidth: 2,
        borderBottomColor: Colors.buttonBackground,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '95%',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity
            onPress={gotoWebsite}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: 15,
            }}
          >
            <SvgLogo
              width={isWideScreen ? 120 : 100}
              color={Colors.primary}
            />
          </TouchableOpacity>
        </View>

        {currentRouteName !== 'create' && (
          <Button
            onPress={gotoCreate}
            theme={isWideScreen ? 'primary' : 'primary small'}
            iconAfter="add"
          >
            Create
          </Button>
        )}

        {currentRouteName === 'create' && (
          <Button
            onPress={gotoHelp}
            theme="tertiary"
          >
            Help
          </Button>
        )}
      </View>
    </View>
  );
};

export default TopBarWeAlign;
