import * as Localisation from 'expo-localization';
import { I18n } from 'i18n-js';
import enUS from './enUS';
import esES from './esES';
import frFR from './frFR';

const i18n = new I18n({
  en: enUS,
  'en-US': enUS,
  'fr-FR': frFR,
  'es-ES': esES,
});

setLocale(Localisation.locale);

export function setLocale(locale: string) {
  i18n.locale = locale;
}
export function getLocale() {
  return i18n.locale;
}
export function getTranslation(key: string, context = {}) {
  return i18n.t(key, context);
}
export default i18n;
