import { Ionicons } from '@expo/vector-icons';
import CountdownTimer from '@src/components/Countdown';
import Colors from '@src/components/shared/Colors';
import { TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import React, { useContext } from 'react';
import { ActivityIndicator } from 'react-native';

interface SourcesListProps {
  fileNames?: string[];
  materialFileNames?: string[];
  indexId: string;
  namespace: string;
  filesUploaded?: () => void;
}

const SourcesList: React.FC<SourcesListProps> = ({ fileNames, indexId, namespace, filesUploaded }: SourcesListProps) => {
  const { checkLoading, addLoading, removeLoading } = useContext(LoadingContext);
  const [materialFileNames, setMaterialFileNames] = React.useState<string[]>([]);

  const handleFileUpload = async (files: FileList | null) => {
    if (files) {
      addLoading('uploadFileToSource');
      // create pinecone index for this user.  each user should have only 1 index associated with them, where each index includes namespaces that are associated with projects
      // we need to get the indexId and namespace
      try {
        console.log('source material file upload', files, indexId, namespace);
        await uploadFiles({ files, indexId, namespace });
        const fileNames = Array.from(files).map(file => file.name);
        setMaterialFileNames(fileNames);
        filesUploaded && filesUploaded();
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        removeLoading('uploadFileToSource');
      }
    }
  };

  return (
    <View
      style={{
        padding: '3%',
        marginVertical: 30,
        paddingBottom: 40,
        backgroundColor: '#fff',
        width: '25%',
        borderRightColor: '#eee',
        borderRightWidth: 20,
      }}
    >
      <TextHeader style={[Styles.h3, { marginBottom: 10, color: Colors.buttonBackground }]}>Uploaded files</TextHeader>
      <TextBodyRegular style={[Styles.h6, { marginBottom: 30 }]}>These are the files that you have uploaded thus far.</TextBodyRegular>
      {fileNames && (
        <View style={{ paddingVertical: 20, borderTopWidth: 1, borderTopColor: '#eee' }}>
          <TextHeader style={[Styles.h5, { marginBottom: 10 }]}>{`Task list discovery (${fileNames.length})`}</TextHeader>
          <TextBodyRegular style={[Styles.h8, { marginBottom: 10 }]}>These are the files that were used to generated your task list.</TextBodyRegular>
          {fileNames.map((file, index) => (
            <View
              key={`file-${index}`}
              style={{
                paddingVertical: 10,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <TextBodyRegular style={[Styles.h7, { marginRight: 10 }]}>{`${index + 1}: ${file}`}</TextBodyRegular>
              <Ionicons
                name="document"
                size={22}
                color="black"
              />
            </View>
          ))}
        </View>
      )}
      {!checkLoading('uploadFileToSource') && materialFileNames && (
        <View style={{ borderTopWidth: 1, borderTopColor: '#eee', paddingTop: 20 }}>
          <TextHeader style={[Styles.h5, { marginBottom: 10 }]}>{`Source materials (${materialFileNames.length})`}</TextHeader>
          <TextBodyRegular style={[Styles.h7, { marginBottom: 30 }]}>These files are used to advise our analysis of the listed tasks.</TextBodyRegular>
          {materialFileNames.map((file, index) => (
            <View
              key={`file-${index}`}
              style={{
                borderBottomColor: '#eee',
                borderBottomWidth: 1,
                paddingVertical: 10,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <TextBodyRegular style={[Styles.h7, { marginRight: 10 }]}>{`${index + 1}: ${file}`}</TextBodyRegular>
              <Ionicons
                name="document"
                size={22}
                color="black"
              />
            </View>
          ))}
        </View>
      )}
      {checkLoading('uploadFileToSource') && (
        <View style={{ marginBottom: 30 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
            <TextHeader style={[Styles.h4, { marginRight: 20 }]}>Uploading</TextHeader>
            <ActivityIndicator
              size={20}
              color="black"
            />
          </View>
          <TextBodyRegular style={[Styles.h7, { marginTop: 10, width: '50%' }]}>Please wait. This can take 60-120 seconds depending on the number of files.</TextBodyRegular>
          <CountdownTimer
            size={14}
            initialTime={120}
          />
        </View>
      )}
      {!checkLoading('uploadFileToSource') && (
        <View
          style={{
            marginVertical: 20,
          }}
        >
          <UploadDocumentButton onPress={handleFileUpload} />
        </View>
      )}
    </View>
  );
};

export default SourcesList;
