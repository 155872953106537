import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type SvgLogo = {
  width: number;
  height?: number;
  style?: any;
  color?: string;
};
export default function SvgLogo({ width, height, style, color }: SvgLogo) {
  return (
    <Svg
      width={width}
      height={height}
      style={style}
      fill={color}
      viewBox="0 0 1024 400"
    >
      <Path
        d="
 M284.634338,88.261436 
   C292.019073,88.224121 298.978699,88.224121 307.270020,88.224121 
   C302.881256,101.519806 298.919067,113.569992 294.925110,125.609642 
   C290.903992,137.731201 286.851288,149.842285 282.718811,162.238693 
   C288.588593,163.752258 293.822998,161.976105 299.095367,162.413879 
   C328.529938,164.857925 346.932526,181.164413 357.240387,207.864853 
   C360.959198,217.497772 361.662170,227.587341 361.317413,237.838272 
   C361.172211,242.156601 359.431641,243.500549 355.196716,243.478287 
   C327.371979,243.331909 299.546112,243.390533 271.720612,243.409744 
   C269.776917,243.411087 267.748016,243.024445 265.419525,244.290161 
   C267.598114,253.430969 272.853149,260.197205 280.820557,264.679230 
   C293.814667,271.989014 308.581421,269.205078 318.703491,257.444305 
   C321.404419,254.306122 323.740814,253.483765 327.671661,254.577667 
   C334.875916,256.582489 342.246979,257.983978 349.534241,259.697510 
   C351.258514,260.102997 352.926361,260.748169 354.512146,261.248993 
   C354.616608,264.486786 352.820526,266.409149 351.599701,268.506683 
   C335.120392,296.820068 302.849640,308.969177 272.363342,297.166412 
   C249.369659,288.264465 236.071823,270.348145 230.787811,246.467972 
   C229.277252,239.641251 228.784744,232.669067 229.615921,225.655807 
   C230.133591,221.287888 229.369507,217.143494 227.930664,212.934937 
   C219.799500,189.151596 211.875046,165.297592 203.870621,141.470871 
   C203.559845,140.545792 203.151672,139.653412 202.284149,137.483093 
   C198.684128,148.119629 195.428253,157.639389 192.239960,167.181702 
   C186.806824,183.442581 181.313278,199.684677 176.083237,216.010956 
   C174.845947,219.873367 172.834747,221.249451 168.860260,221.097244 
   C161.705505,220.823227 154.528366,220.819229 147.371292,221.043854 
   C143.414536,221.168045 141.407288,219.769760 140.135803,215.922348 
   C128.750412,181.470993 117.188126,147.078110 105.677673,112.668106 
   C103.618568,106.512489 101.550682,100.359779 99.504372,94.199913 
   C98.987595,92.644279 98.155716,91.123154 99.017769,88.684189 
   C108.540474,88.684189 118.311195,88.591431 128.078308,88.738503 
   C131.166214,88.784996 131.253540,91.810654 131.955536,93.893066 
   C139.771423,117.077881 147.510803,140.288483 155.288574,163.486176 
   C156.066605,165.806702 156.961334,168.088120 158.312180,171.783615 
   C163.059921,157.587082 167.363647,144.724243 171.663284,131.860031 
   C175.989365,118.916695 180.392502,105.998123 184.577225,93.009216 
   C185.601837,89.828972 187.150635,88.469078 190.611557,88.514862 
   C198.101074,88.613930 205.601212,88.415497 213.086807,88.098076 
   C216.975479,87.933182 219.020538,89.258163 220.294769,93.157021 
   C228.098175,117.033562 236.160797,140.825378 244.134003,164.646439 
   C244.705292,166.353241 244.932541,168.220612 246.621414,169.732086 
   C248.473068,167.818069 248.899750,165.318924 249.668564,163.047302 
   C256.978790,141.447754 264.228455,119.827744 271.507751,98.217705 
   C274.602356,89.030838 274.615479,89.035263 284.634338,88.261436 
 M272.636932,218.645477 
   C288.778717,218.646011 304.920502,218.665833 321.062225,218.636978 
   C327.001556,218.626358 327.486450,217.830887 325.248322,212.247406 
   C323.092010,206.868011 319.654449,202.398422 315.189728,198.794403 
   C305.780304,191.198944 295.304169,188.829529 283.943298,193.656799 
   C273.314056,198.173203 266.952087,206.355606 264.458191,217.611526 
   C267.056030,219.245834 269.445129,218.375610 272.636932,218.645477 
 z"
      />
      <Path
        d="
 M752.862732,363.210449 
   C729.697632,366.051392 709.932068,359.658875 693.194275,344.056366 
   C686.919128,338.206848 681.998779,331.330170 678.219543,323.606476 
   C676.932129,320.975494 677.157959,319.472748 680.077026,318.210205 
   C687.719910,314.904510 695.277100,311.394196 702.801575,307.824615 
   C705.400574,306.591644 706.897156,306.831848 708.323486,309.623291 
   C721.784668,335.967651 753.390259,335.296295 771.425171,319.587830 
   C776.806213,314.900909 779.713867,308.812439 779.750488,301.541565 
   C779.777222,296.240967 779.755798,290.940094 779.755798,285.801697 
   C778.371643,285.111206 777.822876,285.968262 777.325500,286.516022 
   C764.009094,301.181732 747.285400,303.984863 728.861084,300.135620 
   C696.453796,293.365051 673.956909,263.483124 674.747253,228.593079 
   C675.457214,197.252762 700.346863,168.911102 731.780579,163.541702 
   C749.155457,160.573746 764.563782,163.902817 777.003479,177.194855 
   C777.550171,177.778946 778.226685,178.241470 779.852051,179.611206 
   C779.852051,176.070328 779.994385,173.496063 779.819092,170.943588 
   C779.547180,166.982635 781.352661,165.669846 785.163025,165.757294 
   C792.658752,165.929337 800.168396,165.980530 807.658813,165.708344 
   C812.167664,165.544525 813.430176,167.469177 813.328125,171.650909 
   C813.125122,179.979065 813.276855,188.315811 813.276733,196.649002 
   C813.276184,229.481812 812.659363,262.329163 813.435669,295.143555 
   C814.298523,331.617584 789.906494,355.017792 758.703003,362.153748 
   C756.919312,362.561646 755.104675,362.833923 752.862732,363.210449 
 M731.319214,196.727386 
   C711.617554,206.215469 703.434631,227.368347 711.652405,247.566040 
   C720.755493,269.939697 747.947327,276.954376 765.673096,261.268097 
   C775.190247,252.845963 778.252563,241.759415 777.790833,229.276184 
   C776.999023,207.875214 757.244507,187.584229 731.319214,196.727386 
 z"
      />
      <Path
        d="
 M378.030579,298.303680 
   C373.942657,298.026703 370.252472,298.942139 366.786499,297.630798 
   C365.981995,295.336395 367.105469,293.517944 367.765961,291.700012 
   C388.743347,233.959320 409.787750,176.242966 430.705841,118.480858 
   C431.966583,114.999527 433.600861,113.443306 437.475586,113.550301 
   C446.800323,113.807816 456.140533,113.773727 465.467255,113.552338 
   C469.053802,113.467201 470.812988,114.729202 472.029663,118.103683 
   C487.726898,161.640167 503.543610,205.133575 519.339111,248.634613 
   C524.623596,263.188202 529.959045,277.723389 535.196716,292.293762 
   C535.792786,293.952026 536.850952,295.643127 535.765869,298.217865 
   C525.586853,298.217865 515.143372,298.232269 504.700043,298.207794 
   C502.065399,298.201630 501.609589,295.956573 500.932770,294.148621 
   C497.955414,286.195465 494.948425,278.249481 492.206085,270.214264 
   C491.108795,266.999115 489.290161,265.846558 485.954285,265.856628 
   C462.625122,265.927246 439.295227,265.911041 415.966553,265.765533 
   C412.949860,265.746735 411.951965,267.202606 411.092865,269.637085 
   C408.267365,277.643738 405.104645,285.535919 402.455414,293.598206 
   C401.245758,297.279602 399.258606,298.534882 395.518005,298.369781 
   C389.863281,298.120148 384.189453,298.303833 378.030579,298.303680 
 M461.603973,186.809219 
   C458.394012,178.206284 455.184052,169.603348 451.974091,161.000412 
   C451.482574,161.034790 450.991028,161.069183 450.499512,161.103561 
   C442.000183,184.571793 433.500854,208.040024 424.759613,232.176224 
   C442.980865,232.176224 460.096191,232.176224 478.175598,232.176224 
   C472.545227,216.804016 467.188721,202.179596 461.603973,186.809219 
 z"
      />
      <Path
        d="
 M931.108826,165.654297 
   C952.830688,175.355438 964.579285,191.746231 965.009033,215.225464 
   C965.481750,241.048950 965.038086,266.888275 965.175537,292.719696 
   C965.199280,297.171265 963.495605,298.524902 959.243530,298.369415 
   C952.086548,298.107635 944.903442,298.059448 937.750549,298.367493 
   C933.113037,298.567291 931.691589,296.855988 931.720886,292.316040 
   C931.870056,269.151520 931.813843,245.985092 931.713928,222.819839 
   C931.666077,211.733612 926.286743,203.703659 916.519470,198.878616 
   C906.974548,194.163391 897.401428,194.664627 888.585938,200.838486 
   C881.367554,205.893814 877.190918,212.847687 877.184692,221.932907 
   C877.169678,243.931625 877.135071,265.930359 877.133911,287.929077 
   C877.133301,299.888245 877.442017,298.207153 867.021729,298.293610 
   C861.022400,298.343384 855.018311,298.160706 849.024048,298.339935 
   C845.283997,298.451782 843.617004,297.270599 843.626221,293.234406 
   C843.719849,252.403030 843.709839,211.571274 843.626404,170.739853 
   C843.618896,167.081436 844.921570,165.654541 848.582642,165.737427 
   C855.578735,165.895813 862.587585,165.962509 869.578308,165.711594 
   C874.107788,165.549042 877.202087,166.302063 876.465271,171.833969 
   C876.314636,172.964996 876.558044,174.175140 878.215881,175.102020 
   C893.778748,162.372269 911.333923,158.707870 931.108826,165.654297 
 z"
      />
      <Path
        d="
 M591.349304,185.000092 
   C591.348206,220.964172 591.254578,256.428741 591.450745,291.891724 
   C591.478394,296.878021 590.173279,298.711639 585.031494,298.407379 
   C578.062683,297.994995 571.045410,298.117188 564.061157,298.361420 
   C559.986145,298.503876 558.518372,297.149719 558.579041,292.967529 
   C558.784485,278.818146 558.661377,264.664001 558.661438,250.511673 
   C558.661804,202.726761 558.659790,154.941833 558.662170,107.156921 
   C558.662476,100.194336 558.713928,100.108368 565.527100,100.057655 
   C572.353088,100.006844 579.184204,100.198456 586.004517,99.996933 
   C590.137634,99.874809 591.441223,101.499336 591.419617,105.580551 
   C591.280212,131.886444 591.348755,158.193451 591.349304,185.000092 
 z"
      />
      <Path
        d="
 M655.252808,184.001312 
   C655.260437,220.139420 655.276917,255.777908 655.268799,291.416412 
   C655.267273,298.277069 655.222778,298.286072 648.289551,298.292877 
   C641.128540,298.299896 633.964478,298.161224 626.807800,298.334869 
   C623.123718,298.424255 621.795410,296.951447 621.800171,293.342834 
   C621.853638,252.375305 621.844910,211.407608 621.779480,170.440079 
   C621.773987,166.998276 623.146362,165.680023 626.540649,165.733826 
   C634.532410,165.860504 642.528259,165.860413 650.520569,165.761658 
   C653.913818,165.719742 655.381714,167.095459 655.278870,170.513168 
   C655.148682,174.839020 655.251099,179.171890 655.252808,184.001312 
 z"
      />
      <Path
        d="
 M655.397949,134.658813 
   C654.276733,143.863342 647.715027,149.893280 639.003723,150.102798 
   C630.983521,150.295700 623.287781,144.318130 621.874329,136.797745 
   C620.216919,127.979912 624.599121,120.068481 632.708679,117.237656 
   C640.895508,114.379860 649.720581,117.715088 653.746704,125.065628 
   C655.346985,127.987297 655.477295,131.086761 655.397949,134.658813 
 z"
      />
    </Svg>
  );
}
