import { useRoute } from '@react-navigation/native';
import { vectorStore } from '@src/components/ADT/MagicTable';
import Colors from '@src/components/shared/Colors';
import { TextHeader, TextInterRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import TaskModal from '@src/components/TaskList/TaskModal';
import { AlignmentContext } from '@src/providers/Alignment';
import { LoadingContext } from '@src/providers/Loading';
import { ModalContext } from '@src/providers/Modal';
import { chat } from '@src/utils/Chat';
import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';

interface AlignmentItemProps {
  task: any;
  index: number;
  vectorStore: vectorStore;
  role: string | null | undefined;
  tasks: any[];
}

const AlignmentItem = ({ task, index, vectorStore, role, tasks }: AlignmentItemProps) => {
  const route = useRoute();
  const id = route?.params?.id;
  const [data, setData] = useState<any | undefined>(task);
  const [isLoading, setIsLoading] = useState(false);
  const { loading, checkLoading, addLoading, removeLoading } = useContext(LoadingContext);
  const { shouldUpdate, setShouldUpdate, updateAlignmentDeliverablesLLM } = useContext(AlignmentContext);
  const [onHover, setOnHover] = useState(false);
  const { setModalContent } = useContext(ModalContext);

  const onPress = () => {
    // todo we need to run some queries while this modal is open.
    setModalContent(
      <TaskModal
        task={task}
        data={data}
        indexId={vectorStore.indexId}
        namespace={vectorStore.namespace}
        role={role}
      />,
    );
  };

  const handleHoverIn = () => {
    setOnHover(true);
  };
  const handleHoverOut = () => {
    setOnHover(false);
  };

  const generateTaskDetails = async () => {
    const contextSource = {
      requestName: 'question',
      modelName: 'gpt-4o-mini',
      indexId: vectorStore.indexId,
      namespace: vectorStore.namespace,
      question: task.keywords,
    };

    const taskDetailsLLM = {
      modelName: 'gpt-4o-mini',
      templateWrapper: `Review this context <context>{contextSource}</context>.  Determine if this context satisfies what is being asked:<ask>"${task.title}"</ask>. Your response must be formated in json like this: {{ summaryLong: "In depth summary of how the <context> relates to <ask> with specific details and facts in an outline format with headings, subheadings, and lists as needed.  Include any values or amounts and show any algorithms used. In markdown. Do not include any brackets [ or ].", approaches: "if parts of the context are not satisfied, which parts are missing and would you get the information about that part? in markdown.", grade: "An integer between 0 to 3 of how satisfied is the <context> in relation to the <ask>, with 5 being very satisfied.", status: "respond 20 word summary how the <context> satisfies the "${task.title}" otherwise say there is no information"}}.`,
      isJson: true,
    };

    return await chat({ 0: { contextSource }, 1: { taskDetailsLLM } });
  };

  const load = async (forceUpdate: boolean = false) => {
    try {
      setIsLoading(true);
      // only update the task details if new files were added
      if (shouldUpdate || forceUpdate) {
        const res = await generateTaskDetails();
        const { taskDetailsLLM, sourceMaterials: resSourceMaterials } = res;
        if (taskDetailsLLM) {
          // display the results
          setData(taskDetailsLLM);
          let requestItem = taskDetailsLLM;
          if (resSourceMaterials) {
            const resSourceMaterialsArr = Object.values(resSourceMaterials).map(item => item);
            if (resSourceMaterialsArr.length > 0) {
              requestItem = { ...requestItem, sourceMaterials: resSourceMaterialsArr };
            }
          }
          setShouldUpdate(false);
          // save the results to the db
          updateAlignmentDeliverablesLLM(id, requestItem, index);
        }
      } else {
        // show the preloaded data
        setData(task);
      }
    } catch (error) {
      console.log('load task modal error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getColor = () => {
    if (Number(data.grade) >= 3) {
      return Colors.green;
    } else if (Number(data.grade) >= 1) {
      return Colors.orangePastel;
    } else {
      return Colors.red;
    }
  };

  useEffect(() => {
    load();
  }, []);

  const showLoader = isLoading || loading;

  return (
    <Pressable
      onPress={onPress}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
      style={{
        borderRadius: 5,
        backgroundColor: Colors.primary,
        borderWidth: 2,
        borderColor: onHover ? Colors.primary : '#fff',
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: 100,
        }}
      >
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            padding: 20,
            width: '80%',
          }}
        >
          <View
            style={{
              backgroundColor: '#fff',
              width: 30,
              height: 30,
              marginRight: 20,
              borderRadius: 30,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextInterRegular style={[Styles.h7]}>{index + 1}</TextInterRegular>
          </View>
          <View
            style={{
              width: '100%',
              flex: 1,
            }}
          >
            <TextHeader style={[Styles.h5, { color: '#fff' }]}>{task.title.charAt(0).toUpperCase() + task.title.slice(1)}</TextHeader>
            {data && data.status && <TextInterRegular style={[Styles.h6, { color: '#fff', marginTop: 10, width: '70%' }]}>{data.status}</TextInterRegular>}
          </View>
        </View>
        <Pressable
          disabled={true}
          onPress={() => load(true)}
          style={{
            // flex: 1, // This allows the Pressable to fill the height of the parent
            width: 50,
            height: 50,
            borderRadius: 50,
            marginRight: 30,
            backgroundColor: getColor(),
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {showLoader && (
            <ActivityIndicator
              size={22}
              color={'white'}
            />
          )}
        </Pressable>
      </View>
    </Pressable>
  );
};

export default AlignmentItem;
