import React, { useRef } from 'react';
import { Pressable } from './shared/Themed';

interface UploadDocumentProps {
  onPress?: (event: FileList | null) => void;
  children: JSX.Element;
}

const UploadDocument = ({ onPress, children }: UploadDocumentProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleOnPress = () => {
    if (fileInputRef.current !== null) {
      fileInputRef.current?.click();
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      try {
        if (onPress) onPress(event.target.files);
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
      }
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        multiple
      />
      <Pressable onPress={handleOnPress}>{children}</Pressable>
    </>
  );
};

export default UploadDocument;
