import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import useResponsive from '@src/hooks/useResponsive';
import { getTranslation } from '@src/services/i18n';
import React from 'react';
import { View } from 'react-native';
import Button from '../Button';
import { TextBodyMedium } from '../shared/StyledText';
import Styles from '../shared/Styles';

const Footer = () => {
  const { isWideScreen } = useResponsive();
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const gotoPrivacy = () => {
    navigation.navigate('privacy');
  };

  const gotoTerms = () => {
    navigation.navigate('terms');
  };

  return (
    <View
      style={{
        zIndex: 1,
        marginTop: 250,
        padding: isWideScreen ? 35 : 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '90%',
        alignSelf: 'center',
        flexShrink: 0,
      }}
    >
      <Button
        style={[Styles.button, { marginTop: 15, zIndex: 100 }]}
        onPress={gotoPrivacy}
        theme="simple"
      >
        {getTranslation('footer.privacy')}
      </Button>
      <Button
        style={[Styles.button, { marginTop: 15 }]}
        onPress={gotoTerms}
        theme="simple"
      >
        {getTranslation('footer.terms')}
      </Button>
      <View
        style={{
          alignItems: 'center',
        }}
      >
        <TextBodyMedium style={[Styles.h7, { textDecorationColor: '#000' }]}>Baltare Inc.</TextBodyMedium>
        <TextBodyMedium style={[Styles.h8, { textDecorationColor: '#000' }]}>2024 All rights reserved</TextBodyMedium>
      </View>
    </View>
  );
};

export default Footer;
