import { AnalyticsProvider, createClient } from '@segment/analytics-react-native';
import Constants from 'expo-constants';
import React from 'react';

const segmentClient = createClient({
  writeKey: Constants?.expoConfig?.extra?.segment.writeKey,
  trackAppLifecycleEvents: true,
  debug: process.env.NODE_ENV === 'development',
});

export function Provider({ children }: any) {
  return <AnalyticsProvider client={segmentClient}>{children}</AnalyticsProvider>;
}
