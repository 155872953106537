import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Button from '@src/components/Button';
import Screen from '@src/components/Screen';
import { View } from '@src/components/shared/Themed';
import React from 'react';

const DemoLanding = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const gotoTaskList = () => {
    navigation.navigate('customTaskList');
  };
  const gotoMining = () => {
    navigation.navigate('mining');
  };
  const gotoSustainability = () => {
    navigation.navigate('sustainability');
  };

  const gotoAttributeGen = () => {
    navigation.navigate('attributeGen');
  };
  const gotoSPS = () => {
    navigation.navigate('sps');
  };
  return (
    <Screen>
      <View
        style={{
          marginTop: '3%',
          padding: '3%',
          width: '100%',
          backgroundColor: '#fff',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View style={{ marginBottom: 30 }}>
          <Button
            onPress={gotoTaskList}
            theme="primary"
          >
            Custom Task list
          </Button>
        </View>
        <View style={{ marginBottom: 30 }}>
          <Button
            onPress={gotoMining}
            theme="primary"
          >
            Mining (magic table)
          </Button>
        </View>
        <View style={{ marginBottom: 30 }}>
          <Button
            onPress={gotoSustainability}
            theme="primary"
          >
            Sustainability (magic table)
          </Button>
        </View>
        <View style={{}}>
          <Button
            onPress={gotoAttributeGen}
            theme="primary"
          >
            Report Attribute Generate
          </Button>
        </View>
        <View style={{ marginBottom: 30 }}>
          <Button
            onPress={gotoSPS}
            theme="primary"
          >
            Submission Pre-Screen (SPS)
          </Button>
        </View>
      </View>
    </Screen>
  );
};

export default DemoLanding;
