import Button from '@src/components/Button';
import Input from '@src/components/Input';
import { TextBodyMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { getTranslation } from '@src/services/i18n';
import { signIn } from 'aws-amplify/auth';
import React, { useState } from 'react';
import { ActivityIndicator, Linking, NativeSyntheticEvent, TextInputKeyPressEventData, View } from 'react-native';
import AuthContact from '../AuthContact';

const MySignIn: React.FC<any> = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    try {
      console.log('handle sign in', props);
      setLoading(true);
      const res = await props.handleSubmit({ username, password });
      console.log('handleSubmit response:', res);
      const res2 = await signIn({ username, password });
      console.log('signIn response', res2);
      setLoading(false);
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const onKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    // Handle the key press event here
    if (event.nativeEvent.key === 'Enter') {
      handleSignIn();
    }
  };
  const gotoCalendly = () => {
    Linking.openURL('https://www.calendly.com/nakitare/chat');
  };

  const onRequestInfo = () => {
    // TODO create a cloud function that emails us the person's information or adds it to a database?
  };

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            padding: 25,
            width: '50%',
          }}
        >
          <View
            style={{
              padding: 50,
              borderColor: '#000',
              borderRadius: 5,
              borderWidth: 1,
            }}
          >
            <TextBodyMedium style={[Styles.h2]}>{getTranslation('auth.login.title')}</TextBodyMedium>
            <View
              style={{
                marginTop: 50,
                marginBottom: 30,
                width: '70%',
              }}
            >
              <Input
                style={Styles.input}
                placeholder="Username"
                onChangeText={text => setUsername(text)}
                value={username}
                autoComplete="email"
              />
              <View style={{ marginTop: 25 }}>
                <Input
                  style={Styles.input}
                  placeholder="Password"
                  secureTextEntry={true}
                  onChangeText={text => setPassword(text)}
                  value={password}
                  onKeyPress={onKeyPress}
                  autoComplete="password"
                />
              </View>
            </View>
            {loading ? (
              <ActivityIndicator
                size="large"
                color="#0000ff"
              />
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 10,
                }}
              >
                <Button
                  style={[Styles.button]}
                  onPress={handleSignIn}
                  iconAfter="chevron-forward"
                  theme="primary"
                >
                  Submit
                </Button>
              </View>
            )}

            <View
              style={{
                flexDirection: 'row',
                marginTop: 70,
              }}
            >
              <Button
                style={[Styles.button]}
                onPress={props.toSignUp}
                theme="secondary small"
              >
                Create an account
              </Button>
            </View>
          </View>
        </View>
        <View
          style={{
            padding: 25,
            width: '50%',
          }}
        >
          <AuthContact />
        </View>
      </View>
    </>
  );
};

export default MySignIn;
