import Button from '@src/components/Button';
import Input from '@src/components/Input';
import { TextInterRegular } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { View } from '@src/components/shared/Themed';
import useResponsive from '@src/hooks/useResponsive';
import { AlignmentContext } from '@src/providers/Alignment';
import { ModalContext } from '@src/providers/Modal';
import React, { useContext, useState } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';

type InputEditableProps = {
  property: string;
  // onAlign: (property: string) => void;
  height?: number;
};
const InputEditable = ({ property, height }: InputEditableProps) => {
  const { isWideScreen } = useResponsive();
  const { alignmentState, setAlignmentState } = useContext(AlignmentContext);
  const { setModalContent } = useContext(ModalContext);
  const defaultValue = alignmentState[property];
  const [inputValue, setInputValue] = useState<string>(defaultValue);
  const [state, setState] = useState<string>();

  const onSave = () => {
    if (inputValue !== '') {
      setAlignmentState({ ...alignmentState, [property]: inputValue });
      setState(undefined);
      setModalContent(undefined);
    }
  };

  const onCancel = () => {
    setInputValue(defaultValue);
    setAlignmentState({ ...alignmentState, [property]: defaultValue });
    setState(undefined);
    setModalContent(undefined);
  };

  const onKeyPress = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.key === 'Escape') {
      onCancel();
    } else if (e.shiftKey && e.key === 'Enter') {
      // Trigger the same function as onSave button
      onSave();
    }
  };

  return (
    <>
      {state === 'edit' ? (
        <>
          <Input
            style={[Styles.h4]}
            onChangeText={setInputValue}
            value={inputValue}
            // numberOfLines={1}
            multiline={true}
            onKeyPress={onKeyPress}
            height={height}
          />
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
            }}
          >
            <Button
              style={{ marginRight: 10 }}
              theme="primary"
              onPress={onSave}
            >
              Save
            </Button>
            <Button
              theme="primary"
              onPress={onCancel}
            >
              Cancel
            </Button>
          </View>
        </>
      ) : (
        <>
          <TextInterRegular style={[Styles.h4]}>{defaultValue}</TextInterRegular>
          <View
            style={{
              alignSelf: isWideScreen ? 'flex-end' : 'flex-start',
              marginTop: 20,
            }}
          >
            <Button
              theme="primary"
              onPress={() => {
                setState('edit');
              }}
            >
              Edit
            </Button>
          </View>
        </>
      )}
    </>
  );
};

export default InputEditable;
