import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import Screen from '@src/components/Screen';
import { TextBodyMedium, TextBodyRegular, TextHeader } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import { Pressable, View } from '@src/components/shared/Themed';
import { LoadingContext } from '@src/providers/Loading';
import { getTranslation } from '@src/services/i18n';
import FormatDate from '@src/utils/FormatDate';
import { deletePineconeNamespace } from '@src/utils/Pinecone';
import React, { useContext, useEffect } from 'react';
import { ActivityIndicator, ScrollView } from 'react-native';
import { AdvisorContext } from './Provider';

const baseUrl = 'https://banabanabucket9571c-dev.s3.amazonaws.com/';

const AdvisorDetail = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const route = useRoute();
  const { currentAdvisor, loadAdvisorById, deleteAdvisorById, saveAdvisor } = useContext(AdvisorContext);
  const { loading } = useContext(LoadingContext);
  const advisorId = route?.params?.advisorId;

  useEffect(() => {
    console.log('advisorId', advisorId);
    loadAdvisorById(advisorId);
  }, [route]);

  const goBack = () => {
    navigation.navigate('backofficeDashboard');
  };

  const goDelete = () => {
    if (currentAdvisor) {
      deleteAdvisorById(advisorId);
      if (currentAdvisor) {
        deletePineconeNamespace(currentAdvisor.indexId, currentAdvisor.namespace);
      }
      // delete the files in the s3 bucket associated with the project
      goBack();
    } else {
      throw new Error('No project to delete');
    }
  };

  return (
    <Screen>
      <ScrollView>
        <View
          style={{
            width: '100%',
            marginTop: 30,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Pressable
              onPress={goBack}
              style={{
                flexDirection: 'row',
                alignSelf: 'flex-start',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <Ionicons
                name="chevron-back"
                size={30}
              />
              <TextBodyMedium style={[Styles.h6, { textDecorationLine: 'underline' }]}>Back to advisors</TextBodyMedium>
            </Pressable>
          </View>
          <View style={{}}>
            {currentAdvisor && (
              <>
                <TextBodyMedium style={[Styles.h1, {}]}>Advisor base: {currentAdvisor.title}</TextBodyMedium>
                <View
                  style={{
                    marginVertical: 20,
                    borderColor: '#ccc',
                    borderWidth: 1,
                    padding: 20,
                    backgroundColor: '#fff',
                    borderRadius: 5,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <View>
                      <TextBodyMedium style={[Styles.h6, { marginRight: 10 }]}>Created on:</TextBodyMedium>
                      <TextBodyRegular style={[Styles.h6, {}]}>{FormatDate(currentAdvisor.createdAt)}</TextBodyRegular>
                    </View>
                    <Pressable
                      onPress={goDelete}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: 'red',
                        padding: 10,
                        borderRadius: 10,
                      }}
                    >
                      <Ionicons
                        name="warning"
                        size={30}
                      />
                      <TextBodyMedium style={[Styles.h6, { textDecorationLine: 'underline' }]}>{getTranslation('buttons.delete')}</TextBodyMedium>
                    </Pressable>
                  </View>
                </View>
                {loading && (
                  <View>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 10,
                      }}
                    >
                      <ActivityIndicator
                        size="large"
                        color="#000"
                      />
                      <TextBodyMedium style={[Styles.h6, { marginRight: 10 }]}>Loading</TextBodyMedium>
                    </View>
                  </View>
                )}
                {!loading && (
                  <>
                    <View
                      style={{
                        marginVertical: 20,
                        borderColor: '#ccc',
                        borderWidth: 1,
                        padding: 20,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                      }}
                    >
                      <TextBodyMedium style={[Styles.h4, { marginBottom: 10 }]}>Add data sources</TextBodyMedium>
                      {currentAdvisor && (
                        <UploadDocumentButton
                          indexId={currentAdvisor.indexId}
                          namespace={currentAdvisor.namespace}
                          save={saveAdvisor}
                          current={currentAdvisor}
                        />
                      )}
                      {currentAdvisor.documents?.length !== 0 && (
                        <View
                          style={{
                            marginVertical: 20,
                          }}
                        >
                          <TextHeader style={[Styles.h4, { marginBottom: 10 }]}>{`Uploaded documents: ${currentAdvisor.documents?.length || 0}`}</TextHeader>
                          <View>
                            {currentAdvisor.documents?.map((doc: any, i: number) => (
                              <View
                                key={`document-number-${i}`}
                                style={{
                                  marginBottom: 10,
                                }}
                              >
                                <TextBodyRegular style={[Styles.h6, { marginBottom: 10 }]}>{`${i + 1}. ${baseUrl}${doc}`}</TextBodyRegular>
                              </View>
                            ))}
                          </View>
                        </View>
                      )}
                    </View>
                  </>
                )}
              </>
            )}
            {!currentAdvisor && (
              <View
                style={{
                  alignItems: 'center',
                }}
              >
                <ActivityIndicator
                  size="large"
                  color="#000"
                />
                <TextBodyMedium style={[Styles.h3, { marginTop: 20, alignSelf: 'center' }]}>Please wait. Your project is loading.</TextBodyMedium>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    </Screen>
  );
};

export default AdvisorDetail;
