/**
 * Project Context
 *
 * The purpose of this Context is to CRUD (create read update delete) a user's project and it's underlying assessments.
 * A project will consist of answers to specific assessments that have, or not have, been started or completed by a user.
 * The project context uses the assessment context to gather the structure of the assessment.
 * This separation is useful so that we can adjust the assessment structure separately from the saved assessments.
 * Although one might say that once a user starts to create an assessment then changing the underlying structure would render the started assessment obsolete, instead we will need to perform comparisons between existing assessments and the original assessment structure.  This approach will (hopefully allow us to create iterations of assessments without effecting existing data)
 */
import { Project } from '@src/API';
import { createProject, deleteProject, updateProject } from '@src/graphql/mutations';
import { getProject, listProjects } from '@src/graphql/queries';
import { generateClient } from 'aws-amplify/api';
import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';
import { LoadingContext } from './Loading';
import { UserContext } from './User';

export type ProjectContextProps = {
  loadAllProjects: () => void;
  loadProjectById: (id: string) => Project | undefined;
  allProjects: Project[] | undefined;
  makeProject: (p: any) => Promise<any>;
  saveProject: (p: any) => Promise<any>;
  deleteProjectById: (id: string) => void;
  currentProject: Project | undefined;
  setCurrentProject: (project: Project | undefined) => void;
};

export const ProjectContext = React.createContext<ProjectContextProps>({
  loadAllProjects: () => {},
  loadProjectById: _ => undefined,
  makeProject: _ => new Promise<any>(() => {}),
  saveProject: _ => new Promise<any>(() => {}),
  deleteProjectById: _ => {},
  allProjects: undefined,
  currentProject: undefined,
  setCurrentProject: _ => {},
});

export const { Consumer } = ProjectContext;
export default Consumer;

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const { addLoading, removeLoading } = useContext(LoadingContext);
  const { userAttributes } = useContext(UserContext);
  const [allProjects, setAllProjects] = useState<Project[] | undefined>(undefined);
  // we need to figure out how to store the current project in state so that if the user reloads they dont lose their work
  const [currentProject, setCurrentProject] = useState<Project | undefined>(undefined);
  const client = generateClient();

  /**
   * get all user's projects
   */
  async function loadAllProjects() {
    // using the auth context we will get the user id and retrieve all projects from the backend
    // the frontend will filter the projects accordingly
    try {
      addLoading('loadAllProjects');
      const p = await client.graphql({
        query: listProjects,
      });
      setAllProjects(p.data.listProjects.items);
      removeLoading('loadAllProjects');
    } catch (err) {
      console.log('error loading projects:', err);
    }
  }

  async function loadProjectById(id: string) {
    try {
      addLoading('loadProjectById');
      const p = await client.graphql({
        query: getProject,
        variables: { id },
      });
      console.log('p', p.data.getProject);
      // return the project?  store it in state?
      setCurrentProject(p.data.getProject as Project);
      removeLoading('loadProjectById');
      return p.data.getProject;
    } catch (err) {
      console.log(`error loading project by id ${id}:`, err);
    }
  }

  async function saveProject(p: any) {
    try {
      // save the current project to the backend
      addLoading('saveProject');
      if (!currentProject?.title) return;
      const { createdAt, updatedAt, owner, __typename, ...rest } = { ...currentProject, ...p };
      const project = { ...rest };
      console.log('p:', p);
      if (project && project.id) {
        const saveProj = { ...currentProject, ...project };
        console.log('project save', saveProj);
        const updatedProject = await client.graphql({
          query: updateProject,
          variables: {
            input: project,
          },
        });
        setCurrentProject(saveProj);
        console.log('updatedProject', updatedProject);
      }
    } catch (err) {
      console.log('error saving project:', err);
      throw new Error(`${err}`);
    } finally {
      removeLoading('saveProject');
    }
  }

  async function makeProject(projectName: string) {
    try {
      // when creating a project, we use the stores assessments data to populate the database
      // each project will have a unique ID
      // the project context will handle the loading of each unique project
      addLoading('makeProject');

      console.log('projectName:', projectName);

      if (userAttributes && userAttributes.indexId) {
        // convert indexId to only consist of lower case alphanumeric characters or '-'
        const indexId = userAttributes.indexId;
        const namespace = `${projectName.replace(/[^a-zA-Z0-9_-]/g, '-').toLowerCase()}-${Date.now()}`;

        if (indexId) {
          const res = await client.graphql({
            query: createProject,
            variables: {
              input: {
                title: projectName,
                indexId,
                namespace,
                history: '',
                reports: [],
              },
            },
            // authMode: GraphQLAuthMode.AMAZON_COGNITO_USER_POOLS,
            // export type GraphQLAuthMode = 'apiKey' | 'oidc' | 'userPool' | 'iam' | 'identityPool' | 'lambda' | 'none';
          });
          return res;
        }
      }
    } catch (err) {
      console.log('error making project:', err);
      // todo show error message
    } finally {
      removeLoading('makeProject');
    }
  }

  function deleteProjectById(id: string) {
    const newAllProjects = allProjects?.filter(p => {
      return p.id !== id;
    });
    setAllProjects(newAllProjects);
    client.graphql({
      query: deleteProject,
      variables: { input: { id } },
    });
  }

  // useEffect(() => {
  //   loadAllProjects();
  // }, []);

  const contextValue: ProjectContextProps = useMemo(
    () => ({
      currentProject,
      allProjects,
      loadAllProjects,
      loadProjectById,
      makeProject,
      saveProject,
      deleteProjectById,
      setCurrentProject,
    }),
    [currentProject, allProjects, loadAllProjects, loadProjectById, makeProject, saveProject, setCurrentProject],
  );

  return <ProjectContext.Provider value={contextValue}>{children}</ProjectContext.Provider>;
}
