export default {
  landLordPrompts: {
    deliverables: 'In the application we are looking for: \n- 2 years of tax returns \n- 2 paystubs (or similar to show consistent income) \n- 1 bank statement \n- Credit report \n- Landlord reference\n- confirm monthly rental price is at most 40% of monthly income with a monthly rent of $4000 per month',
  },
  compliance: {
    deliverables: `Compliance

Phase 1: Verification of Business Existence and Legitimacy
The first phase involves confirming that the business is a legally recognized entity operating within the confines of the law. This establishes confidence in the legitimacy of the potential partner's financial activities.

Collecting Company Information: Verify the business name, physical address, registration number, and corporate documents like the Certificate of Incorporation and Articles of Association.

Reviewing Corporate Documentation: Examine official documents to ensure the company is duly registered and not a shell corporation.

Assessing Legal Compliance: Verify that the business operates in accordance with relevant laws and regulations, indicating lawful operations.

Phase 2: Due Diligence on Key Individuals
After confirming the company's legitimacy, the next phase focuses on the individuals who own or control the business. This helps minimize legal or reputational risks associated with the partnership.

Gathering Stakeholder Data: Collect the personal information of UBOs (Universal Beneficial Owner: 25% or greater ownership stake in the company) and key stakeholders, including names, dates of birth, addresses, and identification numbers.

Verifying Identities: Confirm that each UBO and key individual is real and not involved in fraudulent activities.

Screening Against Watchlists: Check these individuals against global and local watchlists or sanctions lists to avoid exposure to bad actors.

Phase 3: Risk Assessment and Decision Making
The final phase involves evaluating the overall risk and determining whether to proceed with the business relationship.

Verifying Collected Information: Cross-check all gathered data for accuracy and consistency to complete the due diligence process.
Assessing Risk Levels: Evaluate the potential risks based on the company's industry, geographic location, ownership structure, and any adverse findings from the previous phases.

Weighing Benefits and Risks: Consider the potential advantages, such as access to new markets or services, against the identified risks.

It may be true the objective of Corporate KYC is legal compliance and risk mitigation, but it also provides insights that help make informed decisions about the corporation itself.
`,
  },
  dealScreen: {
    deliverables: `Venture Capital Deal Screening Checklist
Evaluate the market size and growth potential of the target industry or market segment.
Assess the competitive landscape and analyze the target company's competitive advantage and differentiation.
Review the business model and value proposition of the target company to ensure it is sustainable and scalable.
Evaluate the management team's expertise, track record, and ability to execute the business plan.
\Conduct due diligence on the target company's financials, including revenue, profitability, and cash flow projections.
Assess the company's intellectual property, patents, trademarks, or other proprietary assets.
Analyze potential risks and challenges the target company may face and evaluate their mitigation strategies.
Evaluate the target company's customer base, customer acquisition strategies, and potential for customer retention and growth.
Assess the scalability and potential for expansion of the target company's operations.
Review the exit strategy and potential for a successful exit, such as acquisition or initial public offering (IPO).
Analyze the target company's capital structure, including existing investors, debt, and equity.
Assess the alignment of the target company's values and mission with the venture capital firm's investment strategy and portfolio.
Evaluate the terms and conditions of the investment, including valuation, share structure, and investor rights.
Conduct background checks and references on the target company's key stakeholders.

Consider any regulatory or legal implications associated with the target company's industry or operations.
`,
  },
  diligenceQuestionnaire: {
    deliverables: `Due Diligence Questionnaire
1. What is the organizational structure of your company?
2. Can you provide diagrams and charts of your corporate structure?
3. What are your current by-laws?
4. Can you provide a list of all subsidiaries?
5. Who are the security holders?
6. Can you provide us with your stockholder agreements?
7. Who are the key players in your organization?
8. What are their roles, and how long have you employed them?
9. Who are your board members and stakeholders, and what has their feedback
been over the last three to five years?
10. What are your operating costs and metrics?
11. What do your budget and operating plans consist of? What methodology are
you using?
12. What are your accounting policies?
13. What are your investment policies?
14. What are your capital expenditures for the next 12 months?
15. What is your inventory cost system?
16. What are your cash management practices and controls?
17. Do you have any debt?
18. Do you have any existing financial arrangements or agreements?
19. Can you provide us with income statements and balance sheets?
20. What is the breakdown of your sales and gross profits by product type?
21. What are your major growth drivers?
22. What are your projections or forecasts for future financial performance?`,
  },
  proposalReview: {
    deliverables: `Project Title – A concise title for the proposed project.
Executive Summary – A brief overview of the project, its goals, and expected outcomes.
Problem Statement – A description of the issue or need the project aims to address.
Project Objectives – Clear and measurable goals the project intends to achieve.
Project Description/Scope – Detailed explanation of the project, including methodology, target audience, and geographic area.
Budget Overview – A detailed budget, outlining costs such as personnel, materials, and other resources.
Budget Justification – Explanation of why each budget line item is necessary for the project.
Timeline – A project timeline or work plan showing milestones and key deliverables.
Sustainability Plan – How the project will continue after the grant period ends, if applicable.
Organizational Background – Information about the applying organization, its mission, history, and track record.
Key Personnel – Biographies or resumes of the project lead and key team members, showing relevant expertise.
Partnerships/Collaborations – Description of partnerships or other organizations involved in the project.
Evaluation Plan – How the project's success will be measured, including performance indicators and metrics.
Needs Assessment – Data or research supporting the need for the project and the gap it seeks to fill.
Previous Grant Experience – Past experience managing similar grants or projects, including outcomes.
Letters of Support – Letters from partners or stakeholders endorsing the project.
Legal and Compliance Documents – Documentation such as nonprofit status, tax exemption certificates, or business licenses.
Risk Management Plan – How potential risks to the project will be mitigated or managed.
Impact Statement – Explanation of the expected social, environmental, or economic impact of the project.
Project Alignment with Grantor’s Mission – A statement or section explaining how the project aligns with the grantor’s priorities and funding goals.
`,
  },
  portfolioReporting: {
    deliverables: `Financial Performance Metrics
Revenue Growth: Year-over-year revenue growth rates and analysis of revenue streams.
Profitability Ratios: Key metrics such as Gross Margin, Operating Margin, EBITDA Margin, and Net Profit Margin.
Cash Flow Analysis: Detailed cash flow statements, including operating, investing, and financing cash flows.
Liquidity Ratios: Current ratio and quick ratio to assess short-term financial health.
Debt Management: Debt-to-equity ratio, interest coverage ratio, and total debt levels.
Operational Metrics
Customer Retention Rates: Churn rates, customer acquisition costs (CAC), and lifetime value (LTV) of customers.
Employee Productivity: Revenue per employee and EBITDA per employee metrics.
Operational Efficiency: Key metrics around production efficiency, supply chain performance, and inventory turnover ratios.
Sales Performance: Sales growth rates by product line or service category.
Investment Returns Metrics
Internal Rate of Return (IRR): Calculation of IRR for the investment made in the portfolio company.
Multiple on Invested Capital (MOIC): Current MOIC based on valuations and distributions.
Total Value to Paid-in Capital (TVPI): Current TVPI to measure overall fund performance against invested capital.
Distributed to Paid-in Capital (DPI): Analysis of cash distributions relative to capital invested.
Market Positioning and Competitive Analysis
Market Share Data: Current market share figures compared to competitors.
Competitive Landscape Analysis: Overview of key competitors, their strengths/weaknesses, and market positioning strategies.
Customer Feedback: Insights from customer satisfaction surveys or Net Promoter Scores (NPS).
Risk Management Metrics
Risk Assessment Reports: Documentation on identified risks, mitigation strategies, and incident history (e.g., cybersecurity breaches).
Compliance Metrics: Status on regulatory compliance and any outstanding legal issues.
Strategic Initiatives
Progress on Strategic Goals: Updates on strategic initiatives outlined in the last investment review—successes or challenges faced.
Future Growth Plans: Insights into planned expansions, new product launches, or market entry strategies.
ESG Metrics
Environmental Impact Measures: Data on sustainability practices and carbon footprint reduction efforts.
Social Responsibility Initiatives: Employee engagement metrics and community impact assessments.
Governance Practices: Overview of governance structures, board composition, and any changes in management.
`,
  },
  marketAssessment: {
    deliverables: `Market Assessment Framework
Market Size and Growth
What is the total addressable market (TAM) in revenue and units?
What is the serviceable addressable market (SAM)?
What is the serviceable obtainable market (SOM)?
What is the historical market growth rate over the past 3-5 years?
What is the projected market growth rate for the next 3-5 years?
What are the key market segments and their respective sizes?
How does market size vary by geographic region?
Customer Analysis
Who are the primary customer segments?
What are the key demographic and psychographic characteristics of each segment?
What are the primary use cases and pain points for each segment?
What is the average customer lifetime value (CLV)?
What is the typical customer acquisition cost (CAC)?
What are the key buying criteria for customers?
How price-sensitive are different customer segments?
What are the primary purchase channels preferred by customers?
Competitive Landscape
Who are the major players in the market?
What is the market share distribution?
What are the barriers to entry?
What are competitors' key strengths and weaknesses?
How do competitors differentiate themselves?
What are the primary pricing strategies in the market?
What are the typical gross margins in the industry?
Who are the potential new entrants or emerging competitors?
Product and Technology
What are the key product features demanded by customers?
What are the current technology trends affecting the market?
What is the pace of technological change?
What are the key patents and intellectual property considerations?
What are the primary production/development costs?
What are the key quality standards and certifications required?
What is the typical product lifecycle?
Regulatory and Environmental Factors
What are the key regulations affecting the industry?
What licenses or permits are required?
What are the relevant environmental regulations?
What are the import/export considerations?
What are the primary safety standards?
What are the data privacy and security requirements?
What are the relevant labor laws and regulations?
Supply Chain and Distribution
Who are the key suppliers in the market?
What is the typical supply chain structure?
What are the primary distribution channels?
What are the logistics and transportation considerations?
What are the inventory management requirements?
What are the key supplier relationships and bargaining power?
What are the primary supply chain risks?
Economic Factors
What are the key economic drivers affecting the market?
How cyclical is the industry?
What are the primary cost components?
What are the typical payment terms and working capital requirements?
How do exchange rates impact the market?
What are the primary financing options available?
What are the key economic risks?
Market Trends and Evolution
What are the emerging market trends?
What are the primary drivers of market change?
What are the potential market disruptions?
What are the changing customer preferences?
What are the emerging business models?
What are the key sustainability considerations?
What are the primary opportunities for innovation?
Success Factors
What are the critical success factors in the market?
What are the key performance indicators (KPIs)?
What are the minimum viable scale requirements?
What are the essential capabilities and resources needed?
What are the key strategic partnerships required?
What are the primary risk factors?
What are the typical timelines for market entry and scaling?
`,
  },
};
