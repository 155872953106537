import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { UserContext } from '@src/providers/User';
import React, { useContext } from 'react';
import { View } from 'react-native';
import SvgLogoIcon from '../Logo/SvgLogoIcon';
import NavUI from '../NavUI';
import { Pressable } from '../shared/Themed';

const VerticalBar = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { user } = useContext(UserContext);

  function gotoHome() {
    navigation.navigate('investor');
  }
  return (
    <View
      style={{
        zIndex: 1,
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: 20,
        paddingTop: 30,
        backgroundColor: '#fff',
      }}
    >
      <Pressable onPress={gotoHome}>
        <SvgLogoIcon width={120} height={45} />
      </Pressable>
      {user && <NavUI />}
    </View>
  );
};

export default VerticalBar;
