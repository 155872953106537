import { Ionicons } from '@expo/vector-icons';
import Button from '@src/components/Button';
import Input from '@src/components/Input';
import { TextBodyMedium } from '@src/components/shared/StyledText';
import Styles from '@src/components/shared/Styles';
import React, { useState } from 'react';
import { Linking, View } from 'react-native';

const AuthContact: React.FC<any> = props => {
  const [companyName, setCompanyName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const gotoCalendly = () => {
    Linking.openURL('https://www.calendly.com/nakitare/chat');
  };

  const onRequestInfo = () => {
    // TODO create a cloud function that emails us the person's information or adds it to a database?
  };

  return (
    <>
      <View
        style={{
          padding: 50,
          borderColor: '#000',
          borderRadius: 5,
          borderWidth: 1,
        }}
      >
        <View
          style={
            {
              // position: 'absolute',
            }
          }
        >
          <Ionicons
            name="calendar-sharp"
            size={36}
          />
        </View>
        <TextBodyMedium
          style={[
            Styles.h2,
            {
              marginTop: 30,
            },
          ]}
        >
          {`Schedule a time with us.`}
        </TextBodyMedium>
        <View
          style={{
            marginTop: 30,
            flexDirection: 'row',
          }}
        >
          <Button
            theme="secondary small"
            onPress={gotoCalendly}
          >
            {`Book now`}
          </Button>
        </View>
      </View>
      <View
        style={{
          marginTop: 20,
          padding: 50,
          borderColor: '#000',
          borderRadius: 5,
          borderWidth: 1,
        }}
      >
        <View
          style={
            {
              // position: 'absolute',
            }
          }
        >
          <Ionicons
            name="paper-plane"
            size={36}
          />
        </View>
        <TextBodyMedium
          style={[
            Styles.h2,
            {
              marginTop: 30,
            },
          ]}
        >
          {`Request more information.`}
        </TextBodyMedium>
        <TextBodyMedium style={[Styles.h7, { marginTop: 20, marginBottom: 10 }]}>{`Email`}</TextBodyMedium>
        <View
          style={{
            width: '60%',
          }}
        >
          <Input
            style={[Styles.input]}
            placeholder="person@company.com"
            onChangeText={text => setEmailAddress(text)}
            value={emailAddress}
            placeholderTextColor="#bbb"
            autoComplete="email"
          />
        </View>
        <TextBodyMedium style={[Styles.h7, { marginTop: 20, marginBottom: 10 }]}>{`Company`}</TextBodyMedium>
        <View
          style={{
            width: '60%',
          }}
        >
          <Input
            style={[Styles.input]}
            placeholder="ABC Co."
            placeholderTextColor="#bbb"
            onChangeText={text => setCompanyName(text)}
            value={companyName}
            autoComplete="email"
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginTop: 30,
          }}
        >
          <Button
            theme="secondary small"
            onPress={onRequestInfo}
          >
            Submit
          </Button>
        </View>
      </View>
    </>
  );
};

export default AuthContact;
