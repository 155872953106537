import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet } from 'react-native';
import Screen from '../Screen';
import { TextHeader } from '../shared/StyledText';
import Styles from '../shared/Styles';
import { View } from '../shared/Themed';
import TopBarWeAlign from '../SPS/TopBarWeAlign';

const style = StyleSheet.create({
  h1: {
    fontSize: 24,
    marginVertical: 15,
  },
  h2: { fontSize: 20, marginVertical: 10 },
  h4: { fontSize: 16, marginVertical: 5 },
  p: { fontSize: 12, marginVertical: 5 },
  list: {
    backgroundColor: '#0f0',
  },
});
const Terms = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();

  const goBack = () => {
    navigation.goBack();
  };

  return (
    <>
      <TopBarWeAlign />
      <Screen>
        <View
          style={{
            padding: 30,
          }}
        >
          <TextHeader style={Styles.h1}>Terms & Conditions</TextHeader>
        </View>
      </Screen>
    </>
  );
};

export default Terms;
