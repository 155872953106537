/**
 * Modal Context
 *
 * The purpose of this Context is to support moving a component to the top of all elements and positioning it inside of a modal
 */
import React, { PropsWithChildren, useMemo, useState } from 'react';

type QueueState = {
  id: string;
  state: boolean;
};

type ModalContextProps = {
  loadingQueue: QueueState[];
  modalContent: any;
  dimensions: any;
  setModalContent: (content: any) => void;
  openButtonModal: (id: string) => void;
  closeButtonModal: (id: string) => void;
  checkButtonModal: (id: string) => QueueState | undefined;
};

export const ModalContext = React.createContext<ModalContextProps>({
  loadingQueue: [],
  modalContent: undefined,
  dimensions: undefined,
  setModalContent: _ => {},
  openButtonModal: _ => {},
  closeButtonModal: _ => {},
  checkButtonModal: _ => undefined,
});

export const { Consumer } = ModalContext;
export default Consumer;

export function Provider({ children }: PropsWithChildren<Record<symbol, symbol>>) {
  const [modalContent, setModalContent] = useState();
  const [dimensions, setDimensions] = useState();
  const [buttonModalQueue, setButtonModalQueue] = useState<QueueState[]>([]);

  const openButtonModal = (id: string) => {
    setButtonModalQueue(prevQueue => [...prevQueue, { id, state: true }]);
  };

  const closeButtonModal = (id: string) => {
    setButtonModalQueue(prevQueue => prevQueue.filter(item => item.id !== id));
  };

  const checkButtonModal = (id: string) => {
    return buttonModalQueue.some(item => item.id === id);
  };

  const buttonModalOpen = buttonModalQueue.length > 0 && buttonModalQueue.some(item => item.state);

  const contextValue: ModalContextProps = useMemo(() => ({ modalContent, dimensions, setModalContent, setDimensions, buttonModalQueue, buttonModalOpen, openButtonModal, closeButtonModal, checkButtonModal }), [modalContent, dimensions, setModalContent, setDimensions, buttonModalQueue, buttonModalOpen, openButtonModal, closeButtonModal, checkButtonModal]);

  return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
}
