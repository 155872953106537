import { getTranslation } from '@src/services/i18n';
import React from 'react';
import WrapperModal from '../WrapperModal';
import { TextBodyMedium, TextBodyRegular } from '../shared/StyledText';
import Styles from '../shared/Styles';

const Support = () => {
  return (
    <WrapperModal>
      <>
        <TextBodyMedium style={[Styles.h2, { marginBottom: 20 }]}>{getTranslation('support.title')}</TextBodyMedium>
        <TextBodyRegular style={[Styles.h5, { marginBottom: 40 }]}>{getTranslation('support.body')}</TextBodyRegular>
        <TextBodyRegular style={[Styles.h6, { marginBottom: 20 }]}>{getTranslation('support.email')}</TextBodyRegular>
        <TextBodyRegular style={[Styles.h6, { marginBottom: 50 }]}>{getTranslation('support.contact')}</TextBodyRegular>
        <TextBodyRegular style={[Styles.h8, { marginBottom: 20 }]}>{getTranslation('support.terms')}</TextBodyRegular>
      </>
    </WrapperModal>
  );
};

export default Support;
