import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { UserContext } from '@src/providers/User';
import React, { useContext } from 'react';
import { View } from 'react-native';
import SvgLogo from '../Logo/SvgLogo';
import NavUI from '../NavUI';
import { Pressable } from '../shared/Themed';

const TopBar = () => {
  const navigation = useNavigation<NativeStackNavigationProp<any>>();
  const { user } = useContext(UserContext);

  function gotoHome() {
    navigation.navigate('investor');
  }
  return (
    <View
      style={{
        zIndex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
        backgroundColor: '#fff',
      }}
    >
      <View
        style={{
          marginBottom: 10,
          flexDirection: 'row',
          marginLeft: 'auto',
          marginRight: 'auto',
          justifyContent: 'space-between',
          width: '90%',
          margin: 'auto',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          <Pressable
            onPress={gotoHome}
            style={{
              marginRight: 20,
            }}
          >
            <SvgLogo width={120} height={45} />
          </Pressable>
        </View>
        {user && <NavUI />}
      </View>
    </View>
  );
};

export default TopBar;
