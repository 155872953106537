/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createSubmissionPreScreen = /* GraphQL */ `mutation CreateSubmissionPreScreen(
  $input: CreateSubmissionPreScreenInput!
  $condition: ModelSubmissionPreScreenConditionInput
) {
  createSubmissionPreScreen(input: $input, condition: $condition) {
    id
    deliverables
    requirements
    deadline
    emailLLM
    titleLLM
    grade
    deliverablesLLM {
      title
      summaryShort
      summaryLong
      keywords
      methodology
      issues
      grade
      status
      approaches
      sourceMaterials {
        pageContent
        metadata {
          fileName
          linesFrom
          linesTo
          pageNumber
          createdAt
          __typename
        }
        __typename
      }
      queries
      __typename
    }
    requirementsLLM
    changeLog {
      reporter
      createDate
      messages
      files
      __typename
    }
    assignees
    ownerEmail
    indexId
    namespace
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubmissionPreScreenMutationVariables,
  APITypes.CreateSubmissionPreScreenMutation
>;
export const updateSubmissionPreScreen = /* GraphQL */ `mutation UpdateSubmissionPreScreen(
  $input: UpdateSubmissionPreScreenInput!
  $condition: ModelSubmissionPreScreenConditionInput
) {
  updateSubmissionPreScreen(input: $input, condition: $condition) {
    id
    deliverables
    requirements
    deadline
    emailLLM
    titleLLM
    grade
    deliverablesLLM {
      title
      summaryShort
      summaryLong
      keywords
      methodology
      issues
      grade
      status
      approaches
      sourceMaterials {
        pageContent
        metadata {
          fileName
          linesFrom
          linesTo
          pageNumber
          createdAt
          __typename
        }
        __typename
      }
      queries
      __typename
    }
    requirementsLLM
    changeLog {
      reporter
      createDate
      messages
      files
      __typename
    }
    assignees
    ownerEmail
    indexId
    namespace
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubmissionPreScreenMutationVariables,
  APITypes.UpdateSubmissionPreScreenMutation
>;
export const deleteSubmissionPreScreen = /* GraphQL */ `mutation DeleteSubmissionPreScreen(
  $input: DeleteSubmissionPreScreenInput!
  $condition: ModelSubmissionPreScreenConditionInput
) {
  deleteSubmissionPreScreen(input: $input, condition: $condition) {
    id
    deliverables
    requirements
    deadline
    emailLLM
    titleLLM
    grade
    deliverablesLLM {
      title
      summaryShort
      summaryLong
      keywords
      methodology
      issues
      grade
      status
      approaches
      sourceMaterials {
        pageContent
        metadata {
          fileName
          linesFrom
          linesTo
          pageNumber
          createdAt
          __typename
        }
        __typename
      }
      queries
      __typename
    }
    requirementsLLM
    changeLog {
      reporter
      createDate
      messages
      files
      __typename
    }
    assignees
    ownerEmail
    indexId
    namespace
    role
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubmissionPreScreenMutationVariables,
  APITypes.DeleteSubmissionPreScreenMutation
>;
export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelProjectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    id
    title
    history
    indexId
    namespace
    documents
    reports {
      versions {
        title
        text
        date
        sourceMaterial {
          pageContent
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelProjectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    id
    title
    history
    indexId
    namespace
    documents
    reports {
      versions {
        title
        text
        date
        sourceMaterial {
          pageContent
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    title
    history
    indexId
    namespace
    documents
    reports {
      versions {
        title
        text
        date
        sourceMaterial {
          pageContent
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
